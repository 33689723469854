onLoad(($) => {
  if (!$('.requested-registrations-form').length) {
    return;
  }

  const $btnAddOriginator = $('.JS-btn-add-originator');
  const $btnSubmit = $('.JS-submit-button');
  const $inputPhoneNumber = $('.JS-phone-number');
  const $calloutTable = $('.JS-callout-table');
  const $formOriginator = $('.JS-hidden-originator-ids');
  const $inputCompanyName = $('#client_requested_registration_company_name');
  const $inputDocumentNumber = $('#client_requested_registration_document_number');
  const $inputEmail = $('#client_requested_registration_onboarding_email');
  const $originatorSelect = $('#JS-originator-select');
  const $tableOriginator = $('.JS-originator-table');
  const $tbodyOriginator = $('#JS-originator-tbody');
  let originators = [];

  function validateForm() {
    const validInputCompanyName = $inputCompanyName.val().length;
    const validInputDocumentNumber = $inputDocumentNumber.val().length;
    const validInputEmail = $inputEmail.val().length;

    if (validInputCompanyName && validInputDocumentNumber && validInputEmail && originators.length) {
      $btnSubmit.removeClass('disabled');
      return true;
    }
    $btnSubmit.addClass('disabled');
    return false;
  }

  function updateSubmitValue() {
    let quantityTotal = 0;

    originators.forEach((originator) => {
      quantityTotal += originator.quantity;
    });

    if (quantityTotal > 1) {
      $btnSubmit.val(`Solicitar ${quantityTotal.toString()} contas`).change();
    } else {
      $btnSubmit.val('Solicitar').change();
    }
  }

  function findOriginator(id) {
    return originators.findIndex(originator => originator.value === id);
  }

  function appendOriginator() {
    const originatorIds = [];

    originators.forEach((originator) => {
      for (let n = 0; n < originator.quantity; n += 1) {
        originatorIds.push(originator.value);
      }
    });

    $formOriginator.val(originatorIds).change();
  }

  function toggleTable() {
    if (originators.length) {
      $tableOriginator.removeClass('d-none');
      return;
    }
    $calloutTable.addClass('d-none');
    $tableOriginator.addClass('d-none');
  }

  function getOriginatorsSelected() {
    const originatorsIds = $originatorSelect.attr('originator_ids').split(',');

    originatorsIds.forEach((id) => {
      originatorSelector = $originatorSelect.find(`option[value=${id}]`);
      if (originatorSelector) {
        const originatorName = originatorSelector.text();

        const originatorSelected = originators.find(originator => originator.value === id);

        if (originatorSelected) {
          originatorSelected.quantity += 1;
        } else {
          originators.push({ value: id, name: originatorName, quantity: 1 });
          $originatorSelect.find(`[value="${originatorSelector.val()}"]`).remove();
          $originatorSelect.val('').change();
        }
      }
    });

    $originatorSelect.attr('originator_ids', '');
  }

  function updateTbody() {
    if ($originatorSelect.attr('originator_ids').length) {
      getOriginatorsSelected();
    }

    $tbodyOriginator.empty();
    appendOriginator();
    updateSubmitValue();
    validateForm();

    originators.forEach((originator) => {
      const $tr = $('<tr/>');

      $tr.append($('<td/>', { text: originator.name }))
        .append(`
            <td>
              <div class="td-quantity" id="${originator.value}">
                <button type="button" class="btn btn-secondary
                                        JS-remove-quantity ${originator.quantity <= 1 ? 'disabled' : ''}">
                  <i class="fas fa-minus"></i>
                </button>
                <span class="JS-quantity">${originator.quantity}</span>
                <button type="button" class="btn btn-secondary
                                        JS-add-quantity ${originator.quantity >= 10 ? 'disabled' : ''}">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </td>
        `)
        .append(`
            <td>
              <button type="button" class="btn btn-danger JS-delete-originator" id="${originator.value}">
                <i class="fas fa-trash"></i>
              </button>
            </td>
        `);


      $tbodyOriginator.append($tr);
    });

    $('.JS-remove-quantity').on('click', function () {
      originatorID = $(this).closest('.td-quantity').attr('id');
      index = findOriginator(originatorID);
      originators[index].quantity -= 1;
      updateTbody();
    });

    $('.JS-add-quantity').on('click', function () {
      originatorID = $(this).closest('.td-quantity').attr('id');
      index = findOriginator(originatorID);
      originators[index].quantity += 1;
      updateTbody();
    });

    $('.JS-delete-originator').on('click', function () {
      originatorID = $(this).attr('id');
      index = findOriginator(originatorID);
      $originatorSelect.append(`
          <option value="${originators[index].value}" >
            ${originators[index].name}
          </option>
      `);
      originators = originators.filter(item => item !== originators[index]);
      updateTbody();
      toggleTable();
    });
  }

  updateTbody();
  toggleTable();

  $originatorSelect.on('change', () => {
    const optionValue = $originatorSelect.children('option:selected').val();
    if (optionValue) {
      $btnAddOriginator.removeClass('disabled');
      $btnSubmit.addClass('disabled');
      $calloutTable.removeClass('d-none');
    } else {
      $btnAddOriginator.addClass('disabled');
    }
  });

  $btnAddOriginator.on('click', () => {
    const optionSelected = $originatorSelect.children('option:selected');
    originators.push({ value: optionSelected.val(), name: optionSelected.text(), quantity: 1 });
    $originatorSelect.find(`[value="${optionSelected.val()}"]`).remove();
    $originatorSelect.val('').change();
    if (validateForm()) $btnSubmit.removeClass('disabled');
    toggleTable();
    updateTbody();
  });

  $inputCompanyName.add($inputDocumentNumber).add($inputEmail).on('change', () => {
    validateForm();
  });

  $btnSubmit.on('click', () => {
    let contentHtml = '';
    let titleHtml = '';
    let buttonConfirmHtml = '';

    const validInputPhoneNumber = $inputPhoneNumber.val().length;
    if (validInputPhoneNumber >= 14) {
      titleHtml = 'Solicitar abertura de contas';
      contentHtml = '<p>Ao confirmar a solicitação, o convite de abertura de contas será'
        + ' <strong>enviado para o e-mail e celular informados.</strong></p>'
        + `<div class="info_banner">
            <i class="fas fa-info-circle info_icon"></i>
            <small class="text-left p-0 line-height-normal">
              Pedimos sua compreensão, pois o processo de envio pode levar alguns minutos.
            </small>
           </div>`;
      buttonConfirmHtml = 'Confirmar';
    } else {
      titleHtml = 'Continuar sem o celular?';
      contentHtml = `<p>Com o número de celular, você pode aumentar as chances de
         <strong>receber nossas mensagens de forma rápida e eficiente.</strong></p>
         <p>Não será possível editar após a confirmação.</p>
        <div class="modal-content-banner">
          <i class="fas fa-info-circle info_icon"></i>
          <span class="text-left">
            Pedimos sua compreensão, pois o processo de envio pode levar alguns minutos.
          </span>
        </div>`;
      buttonConfirmHtml = 'Sim, continuar';
    }

    function waitForModal(selector) {
      return new Promise((resolve) => {
        if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            observer.disconnect();
            resolve(document.querySelector(selector));
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return null;
      });
    }

    waitForModal('.swal2-container').then(() => {
      const $modalTitle = $('.swal2-title');
      const $modalContent = $('.swal2-content');
      const $buttonConfirm = $('.swal2-confirm');
      $modalContent.html(contentHtml);
      $modalTitle.html(titleHtml);
      $buttonConfirm.html(buttonConfirmHtml);
    });
  });
});
