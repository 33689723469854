onLoad(($) => {
  const $select = $('.JS-values-by-select');

  $select.on('change', function autocomplete() {
    const $node = $(this);
    const url = $node.data('queryurl');
    const selectedValue = $node.children('option:selected').val();
    const autocompleteFields = JSON.parse(this.dataset.autocompletefields);
    const autocompleteFieldsKeys = Object.keys(autocompleteFields);
    if (selectedValue !== '') {
      $.get(`${url}/${selectedValue}.to_json`)
        .done((resp) => {
          for (let r = 0; r < autocompleteFieldsKeys.length; r += 1) {
            const key = autocompleteFieldsKeys[r];
            const field = autocompleteFields[key];
            const value = resp[key];
            if (typeof (value) === 'boolean') {
              $(`#${field}_${value}`).prop('checked', true).trigger('change');
            } else {
              $(`#${field}`).val(value).trigger('change');
            }
          }
        });
    }
  });

  $select.trigger('change');
});
