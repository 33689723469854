import UserAuth from '../user_auth';

const updatePinStorage = (requiresPin) => {
  localStorage.setItem('force_pin_authentication', requiresPin);
};
class ExpiredPassword {
  constructor($element) {
    this.$element = $element;
    this.options = this.$element.data('updateExpiredPassword');
    this.defaultErrorTitle = 'Erro';
    this.defaultErrorMessage = 'Não foi possível atualizar a senha.';
    this.targetClass = 'update-expired-password';

    const btnSubmit = $('#JS-update-expired-password');
    btnSubmit.click(() => { this.updateExpiredPasswordEvent(); });
  }

  updateExpiredPasswordEvent() {
    if (!this.isSubmitting()) {
      this.updateExpiredPassword().catch(() => this.errorDialog());
      return false;
    }
    this.updateExpiredPassword();

    return true;
  }

  async updateExpiredPassword() {
    const requires2fa = this.options.two_factor;
    const requiresPin = this.options.pin;

    updatePinStorage(requiresPin);

    const userAuth = new UserAuth(requires2fa, requiresPin);
    const validationResult = await userAuth.validate();
    let extraParams = {};

    if (!validationResult.success) {
      return false;
    }

    extraParams = validationResult.data;
    this.approve(extraParams);
    return true;
  }

  approve(extraParams = {}) {
    this.appendAdditionalParams(extraParams);
    this.submit();
  }

  submit() {
    this.toggleSubmitStatus();
    $('#edit_user').submit();
    this.toggleSubmitStatus();
  }

  isSubmitting() {
    return !this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  appendAdditionalParams(extraParams = {}) {
    Object.entries(extraParams).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      this.$element.append(input);
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text: text || this.defaultErrorMessage,
      title: this.defaultErrorTitle,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    }).then(() => {
      window.location.reload();
    });
  }
}

onLoad(($) => {
  if (!$('.ds-password-expired').length) return;
  $('#JS-update-expired-password').each((_, element) => new ExpiredPassword($(element)));
});
