import { validateForm, enabledButtonForm } from '../helper/validate_form';
import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  const $selectBorrower = $('.JS-find-borrower-info');
  if (!$selectBorrower.length) return;

  const LABEL_BUTTON_NOT_REGISTRED = 'Devedor não cadastrado';
  const ICON_PLUS = 'plus';
  const ICON_USER = 'user';
  const EMPTY_VALUE = '';

  const currencyHelper = new CurrencyHelper();
  const $buttonBorrowerNotRegistered = $('#JS-button-borrower-not-registered-ccb-company-form');
  const $formBorrower = $('#JS-form-borrower-ccb-company-form');
  const $optionBorrowerEmpty = $('.JS-find-borrower-info option:first');
  const $buttonContinue = $('#JS-button-continue-ccb-company-form');
  const $inputsBorrower = $(`input.form-control, #ccb_company_bank_account_attributes_bank_code, 
    #ccb_company_address_attributes_state, .JS-input-required-ccb-company-form`);
  const $labelsOptional = $(`.ccb_company_formatted_average_billing label, .ccb_company_address_number label, 
    .ccb_company_address_neighborhood label, .ccb_company_address_city label, .ccb_company_address_state label`);
  const $inputName = () => $('#ccb_company_name');
  const $inputCompanyName = () => $('#ccb_company_company_name');
  const $inputDocumentNumber = () => $('#ccb_company_document_number');
  const $inputEmail = () => $('#ccb_company_email');
  const $inputPhone = () => $('#ccb_company_phone');
  const $inputFormattedAverageBilling = () => $('#ccb_company_formatted_average_billing');
  const $inputAddressAttributesZipCode = () => $('#ccb_company_address_attributes_zip_code');
  const $inputAddressAttributesStreet = () => $('#ccb_company_address_attributes_street');
  const $inputAddressAttributesNumber = () => $('#ccb_company_address_attributes_number');
  const $inputAddressAttributesComplement = () => $('#ccb_company_address_attributes_complement');
  const $inputAddressAttributesNeighborhood = () => $('#ccb_company_address_attributes_neighborhood');
  const $inputAddressAttributesCity = () => $('#ccb_company_address_attributes_city');
  const $inputAddressAttributesState = () => $('#ccb_company_address_attributes_state');
  const $inputAccountAttributesBankCode = () => $('#ccb_company_bank_account_attributes_bank_code');
  const $inputSocialContractFileCache = () => $('#ccb_company_social_contract_file_cache');
  const $inputBankAccountAttributesAgency = () => $('#ccb_company_bank_account_attributes_agency');
  const $inputBankAccountAttributesAccount = () => $('#ccb_company_bank_account_attributes_account');
  const $inputBankAccountAttributesName = () => $('#ccb_company_bank_account_attributes_name');
  const $inputBankAccountAttributesDocumentNumber = () => $('#ccb_company_bank_account_attributes_document_number');
  const borrowerId = $('#ccb_company_borrower_id').val();
  const isInvalidForm = $('.invalid-feedback').length;
  const templateOptionBorrowerNew = '<option data-borrower="true" value="new-borrower">Novo devedor</option>';
  const templateIconInButtonBorrower = (iconName, label) => `<i class="fa fa-${iconName} mr-2"></i> ${label}`;
  const templateAbbrRequired = '<abbr title="required" class="ml-1">*</abbr>';

  function isInputRequired(id) {
    const optionalIds = ['change_account_ip_bank_account', 'ccb_company_address_attributes_complement',
      'ccb_company_bank_account_attributes_name'];
    return optionalIds.every(optinalId => optinalId !== id);
  }

  function composeValidateForm() {
    return $inputsBorrower.filter(function () {
      return isInputRequired($(this).attr('id'));
    }).map(function () {
      return $(this).val().length > 0 || Boolean($(this).attr('title'));
    });
  }

  function dispatchValidateForm() {
    validateForm({
      $button: $buttonContinue,
      $form: composeValidateForm(),
    });
  }

  function handleError() {
    Swal.fire({
      title: 'Erro no Devedor',
      text: 'Não foi possível encontrar um devedor com os dados informados.',
      icon: 'error',
    });
  }

  function showFormBorrower() {
    $formBorrower.removeClass('d-none');
  }

  function hideFormBorrower() {
    $formBorrower.addClass('d-none');
  }

  function showPlusIconInButtonBorrowerNotRegistered() {
    $buttonBorrowerNotRegistered.html(templateIconInButtonBorrower(ICON_PLUS, LABEL_BUTTON_NOT_REGISTRED));
  }

  function showUserIconInButtonBorrowerNotRegistered() {
    $buttonBorrowerNotRegistered.html(templateIconInButtonBorrower(ICON_USER, LABEL_BUTTON_NOT_REGISTRED));
  }

  function showOptionNewInSelectBorrower() {
    $optionBorrowerEmpty.after(templateOptionBorrowerNew);
  }

  function showAbbrRequiredInLabels() {
    $labelsOptional.each(function () {
      $(this).after(templateAbbrRequired);
    });
  }

  function removeIsActiveClassForm() {
    $selectBorrower.removeClass('is-valid');
    $inputsBorrower.each(function () {
      $(this).removeClass('is-valid');
    });
  }

  async function populateBorrower(data) {
    if (!Object.keys(data).length) {
      handleError();
      return;
    }
    $inputName().val(data.name);
    $inputCompanyName().val(data.company_name);
    $inputDocumentNumber().val(data.document_number);
    $inputEmail().val(data.email);
    $inputPhone().val(data.phone);
    $inputFormattedAverageBilling().val(currencyHelper.format(data.average_billing));
    $inputAddressAttributesZipCode().val(data.address.zipCode);
    $inputAddressAttributesStreet().val(data.address.street).removeAttr('readonly');
    $inputAddressAttributesNumber().val(data.address.number).removeAttr('readonly');
    $inputAddressAttributesComplement().val(data.address.complement).removeAttr('readonly');
    $inputAddressAttributesNeighborhood().val(data.address.neighborhood).removeAttr('readonly');
    $inputAddressAttributesCity().val(data.address.city).removeAttr('readonly');
    $inputAddressAttributesState().val(data.address.state).attr('disabled', false);
    $inputAccountAttributesBankCode().val(data.bank_account.bankCode).trigger('change');
    $inputSocialContractFileCache().val(data.social_contract_file);
    $inputBankAccountAttributesAgency().val(data.bank_account.agency);
    $inputBankAccountAttributesAccount().val(data.bank_account.account);
    $inputBankAccountAttributesName().val(data.bank_account.name);
    $inputBankAccountAttributesDocumentNumber().val(data.bank_account.documentNumber).trigger('change');
  }

  function cleanFields() {
    $('input:text, input[type=email], input[type=tel]').val(EMPTY_VALUE);
    $('#ccb_company_address_attributes_state').prop('selectedIndex', 0);
    $('#ccb_company_bank_account_attributes_bank_code').val(EMPTY_VALUE).trigger('change');
    $('input[type=file]').each(function () {
      $(this).val(EMPTY_VALUE);
    }).trigger('change');
  }

  function searchBorrower(id) {
    $.ajax({
      url: '/ccb/companies/search',
      type: 'get',
      dataType: 'json',
      data: { id },
      beforeSend: () => {
        cleanFields();
        showUserIconInButtonBorrowerNotRegistered();
      },
      success: (data) => {
        populateBorrower(data, id);
        showFormBorrower();
      },
      error: handleError,
    });
  }

  $formBorrower.ready(() => {
    showOptionNewInSelectBorrower();
    showAbbrRequiredInLabels();
    if (isInvalidForm) {
      showFormBorrower();
      $selectBorrower.val('new-borrower');
      return;
    }
    if (!borrowerId) return;
    removeIsActiveClassForm();
    showFormBorrower();
    enabledButtonForm($buttonContinue);
  });

  $selectBorrower.on('change', function () {
    const isOptionNewBorrowerSelected = $(this).children(':selected').attr('data-borrower');
    const selectedBorrowerId = $selectBorrower.children(':selected').val();
    if (isOptionNewBorrowerSelected) {
      cleanFields();
      showFormBorrower();
      showPlusIconInButtonBorrowerNotRegistered();
      return;
    }
    if (selectedBorrowerId) {
      searchBorrower(selectedBorrowerId);
      return;
    }
    cleanFields();
    hideFormBorrower();
    showUserIconInButtonBorrowerNotRegistered();
  });

  $buttonBorrowerNotRegistered.on('click', (event) => {
    event.preventDefault();
    $selectBorrower.val('new-borrower');
    $selectBorrower.trigger('change');
    cleanFields();
    showFormBorrower();
    showPlusIconInButtonBorrowerNotRegistered();
  });

  $inputsBorrower.on('change', dispatchValidateForm);
});
