onLoad(($) => {
  const nodes = $('.JS-create-internal-beneficiary');

  if (!nodes.length) return;

  const infoArea = $('.JS-internal-beneficiary-info-area');
  const errorArea = $('.JS-internal-beneficiary-error-area');

  const error = errorArea.find('#JS-internal-beneficiary-error');
  const documentNumber = nodes.find('#ip_bank_account_client_document_number');
  const accountNumber = nodes.find('#ip_bank_account_account_number');
  const searchButton = $('.JS-internal-beneficiary-search-button');
  const searchButtonArea = $('.JS-internal-beneficiary-search-button-area');
  const queryUrl = nodes[0].dataset.queryurl;

  const infoName = infoArea.find('#JS-internal-beneficiary-name');
  const infoDocumentNumber = infoArea.find('#JS-internal-beneficiary-document-number');
  const infoAgency = infoArea.find('#JS-internal-beneficiary-agency');
  const infoAccount = infoArea.find('#JS-internal-beneficiary-account');

  const repeatSearch = infoArea.find('.JS-internal-beneficiary-repeat');

  infoArea.hide();
  errorArea.hide();

  const enableButton = () => {
    searchButton.removeAttr('readonly').removeAttr('disabled').removeClass('disabled');
  };

  const disableButton = () => {
    searchButton.attr({ disabled: true, readonly: 'readonly' }).addClass('disabled');
  };

  const disableFields = () => {
    documentNumber.attr({ readonly: 'readonly' });
    accountNumber.attr({ readonly: 'readonly' });
  };

  const enableFields = () => {
    documentNumber.removeAttr('readonly').val('');
    accountNumber.removeAttr('readonly').val('');

    documentNumber.focus();
  };

  const searchData = () => ({ account_number: accountNumber.val(), document_number: documentNumber.val() });

  const searchAnother = () => {
    enableButton();
    infoArea.hide();
    errorArea.hide();
    searchButtonArea.show();
    enableFields();
  };

  const findIpBankAccount = () => {
    $.ajax({
      type: 'get',
      dataType: 'json',
      url: queryUrl,
      data: searchData(),
      success(data) {
        infoName.text(data.name);
        infoDocumentNumber.text(data.document_number);
        infoAgency.text(data.agency);
        infoAccount.text(data.account);

        infoArea.show();
        searchButtonArea.hide();
        errorArea.hide();
        disableButton();
        disableFields();
      },
      error(data) {
        infoArea.hide();
        searchButtonArea.show();
        enableButton();
        errorArea.show();
        error.text(JSON.parse(data.responseText).message || 'Não foi possível buscar a conta grafeno.');
      },
    });
  };

  repeatSearch.click(() => {
    searchAnother();
  });

  searchButton.click(() => {
    findIpBankAccount();
  });
});
