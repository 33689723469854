onLoad(($) => {
  const $select = $('#select-group-autocomplete');

  $select.on('change', function autocomplete() {
    const $node = $(this);
    const url = $node.data('url');
    const selectedValue = $node.children('option:selected').val();
    const idParam = selectedValue !== '' ? `?id=${selectedValue}` : '';
    if ($node.data('reload-on-change') === true) window.location.href = `${url}${idParam}`;
    $node.data('reload-on-change', true);
  });
});
