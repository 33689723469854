onLoad(($) => {
  const nodes = $('.JS-input-or-specified-value');

  if (!nodes.length) return;

  const inputField = $(`#${nodes[0].dataset.inputfield}`);
  const amount = nodes[0].dataset.amount;

  nodes.change(function () {
    if (this.checked) {
      inputField
        .data('current-value', inputField.val())
        .prop('readonly', true)
        .val(amount).change();
    } else {
      inputField.prop('readonly', false).val(inputField.data('current-value')).change();
    }
  });
});
