onLoad(($) => {
  const $newsShort = $('.JS-callout-news-short');

  if (!$newsShort.length) return;

  const localNewsShort = JSON.parse(localStorage.getItem('@grafeno-callout-news-short')) || {};
  const $closeBtn = $('.JS-callout-news-short-close-btn');

  $newsShort.each((i, e) => {
    const $element = $(e);
    const id = $element.attr('id');
    if (id in localNewsShort) {
      $element.addClass('d-none');
    }
  });

  function resetLocalStorage(id) {
    localNewsShort[id] = { closed: true };
    localStorage.setItem('@grafeno-callout-news-short', JSON.stringify(localNewsShort));
  }

  $closeBtn.on('click', function () {
    thisNewsShort = $(this).closest('.JS-callout-news-short');
    id = thisNewsShort.attr('id');
    thisNewsShort.addClass('d-none');
    resetLocalStorage(id);
  });
});
