const purposeSalaryOptions = (purposeSelect) => {
  purposeSelect.append(`<option value="credit_account">Crédito Em Conta</option>
                      <option value="payment_salary">Pagamento De Salários</option>`);
};

const purposeLimitedOptions = (purposeSelect) => {
  purposeSalaryOptions(purposeSelect);
  purposeSelect.append(`<option value="suppliers_payment">Pagamento De Fornecedores</option>
                      <option value="payment_fees">Pagamento De Honorários</option>
                      <option value="alimony">Pensao Alimenticia</option>
                      <option value="transfer_between_accounts_same_ownership">
                        Transferência Entre Contas De Mesma Titularidade
                      </option>
                      <option value="redemption_financial_application">
                        Resgate Aplic. Financ. Cliente p/ Cta De Sua Titularidade
                      </option>
                      <option value="financial_application">
                        Aplicação Financeira Em Nome Do Cliente Remetente
                      </option>`);
};

const purposeCompleteOptions = (purposeSelect) => {
  purposeLimitedOptions(purposeSelect);
  purposeSelect.append(`<option value="payment_tax">Pagamento De Impostos, Tributos E Taxas</option>
                      <option value="payment_public_service">Pagamento A Concessionárias De Serviço Público</option>
                      <option value="dividends_payment">Pagamento De Dividendos</option>
                      <option value="payment_rent_condominium">Pagamento De Aluguéis E Taxas De Condomínio</option>
                      <option value="payment_title">Pagamento De Duplicatas E Títulos</option>
                      <option value="payment_school">Pagamento De Mensalidade Escolar</option>
                      <option value="sponsorship_tax_incentive">Patrocínio Com Incentivo Fiscal</option>
                      <option value="donate_tax_incentive">Doação Com Incentivo Fiscal</option>
                      <option value="adjustment_market_position">Ajuste Posição Mercado Futuro</option>
                      <option value="purchase_and_sale_operations">Operações Compra E Venda De Ações BV/BMB</option>
                      <option value="contracts_referenced_to_share">
                        Contratos Referenciados Ações/Índices Ações BV/BMF
                      </option>
                      <option value="fixed_and_variable_income_operation">
                        Oper Renda Fixa E Variável Com Utilização Intermediário
                      </option>
                      <option value="income_tax_refund">Restituição De Imposto De Renda</option>`);
};

const togglePurposeOptions = (purposeSelect, limitClientPurpose, kind) => {
  purposeSelect.empty();

  if (kind === 'Conta Salário') {
    purposeSalaryOptions(purposeSelect);
  } else if (limitClientPurpose === 'true') {
    purposeLimitedOptions(purposeSelect);
  } else {
    purposeCompleteOptions(purposeSelect);
  }
  purposeSelect.append('<option value="other">Outros</option>');
  purposeSelect.val('credit_account').change();
};

export default togglePurposeOptions;
