onLoad(($) => {
  const $eventsContainer = $('#events');
  if (!$eventsContainer.length) return;

  const $boletoRegistrationSelect = $('#messaging_rule_boleto_registration_kind_cd');
  const $btnConfirm = $('.btn-confirm');
  const $eventsTable = $('.events-table');
  const $eventTbody = $('.event-body');
  let eventsKeys;

  const isImmediately = value => value === 'immediately';
  const eventsLength = () => $('.nested-fields').length;

  $.ajax({
    url: '/api/messaging_rule_events',
    type: 'get',
    dataType: 'json',
    success: (data) => {
      eventsKeys = data;
    },
  });

  const cleanOptions = (field) => {
    field.find('option').remove().end().append('<option></option>');
  };

  const appendValuesToSelect = (field, collection) => {
    cleanOptions(field);
    $.each(collection, (key, value) => {
      field.append(new Option(value.translation, key));
    });
  };

  const updateKindField = (value, kindField) => {
    switch (value) {
      case 'email':
        appendValuesToSelect(kindField, eventsKeys.email.kinds);
        break;
      case 'letter':
        appendValuesToSelect(kindField, eventsKeys.letter.kinds);
        break;
      case 'letter_with_confirmation':
        appendValuesToSelect(kindField, eventsKeys.letter_with_confirmation.kinds);
        break;
      default:
        cleanOptions(kindField);
    }
  };

  const updateTriggerTimeField = (value, triggerTimeField) => {
    const emailKinds = eventsKeys.email.kinds;
    const letterKinds = eventsKeys.letter.kinds;
    const letterConfirmationKinds = eventsKeys.letter_with_confirmation.kinds;
    switch (value) {
      case 'payment_solicitation':
        appendValuesToSelect(triggerTimeField, emailKinds.payment_solicitation.trigger_times);
        break;
      case 'payment_solicitation_ted':
        appendValuesToSelect(triggerTimeField, emailKinds.payment_solicitation_ted.trigger_times);
        break;
      case 'day_of_payment':
        appendValuesToSelect(triggerTimeField, emailKinds.day_of_payment.trigger_times);
        break;
      case 'day_of_payment_ted':
        appendValuesToSelect(triggerTimeField, emailKinds.day_of_payment_ted.trigger_times);
        break;
      case 'delayed':
        appendValuesToSelect(triggerTimeField, emailKinds.delayed.trigger_times);
        break;
      case 'delayed_ted':
        appendValuesToSelect(triggerTimeField, emailKinds.delayed_ted.trigger_times);
        break;
      case 'payment':
        appendValuesToSelect(triggerTimeField, emailKinds.payment.trigger_times);
        break;
      case 'paid_externally':
        appendValuesToSelect(triggerTimeField, emailKinds.paid_externally.trigger_times);
        break;
      case 'send_boleto_letter':
        appendValuesToSelect(triggerTimeField, letterKinds.send_boleto_letter.trigger_times);
        break;
      case 'send_boleto_letter_with_confirmation':
        appendValuesToSelect(triggerTimeField,
          letterConfirmationKinds.send_boleto_letter_with_confirmation.trigger_times);
        break;
      default:
        cleanOptions(triggerTimeField);
    }
  };

  const updateDaysAbsField = (value, daysAbsField) => {
    if (value === 'due_day') {
      daysAbsField.prop('disabled', true);
      daysAbsField.val(0);
    } else if (isImmediately(value)) {
      daysAbsField.prop('disabled', true);
      daysAbsField.prop('required', false);
      daysAbsField.val('');
    } else {
      daysAbsField.prop('disabled', false);
      daysAbsField.prop('required', true);
      daysAbsField.val('');
    }
  };

  function validateMessagingRules(value) {
    const isValidate = (isImmediately(value) || eventsLength()) && value !== '';
    $btnConfirm[`${isValidate ? 'remove' : 'add'}Class`]('btn-disabled');
  }

  $eventsContainer.on('change', '.JS-communication-format', function change() {
    const item = $(this).closest('.nested-fields');
    const communicationFormatField = item.find('.JS-communication-format');
    const kindField = item.find('.JS-kind');
    updateKindField(communicationFormatField.val(), kindField);
  });


  $eventsContainer.on('change', '.JS-kind', function change() {
    const item = $(this).closest('.nested-fields');
    const kindField = item.find('.JS-kind');
    const triggerTimeField = item.find('.JS-trigger-time');
    updateTriggerTimeField(kindField.val(), triggerTimeField);
  });

  $eventsContainer.on('change', '.JS-trigger-time', function change() {
    const item = $(this).closest('.nested-fields');

    const triggerTimeField = item.find('.JS-trigger-time');
    const daysAbsField = item.find('.JS-days-abs');

    updateDaysAbsField(triggerTimeField.val(), daysAbsField);
  });

  $eventsContainer.on('cocoon:after-insert', () => {
    $eventsTable.removeClass('d-none');
    $eventTbody.append($('.nested-fields'));
    $btnConfirm.removeClass('btn-disabled');
  });

  $eventsContainer.on('cocoon:after-remove', () => {
    if (eventsLength() <= 0) {
      $eventsTable.addClass('d-none');
      validateMessagingRules($boletoRegistrationSelect.val());
    }
  });

  $boletoRegistrationSelect.on('change', (event) => {
    validateMessagingRules(event.target.value);
  });

  $eventsContainer.find('.nested-fields').each((key, value) => {
    const item = $(value);
    const daysAbsField = item.find('.JS-days-abs');
    const daysAbsValue = daysAbsField.val();

    if (daysAbsValue === '') {
      daysAbsField.prop('disabled', true);
    }
  });

  validateMessagingRules($boletoRegistrationSelect.val());
});
