import TransferRequestApproveBase from './approve_base';

class TransferRequestApprove extends TransferRequestApproveBase {
  constructor($element) {
    super($element);
    this.$element.submit(() => this.approveTransferRequestEvent());
  }

  submitApproval(extraParams = {}) {
    this.appendAdditionalParams(extraParams);

    this.toggleSubmitStatus();
    this.$element.submit();
    this.toggleSubmitStatus();
  }

  appendAdditionalParams(extraParams = {}) {
    Object.entries(extraParams).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      this.$element.append(input);
    });
  }
}

onLoad($ => $('form.approve-transfer-request').each((_, element) => new TransferRequestApprove($(element))));
