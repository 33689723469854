onLoad(($) => {
  $mainElement = $('#JS-PixKeysIndex');

  if (!$mainElement.length) {
    return;
  }

  const params = new URLSearchParams(window.location.search);
  if (params.get('synchronizing_key') === 'true') {
    Swal.fire({
      title: 'Sua solicitação foi enviada com sucesso!',
      text: 'Aguarde a sincronização. A página será recarregada automaticamente.',
      icon: 'success',
      showConfirmButton: false,
    });

    setTimeout(() => {
      path = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
      window.location.href = path;
    }, 8000);
  }
});
