import UserAuth from '../../user_auth';

class ProtestAgreementApprove {
  constructor($element) {
    this.$element = $element;
    this.agreementId = this.$element.data('agreementId');
    this.options = this.$element.data('approveProtestAgreement');
    this.defaultErrorTitle = 'Erro';
    this.defaultErrorMessage = 'Não foi possível aprovar a prévia do termo';
    this.targetClass = 'protest-agreement-approval';

    this.$element.on('submit', () => this.approvalHandler());
  }

  approvalHandler() {
    if (!this.isSubmitting()) {
      this.approveAgreement().catch(() => this.errorDialog());
      return false;
    }

    return true;
  }

  async approveAgreement() {
    if ((await this.showConfirmDialog()).isDismissed) return;

    const validation = await this.validate();
    if (validation.valid) {
      const requiresPin = this.options.confirm_pin;
      const requires2fa = this.options.confirm_2fa;
      let extraParams = {};

      const userAuth = new UserAuth(requires2fa, requiresPin);
      const validationResult = await userAuth.validate();

      if (!validationResult.success) {
        return;
      }

      extraParams = validationResult.data;
      this.approve(extraParams);
    } else if (validation.state === 'expired') {
      this.errorDialog(validation.message);
    } else {
      await this.showExpirationModal();
      this.expire();
    }
  }

  submit() {
    this.toggleSubmitStatus();
    this.$element.submit();
    this.toggleSubmitStatus();
  }

  expire() {
    const url = `/protest/agreements/${this.agreementId}/expire`;

    this.$element.attr('action', url);
    this.submit();
  }

  approve(extraParams = {}) {
    this.appendAdditionalParams(extraParams);

    this.submit();
  }

  validate() {
    const url = `/protest/agreements/${this.agreementId}/validate`;
    const method = 'get';

    return new Promise((success, error) => {
      $.ajax({
        success,
        error,
        url,
        method,
        dataType: 'json',
        data: {},
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
      });
    });
  }

  showExpirationModal() {
    return Swal.fire({
      title: 'Atenção',
      html: this.options.expiration,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text: text || this.defaultErrorMessage,
      title: this.defaultErrorTitle,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    }).then(() => {
      window.location.reload();
    });
  }

  showConfirmDialog() {
    if (this.options.html === undefined) throw new Error('Html is required');

    return Swal.fire({
      title: this.options.title || 'Deseja realmente aprovar os termos das instruções de protesto?',
      html: this.options.html,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  isSubmitting() {
    return !this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  appendAdditionalParams(extraParams = {}) {
    Object.entries(extraParams).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      this.$element.append(input);
    });
  }
}

onLoad($ => $('form.protest-agreement-approval').each((_, element) => new ProtestAgreementApprove($(element))));
