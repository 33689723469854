import GeneralShimmer from '../../helper/generalShimmer';
import TableValueHelper from '../../helper/tableValuesHelper';

onLoad(($) => {
  const $chargeGroupTable = $('#chargeGroupTable');
  if (!$chargeGroupTable.length) return;

  $('.toggle-charge-group-table-shimmer')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-charge-group-table-container'));

  const { handleValueKinds, sanitizeEmptyValues } = TableValueHelper();
  const $chargeGroupEditLink = $('#chargeGroupEditLink');
  const $chargeGroupSelector = $('.JS-charge-group-selector');

  function handleChargeGroupError() {
    $('#chargeGroupTable .charge-table-row span').each((_index, el) => {
      if ($(el).data('overwritten') === false) {
        $(el).text('-');
      }
    });
    $chargeGroupSelector.val('');
    $chargeGroupEditLink.prop('href', '#');
    $chargeGroupEditLink.addClass('d-none');
  }


  function elementAndValue(data) {
    const interestType = `Juros mensal ${handleValueKinds(data.interest_type_cd)}`;
    const discountType = `Desconto ${handleValueKinds(data.discount_type_cd)}`;

    return {
      '#charge_table_days_write_off_after_due_date': data.days_write_off_after_due_date,
      '#charge_table_discount_type_cd': discountType,
      '#charge_table_discount_value': data.discount_value,
      '#charge_table_discount_until': data.discount_until,
      '#charge_table_formatted_abatement_value': data.abatement_value,
      '#charge_table_interest_type_cd': interestType,
      '#charge_table_monthly_interest': data.monthly_interest,
      '#charge_table_formatted_applicable_fine': data.formatted_applicable_fine,
    };
  }

  function populateTable(dataObject) {
    Object.entries(dataObject).forEach((entry) => {
      const [element, value] = entry;
      if ($chargeGroupTable.find(element).data('overwritten') === false) {
        $chargeGroupTable.find(element).text(value);
      }
    });
  }

  function handleChargeGroupSuccess(data) {
    if (!data.id) {
      handleChargeGroupError();
      return;
    }

    if (data) {
      const chargeGroup = sanitizeEmptyValues(data);

      populateTable(elementAndValue(chargeGroup));

      $chargeGroupEditLink.prop('href', `/charge_groups/${data.id}/edit`);
      $chargeGroupEditLink.removeClass('d-none');
    }
  }

  function findChargeGroup(id) {
    if (!id) {
      handleChargeGroupError();
      return;
    }

    $.ajax({
      url: `/charge_groups/${id}`,
      type: 'get',
      dataType: 'json',
      success: (data) => {
        handleChargeGroupSuccess(data);
      },
      error: () => { handleChargeGroupError(); },
    });
  }

  const handleChargeGroupChange = () => {
    if (!$chargeGroupSelector.length) {
      return;
    }
    const $chargeGroupId = $chargeGroupSelector.val();

    findChargeGroup($chargeGroupId);
  };

  $('.JS-charge-group-selector').on('change', () => {
    handleChargeGroupChange();
  });

  function mirrorAdditionalSettings() {
    const sharedIdNames = ['days_write_off_after_due_date', 'discount_type_cd', 'discount_value', 'discount_until',
      'formatted_abatement_value', 'interest_type_cd', 'monthly_interest', 'formatted_applicable_fine'];

    sharedIdNames.forEach((idName) => {
      const fieldValue = $(`.JS-additional-settings-form #charge_${idName}`).val();
      if (fieldValue) {
        $(`#chargeGroupTable #charge_table_${idName}`)
          .text($('.JS-additional-settings-form').find(`#charge_${idName}`).val());
        $(`#chargeGroupTable #charge_table_${idName}`).data('overwritten', true);
      } else {
        $(`#chargeGroupTable #charge_table_${idName}`).text('-');
        $(`#chargeGroupTable #charge_table_${idName}`).data('overwritten', false);
      }
    });
  }

  function maskChargeGroupTable() {
    const [discountType, interestType, discountValue, discountUntil, abatementValue] = [
      $('.charge-table-row #charge_table_discount_type_cd').text(),
      $('.charge-table-row #charge_table_interest_type_cd').text(),
      $('.charge-table-row #charge_table_discount_value').text(),
      $('.charge-table-row #charge_table_discount_until').text(),
      $('.charge-table-row #charge_table_formatted_abatement_value').text()];

    $('.charge-table-row #charge_table_discount_type_cd').text(`Desconto ${handleValueKinds(discountType)}`);
    $('.charge-table-row #charge_table_interest_type_cd').text(`Juros mensal ${handleValueKinds(interestType)}`);
    $('.charge-table-row #charge_table_discount_value').text(discountValue.replace(/.%/g, '').replace(',', '.'));
    if (/^\d{4}-\d{2}-\d{2}$/.test(discountUntil)) {
      const [year, month, day] = discountUntil.split('-');
      $('.charge-table-row #charge_table_discount_until').text(`${day}/${month}/${year}`);
    }
    $('.charge-table-row #charge_table_formatted_abatement_value')
      .text(abatementValue.replace('.', '').replace(',', '.'));
  }

  $('.JS-additional-settings-form-submit').on('click', () => {
    mirrorAdditionalSettings();
    maskChargeGroupTable();
    handleChargeGroupChange();
  });

  mirrorAdditionalSettings();
  maskChargeGroupTable();
  handleChargeGroupChange();
});
