import { validateForm } from '../helper/validate_form';

onLoad(($) => {
  if (!$('#nc-simulations').length) return;

  const simulationBtn = $('#JS-nc-simulations-btn');
  const inputs = $('#nc-simulations input');

  function composeValidateForm() {
    return inputs.map(function () {
      return $(this).val().length > 0;
    });
  }

  inputs.on('change', () => {
    validateForm({
      $form: composeValidateForm(),
      $button: simulationBtn,
    });
  });
});
