onLoad(($) => {
  if (!$('#user_password').length) return;

  const divClasses = ['user_current_password', 'user_password', 'user_password_confirmation'];

  divClasses.forEach((className) => {
    const $inputForm = $(`div .${className}`);
    $inputForm.append(`
      <div class="toggle-password">
        <span toggle='#${className}' class='fa fa-fw fa-eye ${className}-icon'></span>
      </div>`);

    const $input = $inputForm.find('input');
    $inputForm.find('.toggle-password').on('click', function () {
      const $icon = $(this).find('.fa-eye').length ? $(this).find('.fa-eye') : $(this).find('.fa-eye-slash');
      const inputType = $input.attr('type') === 'password' ? 'text' : 'password';
      $icon.toggleClass('fa-eye fa-eye-slash');
      $input.attr('type', inputType);
    });
  });
});
