import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  const $barcodePaymentsPendingIndex = $('.ds-barcode-payments-pending-index');
  if (!$barcodePaymentsPendingIndex.length) return;

  const $checkboxes = $('input[type="checkbox"]');
  const $buttonApproveAll = () => $('[data-action="approve_all"]');
  const $buttonRejectAll = () => $('[data-action="reject_all"]');
  const $barcodePaymentsValues = () => $('.JS-barcode-payment-value');
  const NUMERICAL_SYSTEM = 100;
  const INITIAL_VALUE = 0;
  let total = INITIAL_VALUE;
  let quantity = INITIAL_VALUE;

  const convertToNumber = value => Number(value) * NUMERICAL_SYSTEM;
  const convertToString = value => String(value / NUMERICAL_SYSTEM);

  function handleModal($element) {
    const currencyHelper = new CurrencyHelper();
    const $modalContent = $($element.data('html'));
    $modalContent.find('.JS-barcode-payment-total').text(currencyHelper.format(total));
    $modalContent.find('.JS-barcode-payment-quantity').text(quantity);
    $element.data('html', $modalContent);
  }

  function handleCheckbox() {
    const values = [];
    $barcodePaymentsValues().each(function () {
      const $checkbox = $(this).siblings('td').first().find('input[type="checkbox"]');
      const isChecked = $checkbox.is(':checked');
      if (!isChecked) return;
      const value = convertToNumber($(this).data('barcode-payment-value'));
      values.push(value);
    });
    total = convertToString(values.reduce((accumulated, value) => accumulated + value, INITIAL_VALUE));
    quantity = values.length;
    handleModal($buttonApproveAll());
    handleModal($buttonRejectAll());
  }

  async function eventPropagation(event) {
    return new Promise((resolve) => {
      resolve(event.bubbles);
    });
  }

  $checkboxes.on('change', async (event) => {
    const isEventPropagation = await eventPropagation(event);
    if (isEventPropagation) handleCheckbox();
  });
});
