import CurrencyHelper from './helper/currencyHelper';
import togglePurposeOptions from './helper/purposeOptionsHelper';
import TransferRequestHelper from './helper/TransferRequestHelper';

onLoad(($) => {
  const nodes = $('.JS-beneficiary-info');
  if (!nodes.length) return;

  const currencyHelper = new CurrencyHelper();
  const transferRequestHelper = new TransferRequestHelper();

  const $accountLimitAlert = $('.account-limit-container');
  const $changeToPixBtn = $('.btn-change-to-pix');
  const $confirmTransferBtn = $('.JS-confirm-transfer-btn');
  const $finalizeTransferContainer = $('.JS-finalize-transfer');
  const beneficiaryInfoDiv = $('.beneficiary-info');
  const beneficiaryTable = $('.table.table-show').find('td');
  const limitClientPurpose = $('#limit_client_purpose').val();
  const purposeSelect = $('.client-purpose-select');

  const beneficiaryInfo = (data) => {
    const accountLimit = data.account_limit;
    transferRequestHelper.toggleFinalizeContainer(!accountLimit, $finalizeTransferContainer);

    if (accountLimit) {
      $accountLimitAlert.removeClass('d-none');
      $changeToPixBtn.prop('href', `/pix/transfer_requests/new?beneficiary_id=${data.id}&page=manual`);
      return;
    }

    beneficiaryTable.filter('#beneficiaryName').text(data.name);
    beneficiaryTable.filter('#beneficiaryDocument').text(data.masked_document_number);
    beneficiaryTable.filter('#beneficiaryAgency').text(data.agency);
    beneficiaryTable.filter('#beneficiaryAccount').text(data.account);

    beneficiaryInfoDiv.find('.beneficiary_name').text(data.name);
    beneficiaryInfoDiv.find('.beneficiary_document').text(data.masked_document_number);
    beneficiaryInfoDiv.find('.beneficiary_bank_info').text(data.bank_info);
    beneficiaryInfoDiv.find('.beneficiary_agency').text(data.agency);
    beneficiaryInfoDiv.find('.beneficiary_account').text(data.account);
    beneficiaryInfoDiv.find('.beneficiary_account_kind').text(data.account_kind);

    beneficiaryInfoDiv.find('.beneficiary_transfer_limit').text(currencyHelper.format(data.beneficiary_transfer_limit));
    beneficiaryInfoDiv.find('.available_transfer_limit').text(currencyHelper.format(data.available_transfer_limit));

    beneficiaryInfoDiv.removeClass('d-none');
    togglePurposeOptions(purposeSelect, limitClientPurpose, data.account_kind);
  };

  const handleError = () => {
    $('.JS-finalize-transfer').addClass('d-none');
  };

  nodes.on('change', function () {
    $confirmTransferBtn.addClass('btn-disabled');
    $accountLimitAlert.addClass('d-none');

    $.ajax({
      url: '/ip_bank_account/beneficiaries/search',
      type: 'get',
      dataType: 'json',
      data: { id: this.value },
      success: beneficiaryInfo,
      error: handleError,
    });
  });

  // nodes pre-selecionados no backend
  nodes.filter((i, node) => $(node).val()).change();
});
