onLoad(($) => {
  $('#JS-2fa-send-2faemail-code').on('ajax:success', () => {
    $('#email-code-container').removeClass('d-none');
    Swal.fire({
      title: 'E-mail enviado com sucesso',
      icon: 'success',
      toast: true,
      position: 'top-end',
      timer: 10000,
    });
  }).on('ajax:error', () => {
    Swal.fire({
      title: 'Ocorreu um erro ao enviar o e-mail',
      icon: 'error',
      toast: true,
      position: 'top-end',
      timer: 10000,
    });
  });
});
