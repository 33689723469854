import CurrencyHelper from '../helper/currencyHelper';
import TransferRequestHelper from '../helper/TransferRequestHelper';

onLoad(($) => {
  if (!$('.ds-pix-transfer-request-new').length) return;

  const currencyHelper = new CurrencyHelper();
  const transferRequestHelper = new TransferRequestHelper();

  const $beneficiarySelect = $('#JS-pixTransferBeneficiary');
  const $confirmPixTransferBtn = $('.JS-confirm-pix-transfer-btn');
  const $finalizeTransferContainer = $('#JS-transferFinalize');
  const $pixDesctiptions = $('#JS-transfer-pixDescription');
  const $transferRequestValue = $('#transfer_request_formatted_value');

  function alertBox(title, message) {
    Swal.fire({
      title,
      text: message,
      icon: 'error',
    });
  }

  function setBeneficiary(data) {
    const $beneficiaryInfoDiv = $('.beneficiary-info');
    const infoKey = data.data;

    if (!infoKey) return;

    const changeIcon = `
      <span data-toggle="tooltip" data-placement="top" data-title="A conta atrelada à esta chave foi atualizada">
        <i class="fas fa-exclamation-circle icon"></i>
      </span>`;

    const fields = data.conflict_by_field;

    $beneficiaryInfoDiv.find('.beneficiary_name').html(`${infoKey.name}
                                                        ${fields && fields.name.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_document').html(`${infoKey.document_number}
                                                ${fields && fields.document_number.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_pix_key').html(`${infoKey.pix_key || ''}
                                                            ${fields && fields.pix_key.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_bank_info').html(`${infoKey.bank_name}
                                                          ${fields && fields.bank_name.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_agency').html(`${infoKey.agency}
                                                          ${fields && fields.agency.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_account').html(`${infoKey.account}
                                                            ${fields && fields.account.conflict ? changeIcon : ''}`);
    $beneficiaryInfoDiv.find('.beneficiary_account_kind').html(`${infoKey.translated_kind || ''}
                                                        ${fields && fields.kind_cd.conflict ? changeIcon : ''}`);

    $('#JS-pix_change_info').toggle(data.conflict === true);
    $('[data-toggle="tooltip"]').tooltip();
    $beneficiaryInfoDiv.removeClass('d-none');
  }

  $beneficiarySelect.on('change', (e) => {
    e.preventDefault();

    const dataParams = {
      beneficiary: { uuid: $beneficiarySelect.val() },
    };

    $.ajax({
      url: '/pix/transfer_requests/consult_beneficiary',
      type: 'post',
      dataType: 'json',
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: dataParams,
      success: (data) => {
        if (data.success) {
          transferRequestHelper.toggleFinalizeContainer($beneficiarySelect.val() !== '', $finalizeTransferContainer);
          setBeneficiary(data);
        } else {
          transferRequestHelper.toggleFinalizeContainer(false, $finalizeTransferContainer);
          alertBox('Beneficiário', 'Não foi possível encontrar um beneficiário com os dados informados.');
        }
      },
      error: () => {
        transferRequestHelper.toggleFinalizeContainer(false, $finalizeTransferContainer);
        alertBox('Atenção', 'Houve um erro na consulta.');
      },
    });
  });

  $pixDesctiptions.on('input', (event) => {
    if (event.target.value.length) {
      $('#JS-charCount').text(`${event.target.value.length} / 140 caracteres`);
    } else {
      $('#JS-charCount').text('');
    }
  });

  $transferRequestValue.on('change input', (event) => {
    const value = currencyHelper.unformat(event.target.value);
    if (Number.isNaN(value) || value === 0) {
      $confirmPixTransferBtn.addClass('btn-disabled');
      return;
    }
    $confirmPixTransferBtn.removeClass('btn-disabled');
  });

  if ($transferRequestValue.val()) {
    $transferRequestValue.val('').change();
  }

  if ($beneficiarySelect.val()) {
    $beneficiarySelect.change();
  }

  transferRequestHelper.toggleFinalizeContainer($beneficiarySelect.val() !== '', $finalizeTransferContainer);
});
