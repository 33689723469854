import { validateForm } from '../helper/validate_form';
import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  const $operationValues = $('#ccb-operation-values');
  if (!$operationValues.length) return;

  const EMPTY_VALUE = '';
  const DATA_CONTENT_VALUE = '+ 100% CDI';
  const INPUT_COMMISSION_DEFAULT_VALUE = '0';

  const currencyHelper = new CurrencyHelper();
  const $simulationButton = $('#JS-button-simulation-ccb-simulator-form');
  const $inputs = $('#ccb-simulator-form .form-group input');
  const $continueButton = $('#JS-button-continue-ccb-simulator-form');
  const $inpustRequired = $('abbr').parent().parent().map(function () {
    return $(this).find('input');
  });
  const $inputCommission = () => $('#commission_value');
  const inputCommissionValueUnformated = () => String(currencyHelper.unformat($inputCommission().val()));
  const $select = $('#JS-select-autocomplete');
  const $inputPreFixed = $('.JS-input-pre-fixed');
  const $inputHybrid = $('.JS-input-hybrid');
  const optionValue = () => $select.val();

  function composeValidateForm() {
    return $inpustRequired
      .filter(input => $(input).attr('type') !== 'hidden')
      .map(function () {
        return $(this).val().length > 0;
      });
  }

  function dispatchValidateForm() {
    validateForm({
      $button: $simulationButton,
      $form: composeValidateForm(),
      colorButton: 'btn-secondary',
    });
    validateForm({
      $button: $continueButton,
      $form: composeValidateForm(),
    });
  }

  function sanitizeInputCommission() {
    const isInputCommissionDefaultValue = inputCommissionValueUnformated() === INPUT_COMMISSION_DEFAULT_VALUE;
    if (isInputCommissionDefaultValue) return $inputCommission().val(EMPTY_VALUE);
    const isValidInputCommissionValue = !$inputCommission().val().startsWith('.');
    if (isValidInputCommissionValue) return null;
    const inputCommissionValueValid = $inputCommission().val().replace('.', '');
    return $inputCommission().val(inputCommissionValueValid);
  }

  function handleInputCommission() {
    const isInputCommissionEmpty = $inputCommission().val() === EMPTY_VALUE;
    if (isInputCommissionEmpty) $inputCommission().attr('val', INPUT_COMMISSION_DEFAULT_VALUE);
  }

  function handleInputs(handleInputsComposed) {
    $inputHybrid.val(handleInputsComposed.inputHybrid);
    $inputPreFixed
      .parent()
      .attr('data-content', handleInputsComposed.inputPreFixedDataContent);
  }

  function handleOptionHybridSelected() {
    const composeHandleInputs = {
      inputHybrid: optionValue(),
      inputPreFixedDataContent: DATA_CONTENT_VALUE,
    };
    handleInputs(composeHandleInputs);
  }

  function handleOptionPreFixedSelected() {
    const composeHandleInputs = {
      inputHybrid: optionValue(),
      inputPreFixedDataContent: EMPTY_VALUE,
    };
    handleInputs(composeHandleInputs);
  }

  function handleSelect() {
    const handler = {
      hybrid: () => handleOptionHybridSelected(),
      pre_fixed: () => handleOptionPreFixedSelected(),
    };
    handler[optionValue()]();
  }

  $operationValues.ready(() => {
    dispatchValidateForm();
    handleSelect();
    sanitizeInputCommission();
  });

  $inputs.on('change', dispatchValidateForm);
  $select.on('change', handleSelect);
  $simulationButton.on('click', handleInputCommission);
});
