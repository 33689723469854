import GeneralShimmer from './helper/generalShimmer';
import CurrencyHelper from './helper/currencyHelper';

const isValueUpdated = (shimmer) => {
  const value = shimmer.$elementToShimmer.text();
  return value.match(/(atualizando|R\$ ----)/) === null;
};

const isNumber = value => !Number.isNaN(parseFloat(value));

const template = (value) => {
  const currencyHelper = new CurrencyHelper();
  let formattedValue = value;
  if (isNumber(value)) formattedValue = currencyHelper.format(value);

  return `${formattedValue}`;
};

const setValues = ($element, value) => {
  $element.children('strong').html(template(value));
};

const updateValues = (data) => {
  const openChargesCard = $('.JS-calculation-open-total-value');
  const overdueChargesCard = $('.JS-calculation-overdue-total-value');
  const toUpdate = [
    [openChargesCard, data.open_total_value],
    [overdueChargesCard, data.overdue_total_value],
  ];

  toUpdate.forEach(([card, value]) => setValues(card, value));
};

const updateErrorModal = () => Swal.fire({
  icon: 'warning',
  title: 'Ops!',
  html: `Não foi possível atualizar as informações neste momento.
         É possível tentar novamente clicando no ícone <i class="far fa-eye"></i>`,
  confirmButtonText: 'Entendi',
  showCancelButton: false,
});

const handleError = () => {
  const failMessage = 'Não foi possível atualizar as informações neste momento. '
      + 'É possível tentar novamente clicando no ícone de visualizar valores';
  const emptyValue = `R$ ----  <i class="fas fa-question-circle" title='${failMessage}'></i>`;
  const data = {
    open_total_value: emptyValue,
    overdue_total_value: emptyValue,
  };
  updateValues(data);
  updateErrorModal();
};

const updateDashboardCards = (shimmer) => {
  if (isValueUpdated(shimmer)) return;

  $.ajax({
    url: '/charges/dashboard_calculations',
    type: 'get',
    dataType: 'json',
    data: { },
  }).done(data => updateValues(data))
    .fail(() => handleError());
};

onLoad(($) => {
  $('.JS-calculation-overdue-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-overdue-charges-icon.JS-toggle-balance', updateDashboardCards));
  $('.JS-calculation-open-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-open-charges-icon.JS-toggle-balance', updateDashboardCards));
});
