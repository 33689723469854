/* eslint-disable import/prefer-default-export, consistent-return */

export function urlQueryParamsToObject(url) {
  if (typeof url !== 'string') return;
  if (!url.includes('?')) return;
  const [, params] = url.split('?');
  return JSON.parse(`{"${
    decodeURIComponent(params)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/q/g, '')
      .replace(/\[/g, '')
      .replace(/\]/g, '')}"}`);
}
