onLoad(($) => {
  if (!$('.JS-minimun-validated').length) return;

  const $emailsList = $('#emails-holder, #emails-mover');

  const updateRequires = (holderOrMover) => {
    const $emailList = $emailsList.filter(`#emails-${holderOrMover}`);
    const $emailListCheckboxes = $emailList.find('input:checkbox');
    const hasChecked = $emailListCheckboxes.filter(':checked').length >= 1;

    if (hasChecked) {
      $emailListCheckboxes.removeAttr('required').get(0).setCustomValidity('');
    } else {
      $emailListCheckboxes.attr('required', true).get(0)
        .setCustomValidity('É necessário escolher ao menos um e-mail desta lista');
    }
  };

  const loadListRequired = () => {
    updateRequires('holder');
    updateRequires('mover');
  };

  $emailsList.find('input:checkbox').on('change', () => {
    loadListRequired();
  });

  loadListRequired();
});
