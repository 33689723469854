import WebWorker from '../web_worker';
import workerTimer from './worker_timer';

onLoad(($) => {
  const $buttonReceiveInstructions = $('.JS-button-receive-instructions');
  if (!$buttonReceiveInstructions.length) return;

  const $spanCounter = $('.JS-span-timer');
  const currentDate = new Date();
  const storage = localStorage.getItem('@grafeno-timer');
  const session = $('.JS-data-session').data('session');
  const composeDate = () => {
    if (session) return new Date(session);
    if (storage) return new Date(storage);
    return new Date();
  };
  const date = composeDate();
  let milliseconds = 300000;

  function handleClearTimer() {
    localStorage.removeItem('@grafeno-timer');
    $spanCounter.text('');
    $buttonReceiveInstructions.removeClass('btn-disabled');
  }

  function convertDateToMilliSeconds(_date) {
    const hours = _date.getHours() * 3600000;
    const minutes = _date.getMinutes() * 60000;
    const seconds = _date.getSeconds() * 1000;
    return hours + minutes + seconds;
  }

  function handleTimer() {
    const dateMilliseconds = convertDateToMilliSeconds(date);
    const currentDateMilliseconds = convertDateToMilliSeconds(currentDate);
    const isTimeExpired = (dateMilliseconds + milliseconds) < currentDateMilliseconds;
    const isDateValid = currentDate.toLocaleDateString('pt-br') === date.toLocaleDateString('pt-br');

    if (!isDateValid || (isDateValid && !(storage)) || isTimeExpired) {
      handleClearTimer();
      return;
    }

    $buttonReceiveInstructions.addClass('btn-disabled');
    milliseconds -= (currentDateMilliseconds - dateMilliseconds);

    const worker = new WebWorker(workerTimer);
    worker.postMessage({ milliseconds });
    worker.addEventListener('message', (event) => {
      const { minutes, clearTimer } = event.data;
      $spanCounter.text(`Você poderá reenviar o e-mail em ${minutes}`);
      if (clearTimer) {
        handleClearTimer();
        worker.terminate();
      }
    });
  }

  $buttonReceiveInstructions.ready(() => {
    handleTimer();
  });

  $buttonReceiveInstructions.on('click', () => {
    const dateISO = (new Date()).toISOString();
    localStorage.setItem('@grafeno-timer', dateISO);
  });
});
