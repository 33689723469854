onLoad(($) => {
  let $mainElement = $('#transfer_requests_new');

  if ($mainElement.length === 0) {
    $mainElement = $('#transfer_requests_create');
    return false;
  }

  const $beneficiaryForm = $mainElement.find('.modal-new-beneficiary form');
  const $bankAccount = $beneficiaryForm.find('#bank_account_account');

  const accountValidation = function (account) {
    if (account.search(/[\d]+-[\d]{1,1}/) === -1) {
      Swal.fire({
        title: 'Formato inválido!',
        text: 'A conta deve estar no formato: 1234-5',
      });

      return false;
    }
    return true;
  };

  $bankAccount.change(function () { accountValidation($(this).val()); });

  let valid = false;

  $beneficiaryForm.on('submit', function (e) {
    if (!valid) {
      e.preventDefault();

      if (!accountValidation($bankAccount.val())) {
        return false;
      }

      valid = true;
      $(this).submit();
    }

    return true;
  });

  return true;
});
