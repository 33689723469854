class TransferRequestImportsConfirmToSkipTed {
  constructor($element) {
    this.$targetElement = $element.find('input#transfer_request_import_skip_ted_after_window');
    this.message = $element.data('message');

    this.confirmToSkipTedEvent();
  }

  async confirmToSkipTedEvent() {
    const confirmResult = await this.confirmDialog();

    if (confirmResult.isConfirmed) this.$targetElement.val('true');
  }

  confirmDialog() {
    const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">Atenção</div>
        <div class="content">
          ${this.message}<br />Gostaria de prosseguir com a importação
          <strong>apenas de transferências para o mesmo banco (TEF)</strong>?
        </div>
        <div class="callout warning mt-3">
          Lembrando que todas as transferências do tipo TED serão ignoradas e não aparecerão
          na lista de transferências importadas nem de transferências a aprovar.
        </div>
      </div>
    `;

    return Swal.fire({
      html: textHtml,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, ignorar TEDs',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
        content: 'pt-0',
      },
    });
  }
}

onLoad(($) => {
  $('form.new_transfer_request_import[data-confirm-to-skip-ted="true"]').each(
    (_, $element) => new TransferRequestImportsConfirmToSkipTed($($element)),
  );
});
