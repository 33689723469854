import haveDesignSystem from '../helper/have_design_system';

onLoad(($) => {
  const handlePagination = () => {
    const value = {
      initial: '1',
      prev: '<',
      next: '>',
      total: $('.pagination-total').length ? Math.ceil($('.pagination-total').text().trim() / 20) : 'Última',
    };

    const $element = {
      first: $('.pagination-prev .page-link').first(),
      last: $('.pagination-next .page-link').last(),
      prev: $('.pagination-prev .page-link').last(),
      next: $('.pagination-next .page-link').first(),
      firstChild: $('.pagination-list li').first(),
      lastChild: $('.pagination-list li').last(),
      list: $('.pagination-list').children(),
    };

    const setText = (element, val) => element.text(val);
    const getText = element => element.text().trim();
    const hideElement = ($parent, $child) => {
      if (getText($child) === getText($parent)) {
        $parent.addClass('d-none');
      }
    };

    const showElement = (element, val, action) => {
      const template = `<li class="page-item">
          <a class="page-link no-press">${val}</a>
        </li>`;

      if (!element.length && action === 'append') {
        $('.pagination-next').html(template);
      }

      if (!element.length && action === 'prepend') {
        $('.pagination-prev').html(template);
      }
    };

    setText($element.first, value.initial);
    setText($element.prev, value.prev);
    setText($element.next, value.next);

    if ($element.last) {
      setText($element.last, value.total);
    }

    hideElement($element.first, $element.firstChild);
    hideElement($element.last, $element.lastChild);
    showElement($('.page-item [rel="next"]'), value.next, 'append');
    showElement($('.page-item [rel="prev"]'), value.prev, 'prepend');

    const validation = {
      isTwo: $this => getText($this) === '2',
      isntOne: getText($element.list.first()) !== '1',
      isPenultimate: $this => Number(getText($this)) === getText($element.last) - 1,
      isntLast: getText($element.list.last()) !== getText($element.last),
    };

    $element.list.map(function (index) {
      if (index === 1 && validation.isTwo($(this)) && validation.isntOne) {
        $element.list.first().addClass('d-none');
      }
      if (index === $element.list.length - 2 && validation.isPenultimate($(this)) && validation.isntLast) {
        $element.list.last().addClass('d-none');
      }
      return $(this);
    });
  };

  if (haveDesignSystem()) {
    if ($('.pagination').length) {
      $('.card-footer').addClass('pagination-footer');
      handlePagination();
    }
  }
});
