onLoad(($) => {
  $('.JS-conditional-display').on('click', function () {
    const displayElement = $(this).data('el-to-display');
    const display = $(this).data('display');

    if (display) {
      $(displayElement).removeClass('hidden-element');
    } else {
      $(displayElement).addClass('hidden-element');
    }
  });
});
