onLoad(($) => {
  $('.JS-change-role')
    .removeClass('is-valid')
    .on('change', (event) => {
      const $target = $(event.target);
      const userEmail = $target.parents('td').siblings('.user-email').text();
      Swal.fire({
        title: 'Tem certeza?',
        html: `E-mail: <strong>${userEmail}</strong> <br /> Mudar o tipo para
               <strong>${$target.find('option:selected').text()}</strong>?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode mudar',
        cancelButtonText: 'Cancelar',
      }).then((result) => { if (result.value) Rails.fire($target.parents('form').get(0), 'submit'); });
    });
});
