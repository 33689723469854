onLoad(($) => {
  const fineNode = $('.JS-fine-simulation');
  const discountNode = $('.JS-discount-simulation');

  if (!(fineNode.length && discountNode)) return;

  const chargeField = $('#charge_value');
  const monthlyInterestField = $('#charge_monthly_interest');
  const interestTypeField = $('#charge_interest_type_cd');
  const abatementField = $('#charge_formatted_abatement');
  const fineField = $(`#${fineNode[0].id}`);
  const discountField = $(`#${discountNode[0].id}`);

  const calculateDiscount = (discount, value) => (value - ((discount / 100) * value)).toFixed(2);

  const normalizedPercentage = value => parseFloat(value.replace(/[^0-9`,`.]/, '').replace(',', '.'));

  const dailyInterest = (monthlyInterest, type) => {
    let interest = 0.00;
    if (type === 'percentage') {
      interest = normalizedPercentage(monthlyInterest) / 100;
    } else {
      interest = monthlyInterest;
    }
    return interest / 30;
  };

  const absoluteDailyInterest = (monthlyInterest, type, value) => {
    const parsedValue = parseFloat(value);
    if (type === 'percentage') {
      return (parsedValue + (parsedValue * dailyInterest(monthlyInterest, type))).toFixed(2);
    }
    return (parsedValue + dailyInterest(monthlyInterest, type)).toFixed(2);
  };

  const updateFineField = () => {
    if (monthlyInterestField.val() && chargeField.val() && interestTypeField.val()) {
      fineField.val(absoluteDailyInterest(monthlyInterestField.val(), interestTypeField.val(), chargeField.val()));
    }
  };

  const updateDiscountField = () => {
    if (chargeField.val() && abatementField.val()) {
      discountField.val(calculateDiscount(abatementField.val(), chargeField.val()));
    }
  };

  monthlyInterestField
    .add(chargeField)
    .add(interestTypeField)
    .change(updateFineField);

  chargeField
    .add(abatementField)
    .change(updateDiscountField);
});
