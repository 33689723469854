onLoad(($) => {
  if (!$('.ds-charge-imports-new').length) return;

  const $fileFormatInput = $('.JS-file-format-charge-imports');
  const $orientationModalBtn = $('.orientation-modal-btn');
  const downloadButton = $('.charge_import_example_download');

  $fileFormatInput.on('change', () => {
    const format = $fileFormatInput.val();

    if (!format.length) {
      $orientationModalBtn.addClass('d-none');
      return;
    }

    const extension = format === 'CSV' ? 'csv' : 'txt';
    const url = `/download_charges_example?format=${extension}&file_format=${format}`;

    $orientationModalBtn.attr('data-target', `#${extension}OrientationsModal`);
    $orientationModalBtn.removeClass('d-none');
    downloadButton.attr('href', url);
  });
});
