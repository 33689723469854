export default class ProgressBarFileInput {
  constructor($inputFile, beforeSubmitCallback = function submitCallback() { return true; }) {
    this.$inputFile = $inputFile;
    this.$form = $inputFile.closest('form');
    this.$dragArea = $inputFile.closest('.JS-progress-bar-input-file');
    this.$progressBar = this.$form.find('.JS-progress-bar-ongoing');
    this.$fileContent = this.$form.find('.JS-progress-bar-upload');
    this.$uploadContent = this.$fileContent.find('.JS-file-upload-content');
    this.$cancelButton = this.$form.find('.JS-progress-bar-cancel');
    this.$removeButton = this.$form.find('.JS-file-upload-remove');
    this.emptyFileContent = this.$uploadContent.html();
    this.inputHasFiles = false;
    this.submitXHR = {};
    this.beforeSubmitCallback = beforeSubmitCallback;
    this.canContinue = true;

    this.setEvents();
  }

  setEvents() {
    this.$inputFile.on('change', () => {
      this.updateDragArea();
      this.$dragArea.removeClass('dragover-active');
    });

    this.$cancelButton.on('click', () => this.cancelUpload());

    this.$dragArea.on('dragover', () => {
      if (this.$dragArea.hasClass('with-file')) return;

      this.$dragArea.addClass('dragover-active');
      const hoverContent = this.$fileContent.data('hover-content');
      this.$uploadContent.html(ProgressBarFileInput.utils().spanWrap(hoverContent));
    });

    this.$dragArea.on('click', ((e) => {
      if (e.target.matches('.JS-file-upload-remove')) {
        this.$inputFile.val(null).trigger('change');
      }
    }));

    this.$dragArea.on('dragleave', () => {
      this.updateDragArea();
      this.$dragArea.removeClass('dragover-active');
    });

    this.$form.on('submit', async (e) => {
      e.preventDefault();
      await this.beforeSubmitCallback().then((result) => {
        this.canContinue = result;
      });
      if (!this.canContinue) return;
      const formData = new FormData(this.$form[0]);
      this.toggleShowProgressBar();
      this.startAjax(formData)
        .catch(r => this.handleError(r))
        .then(() => {
          this.toggleShowProgressBar();
          this.setProgressBar(0);
        });
    });
  }

  startAjax(data) {
    const xhr = ProgressBarFileInput.computeProgress;
    return new Promise((success, error) => {
      const request = $.ajax({
        url: this.$form.attr('action'),
        method: this.$form.attr('method'),
        contentType: false,
        processData: false,
        data,
        xhr,
        error,
        success,
      });

      this.submitXHR.abort = () => request.abort();
    });
  }

  setUploadContent() {
    const uploadContent = this.inputHasFiles
      ? ProgressBarFileInput.utils().spanWrap(this.$inputFile[0].files[0].name)
      : this.emptyFileContent;
    this.$uploadContent.html(uploadContent);
  }

  updateDragAreaZIndex() {
    const zIndex = this.inputHasFiles ? 0 : 1;
    this.$inputFile.closest('.form-group').css('z-index', zIndex);
    const method = this.inputHasFiles ? 'addClass' : 'removeClass';
    this.$dragArea[method]('with-file');
  }

  toggleRemoveButton() {
    const method = this.inputHasFiles ? 'removeClass' : 'addClass';
    this.$removeButton[method]('d-none');
  }

  updateDragArea() {
    this.inputHasFiles = this.$inputFile[0].files.length;
    this.setUploadContent();
    this.updateDragAreaZIndex();
    this.toggleRemoveButton();
  }

  toggleShowProgressBar() {
    this.$fileContent.add(this.$progressBar).toggleClass('d-none d-flex');
  }

  cancelUpload() {
    this.submitXHR.abort('abort');
  }

  setProgressBar(progress) {
    this.$progressBar
      .find('.JS-progress-bar-current').text(`${progress}%`).end()
      .find('.progress .progress-bar')
      .css('width', `${progress}%`);
  }

  handleError(e) {
    this.$form.find('button[type="submit"]').removeAttr('disabled');
    if (e.statusText === 'abort') return;

    Swal.fire({
      text: 'Não foi possível importar o arquivo de remessa neste momento. Tente novamente mais tarde.',
      icon: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: false,
    });
  }

  static computeProgress() {
    const xhr = new window.XMLHttpRequest();
    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) {
        const progress = parseInt((e.loaded / e.total) * 100, 0);
        $('.JS-progress-bar-current').text(`${progress}%`);
        $('.JS-progress-bar-ongoing .progress .progress-bar').css('width', `${progress}%`);
      }
    });

    return xhr;
  }

  static utils() {
    const spanWrap = text => `<span class='text-primary font-weight-bold'>${text}</span>`;

    return { spanWrap };
  }
}
