import TransferRequestApproveBase from './approve_base';

class TransferRequestApproveAll extends TransferRequestApproveBase {
  constructor($element) {
    super($element);
    this.$element.click(() => this.approveTransferRequestEvent());
  }

  submitApproval(extraParams = {}) {
    this.appendAdditionalParams(extraParams);

    this.toggleSubmitStatus();
    this.$element[0].click();
    this.toggleSubmitStatus();
  }

  appendAdditionalParams(extraParams = {}) {
    const params = new URLSearchParams(this.$element.data('params'));

    Object.entries(extraParams).forEach(([key, value]) => {
      params.append(key, value);
    });

    this.$element.attr('data-params', params.toString());
  }
}

onLoad(($) => {
  $('a.approve-transfer-request[data-remote="true"]').each((_, element) => new TransferRequestApproveAll($(element)));
});
