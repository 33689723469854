import { validateForm } from '../helper/validate_form';

onLoad(($) => {
  const $selectCompanySigner = $('#ccb-select-company-signer');
  if (!$selectCompanySigner.length) return;

  const $formCompanySigner = $('#JS-container-company-signer');
  const $buttonCompanySignerNotRegistered = $('#JS-button-company-signer-not-registered');
  const $buttonSaveCompanySigner = $('.JS-button-save-company-signer');
  const EMPTY_VALUE = '';
  const $inputsCompanySigner = $(`#JS-container-company-signer input.form-control, 
    #JS-container-company-signer input.form-control-file`);

  function showFormCompanySigner() {
    $formCompanySigner.removeClass('d-none');
  }

  function hideFormCompanySigner() {
    $formCompanySigner.addClass('d-none');
  }

  function cleanFields() {
    $inputsCompanySigner.each(function () {
      $(this).val(EMPTY_VALUE).trigger('change');
    });
  }

  function composeValidateForm() {
    return $inputsCompanySigner
      .filter(function () {
        return !!$(this).attr('required');
      })
      .map(function () {
        return $(this).val().length > 0;
      });
  }

  function dispatchValidateForm() {
    validateForm({
      $button: $buttonSaveCompanySigner,
      $form: composeValidateForm(),
      colorButton: 'btn-secondary',
    });
  }

  $selectCompanySigner.on('change', function () {
    const isOptionNewCompanySignerSelected = $(this).children(':selected').val();
    if (isOptionNewCompanySignerSelected) {
      cleanFields();
      showFormCompanySigner();
      return;
    }
    cleanFields();
    hideFormCompanySigner();
  });

  $buttonCompanySignerNotRegistered.on('click', (event) => {
    event.preventDefault();
    $selectCompanySigner.val('new-company-signer');
    $selectCompanySigner.trigger('change');
    cleanFields();
    showFormCompanySigner();
  });

  $inputsCompanySigner.on('change', dispatchValidateForm);
});
