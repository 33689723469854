onLoad(($) => {
  const $checkboxesContainer = $('.checkboxes-container');
  if (!$checkboxesContainer.length) return;

  const $labels = () => $('.checkboxes-container .form-control-label');
  const LABEL_WRAP_HEIGHT = 40;

  function handleLabel() {
    $labels().each(function () {
      $(this)[`${$(this).height() > LABEL_WRAP_HEIGHT ? 'add' : 'remove'}Class`]('label-wrap');
    });
  }

  handleLabel();

  $(window).on('resize', () => {
    handleLabel();
  });
});
