onLoad(($) => {
  const nodes = $('.JS-ask-when-other');

  if (!nodes.length) return;

  $('.JS-ask-when-other-reason-text').hide();

  nodes.on('change', function () {
    const id = this.dataset.textId;
    const field = $(`.JS-ask-when-other-field-${id}`);
    const reasonText = $(`.JS-ask-when-other-reason-text-${id}`);

    if (this.value === 'Outro') {
      field.val('');
      reasonText.show();
    } else {
      reasonText.hide();
      field.val(this.value);
    }
  });
});
