const Pin = () => {
  const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">Atenção</div>
        <div class="content mt-3 mb-1">
          Para confirmar, digite o código PIN.
        </div>
      </div>
    `;
  const showDialog = () => Swal.fire({
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    html: textHtml,
    icon: 'warning',
    input: 'password',
    inputPlaceholder: 'Digite o seu PIN',
    showCancelButton: true,
    customClass: {
      actions: 'flex-row-reverse',
      content: 'pt-0',
    },
    showLoaderOnConfirm: true,
    footer: null,
    allowOutsideClick: () => !Swal.isLoading(),
    inputAttributes: {
      id: 'swal2-pin-input',
      maxlength: 4,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    inputValidator: (value) => {
      if (value.length !== 4) {
        return 'O PIN deve ter 4 dígitos';
      }
      return null;
    },
    onBeforeOpen: () => {
      Swal.getInput().onkeypress = event => Number.isInteger(parseInt(event.key, 10));
    },
    preConfirm: pin => fetch('/users/pin/validate', {
      method: 'PATCH',
      redirect: 'manual',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        pin: `${pin}`,
      }),
    })
      .then((response) => {
        if (response.ok) return pin;

        Swal.showValidationMessage('Erro');

        if (response.status === 401) {
          response.json().then((data) => {
            Swal.showValidationMessage(data.message);
            if (data.present === false) {
              Turbolinks.visit('/');
            } else {
              const message = `${data.remaining_attempts} tentativas restantes antes do seu PIN ser bloqueado`;
              Swal.getFooter().innerHTML = message;
              Swal.getFooter().style.display = 'flex';
            }
          });
        } else if (response.status === 0) {
          Swal.showValidationMessage('Você está deslogado');
          Turbolinks.visit(window.location);
        } else {
          throw new Error(
            `Unexpected PIN response. Status: ${response.status}, message: ${response.statusText}`,
          );
        }

        return pin;
      })
      .catch((error) => {
        Swal.showValidationMessage('Erro inesperado');
        throw error;
      }),
  });

  const shouldForcePinAuth = () => localStorage.getItem('force_pin_authentication') === 'true';

  return { showDialog, shouldForcePinAuth };
};

export default Pin;
