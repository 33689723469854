/* eslint-disable no-restricted-globals */

export default () => {
  function convertMillisecondsToMinutes(_milliseconds) {
    const minutes = Math.floor(_milliseconds / 60000);
    const seconds = ((_milliseconds % 60000) / 1000).toFixed(0);
    return `0${minutes}:${(seconds < 10 ? '0' : '')}${seconds}`;
  }

  self.onmessage = ((event) => {
    let { milliseconds } = event.data;
    const timer = setInterval(() => {
      const minutes = convertMillisecondsToMinutes(milliseconds);
      self.postMessage({ minutes, milliseconds });
      milliseconds -= 1000;
      if (!milliseconds) {
        self.postMessage({ clearTimer: true });
        clearInterval(timer);
      }
    }, 1000);
  });
};
