onLoad(($) => {
  const $modalResendProtest = $('.JS-resend-protest-modal');
  if (!$modalResendProtest.length) return;

  const $checkbox = $('#modal-checkbox');
  const $submitBtn = $('.JS-resend-protest-btn');

  const toggleButtonState = (isChecked) => {
    const [addClass, removeClass] = isChecked ? ['btn-able', 'btn-disabled'] : ['btn-disabled', 'btn-able'];
    $submitBtn.addClass(addClass).removeClass(removeClass);
  };

  const validateCheckbox = () => toggleButtonState($checkbox.is(':checked'));

  const clearCheckbox = () => {
    $checkbox.prop('checked', false);
    toggleButtonState(true);
  };

  $modalResendProtest.on('show.bs.modal', validateCheckbox);
  $modalResendProtest.on('hidden.bs.modal', clearCheckbox);
  $checkbox.on('change', validateCheckbox);
});
