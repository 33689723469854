import ProgressBarFileInput from '../progress_bar_file';

onLoad(($) => {
  if (!$('.ds-cnab-imports-new').length) return;

  const $fileInput = $('#cnab_import_file');
  const $continueBtn = $('.btn-continue');

  $fileInput.on('change', (event) => {
    $continueBtn[`${event.target.value.length ? 'remove' : 'add'}Class`]('btn-disabled');
  });

  $('.JS-progress-bar-input-file input[type=file]')
    .each((_, element) => new ProgressBarFileInput($(element)));
});
