export function enabledButtonForm($button, colorButton = 'btn-primary') {
  $($button)
    .removeAttr('disabled')
    .removeClass('btn-secondary')
    .removeClass('btn-disabled')
    .addClass(colorButton);
}

export function disabledButtonForm($button) {
  if ($button.attr('disabled')) return;
  $($button)
    .attr('disabled', 'disabled')
    .removeClass('btn-primary')
    .addClass('btn-disabled');
}

export function isFormValid($form) {
  return $form.toArray().every(input => !!input);
}

export function validateForm(data) {
  if (isFormValid(data.$form)) return enabledButtonForm(data.$button, data.colorButton);
  return disabledButtonForm(data.$button);
}
