/* eslint-disable func-names */

onLoad(($) => {
  const updateState = function () {
    const $element = $(this);

    const $check = $(this).find('[type=checkbox]');

    if ($check.is(':checked')) {
      $element.addClass('btn-secondary').removeClass('btn-primary');
    } else {
      $element.addClass('btn-primary').removeClass('btn-secondary');
    }

    const checkedText = $check.data('checkedText');
    const uncheckedText = $check.data('uncheckedText');

    $(this).find('.text').text($check.is(':checked') ? checkedText : uncheckedText);
  };

  const toggleCheckbox = function () {
    const $check = $(this).find('[type=checkbox]');

    $check.prop('checked', !$check.is(':checked'));

    $(this).trigger('update-state');
    $check.trigger('change');

    return false;
  };

  $('.JS-toggle-checkbox').on('click', toggleCheckbox).on('update-state', updateState);
});
