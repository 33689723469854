onLoad(($) => {
  const $pixKey = $('#bank_account_pix_key');
  const $pixType = $('#bank_account_pix_type');
  let $previousPixKeyValue;

  if (!$pixKey.length) return;

  const $account = $('#bank_account_account');
  const $accountType = $('#bank_account_kind_cd');
  const $agency = $('#bank_account_agency');
  const $bankIspb = $('#bank_account_ispb');
  const $documentNumber = $('#bank_account_document_number');
  const $form = $('#new_bank_account');
  const $keyType = $('#bank_account_pix_key_type_cd');
  const $name = $('#bank_account_name');
  const $submitButton = $('#submit_button_bank_account');
  const $summary = $('#JS-new_beneficiary_info_summary');

  function isPhoneKey() {
    return $pixType.val() === 'phone';
  }

  function alertError(title, text, icon = 'error') {
    Swal.fire({
      title,
      text,
      icon,
    });
  }

  function unmaskPix() {
    $pixKey.unmask();
  }

  function handlePhoneTypeKey() {
    if (isPhoneKey() && !$pixKey.val().includes('+55')) {
      $pixKey.val(`+55${$pixKey.val()}`);
    }
  }

  function show(element) {
    element.removeClass('d-none');
  }

  function hide(element) {
    element.addClass('d-none');
  }

  function applyKeyMask() {
    const infoKey = $pixType.val();

    switch (infoKey) {
      case 'cpf':
        $pixKey.mask('000.000.000-00');
        break;
      case 'phone':
        $pixKey.mask('+55 (00) 00000-0000');
        break;
      case 'cnpj':
        $pixKey.mask('00.000.000/0000-00');
        break;
      default:
        break;
    }
  }

  function populateForm(data) {
    if (!data) return;

    $documentNumber.val(data.document_number);
    $name.val(data.name);
    $keyType.val(data.pix_key_type_cd).change();
    $bankIspb.val(data.ispb).change();
    $accountType.val(data.kind_cd).change();
    $agency.val(data.agency);
    $account.val(data.account);
  }

  function populateSummary(data) {
    if (!data) return;

    const $summaryDocumentNumber = $('#JS-new_beneficiary_document_number');
    const $summaryName = $('#JS-new_beneficiary_name');
    const $summaryKey = $('#JS-new_beneficiary_pix_key');
    const $summaryBank = $('#JS-new_beneficiary_bank');
    const $summaryAgency = $('#JS-new_beneficiary_agency');
    const $summaryAccount = $('#JS-new_beneficiary_account');

    $summaryDocumentNumber.html(data.document_number);
    $summaryName.html(data.name);
    $summaryKey.html(data.pix_key);
    $summaryBank.html(data.bank_name);
    $summaryAgency.html(data.agency);
    $summaryAccount.html(data.account);
  }

  function populateFields(data) {
    populateSummary(data);
    populateForm(data);
  }

  $pixKey
    .on('blur keypress', function (event) {
      if (!$pixKey.val()) return;

      if (event.type === 'blur' || (event.type === 'keypress' && event.which === 13)) {
        unmaskPix();

        handlePhoneTypeKey();

        const key = $(this).val();

        if (key === $previousPixKeyValue && !$submitButton.hasClass('btn-disabled')) return;

        $previousPixKeyValue = key;

        $.ajax({
          url: $pixKey.data('url-consult'),
          type: 'post',
          dataType: 'json',
          beforeSend: (xhr) => {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          data: {
            key,
          },
          success: (data) => {
            applyKeyMask();
            if (data.success) {
              populateFields(data.data);
              show($summary);
              $submitButton.removeClass('btn-disabled');
            } else {
              const error = data.message ? data.message : data.error;
              alertError('Chave Pix', error);
              hide($summary);
              $submitButton.addClass('btn-disabled');
            }
          },
          error: (error) => {
            console.warn(`${error.status} ${error.statusText}`);
            applyKeyMask();
            const errorMessage = `Algo deu errado com o PIX!
            Estamos trabalhando para resolver isso.Tente novamente mais tarde.`;
            alertError('Atenção', errorMessage, 'warning');
            $submitButton.addClass('btn-disabled');
          },
        });
      }
    });

  $form.one('submit', (e) => {
    e.preventDefault();
    unmaskPix();
    handlePhoneTypeKey();
    $accountType.add($bankIspb).prop('disabled', false);
    $form.submit();
  });

  $pixType.on('change', function () {
    hide($summary);
    $submitButton.addClass('btn-disabled');

    $pixKey
      .unmask()
      .val('')
      .closest('.bank_account_pix_key')
      .toggleClass('d-none', !$(this).val());

    applyKeyMask();
  });
});
