onLoad(($) => {
  if (!$('.JS-multiple-carousel').length) return;

  const $groups = $('.JS-multiple-carousel .carousel-item .item-content');

  if (!$groups.length) return;

  const itemsPerSlide = Number($('.JS-multiple-carousel').data('items-per-slide'));
  const groupItems = {};

  function getGroupByIndex(index) {
    return $(groupItems[index]);
  }

  function getSlideGroups() {
    $groups.each((index, group) => {
      const $items = $(group).children();

      groupItems[index] = $items;
    });
  }

  function fillLastCardGroup(remainingCards) {
    const $penultimateGroup = getGroupByIndex($groups.length - 2);

    if (!$penultimateGroup.length) return;

    for (let i = 1; i <= remainingCards; i += 1) {
      const cardIndex = itemsPerSlide - i;
      const $clone = $($penultimateGroup[cardIndex]).clone();

      if (!$clone) return;

      $($groups[$groups.length - 1]).prepend($clone);
    }
  }

  function checkIfLastCardGroupIsFilled() {
    const lastIndex = $groups.length - 1;
    const $lastGroup = getGroupByIndex(lastIndex);
    const remainingCards = itemsPerSlide - $lastGroup.length;

    if (remainingCards > 0) {
      fillLastCardGroup(remainingCards);
    }
  }

  function hidePreviews() {
    const $carouselItems = $('.carousel-item');
    const $first = $carouselItems.find(':first-child');
    const $last = $carouselItems.find(':last-child');

    if ($first) $first.first().remove();
    if ($last) $last.last().remove();
  }

  function fillPreviousPreviewCard(index) {
    const $group = getGroupByIndex(index);
    const $prevGroup = getGroupByIndex(index - 1);

    if (!$prevGroup.length) return;

    const $prevContainer = $group.parent().siblings().first();
    const $card = $($prevGroup.eq(itemsPerSlide - 1))
      .find('.card')
      .first();

    if (!$card.length) return;

    $prevContainer.find('.prev-item').append($card.clone());
  }

  function fillNextPreviewCard(index) {
    const $group = getGroupByIndex(index);
    const $nextGroup = getGroupByIndex(index + 1);

    if (!$nextGroup.length) return;

    const $nextContainer = $group.parent().siblings().last();
    const $card = $($nextGroup.first()).find('.card').first();

    if (!$card.length) return;

    $nextContainer.find('.next-item').append($card.clone());
  }

  function fillPreviewCards() {
    Object.keys(groupItems).forEach((_, index) => {
      fillPreviousPreviewCard(index);
      fillNextPreviewCard(index);
    });
  }

  getSlideGroups();
  checkIfLastCardGroupIsFilled();
  hidePreviews();
  fillPreviewCards();
});
