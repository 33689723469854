/* eslint-disable class-methods-use-this */

import CurrencyHelper from '../helper/currencyHelper';
import UserAuth from '../user_auth';

export const approveInAppDialogContent = {
  icon: 'warning',
  title: 'Aprovar solicitação',
  confirmButtonText: 'Ok',
  customClass: {
    container: 'modal-approve-in-app',
  },
  showCloseButton: true,
  html: `
    <div>
      <p>Para concluir, verifique a notificação enviada através do aplicativo <strong>Grafeno Autenticador</strong>
       ou acesse a área de notificações no aplicativo e <strong>confirme a aprovação</strong> antes que o prazo expire.
      </p>
      <p class="modal-approve-in-app-content">
        Caso não haja saldo disponível, não será possível processar o pagamento.
      </p>
    </div>
  `,
};

export default class TransferRequestApproveBase {
  constructor($element) {
    this.$element = $element;
    this.options = this.$element.data('approveTransferRequest');
    this.defaultErrorMessage = 'Não foi possível aprovar a(s) transferência(s)';
    this.targetClass = 'approve-transfer-request';
  }

  approveTransferRequestEvent() {
    if (this.isApproveTransferRequest()) {
      this.approveTransferRequest().catch(() => this.errorDialog());
      return false;
    }

    return true;
  }

  async approveTransferRequest() {
    const requiresApp = this.options.approve_in_app;
    const requiresPin = this.options.pin;
    const requires2fa = this.options.two_factor;

    if (requiresApp) {
      const approveInApp = await this.approveInAppDialog();
      if (approveInApp.isDismissed) return;
      this.submitApproval();
      return;
    }

    const quant = this.options.quant || 1;
    const confirmResult = await this.confirmDialog(quant);

    if (confirmResult.isDismissed) return;

    let extraParams = {};

    const userAuth = new UserAuth(requires2fa, requiresPin);
    const validationResult = await userAuth.validate();

    if (!validationResult.success) {
      return;
    }

    extraParams = validationResult.data;

    const validationLimit = await this.verifyAccountLimit();
    if (!validationLimit.success) {
      this.showExceededLimitDialog(verification.data);
      return;
    }

    this.submitApproval(extraParams);
  }

  isApproveTransferRequest() {
    return this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  confirmDialog(quant) {
    const currencyHelper = new CurrencyHelper();
    const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">Atenção</div>
        <div class="content">
          Você está prestes a aprovar
          <strong> ${quant} solicitaç${quant > 1 ? 'ões' : 'ão'} de transferência </strong>
          no valor total de <strong>${currencyHelper.format(this.options.value)}</strong>.
        </div>
        <div class="content">${this.options.prefix || ''}</div>
        <div class="subcontent">
          Caso não haja saldo disponível, não será possível processar o pagamento.</br>
        </div>
        <div class="callout warning mt-3">
          Antes de concluir, certifique-se que os dados estão corretos.
          Redobre a atenção e <strong>cuidado com golpes</strong>.
        </div>
      </div>
    `;

    return Swal.fire({
      html: textHtml,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
        content: 'pt-0',
      },
    });
  }

  verifyAccountLimit() {
    const url = '/transfer_requests/validate_account_limit';
    const method = 'get';
    const payload = { transfer_value: this.options.value };

    return new Promise((success, error) => {
      $.ajax({
        success,
        error,
        url,
        method,
        dataType: 'json',
        data: payload,
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
      });
    });
  }

  showExceededLimitDialog(data) {
    const currencyHelper = new CurrencyHelper();
    const textHtml = `
      <div class="text-left">
        <p>Valor do movimento excede o limite diário para esta conta.</p>
        <span><b>Limite disponível:</b> ${currencyHelper.format(data.available_limit)}</span><br/>
        <span><b>Limite diário:</b> ${currencyHelper.format(data.account_limit)}</span><br/>
        <b>Valor das movimentações:</b> ${currencyHelper.format(this.options.value)}
      </div>
    `;

    const footerHtml = `
      <span>
        <b>Obs.:</b> É possível aprovar as movimentações que não excedem o limite,
        retirando da seleção as que o ultrapassam individualmente.
      </span>
    `;

    return Swal.fire({
      html: textHtml,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      footer: footerHtml,
      customClass: {
        actions: 'flex-row-reverse',
        footer: 'px-1 text-justify',
      },
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text,
      title: this.defaultErrorMessage,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  approveInAppDialog() {
    return Swal.fire(approveInAppDialogContent);
  }
}
