import ProgressBarFileInput from '../progress_bar_file';

const fileExampleByFormat = ($formatSelector) => {
  const format = $formatSelector.val();
  const downloadButton = $('#charge_import_example_download');
  const extension = format === 'CSV' ? 'csv' : 'txt';
  const url = `/download_charges_example?format=${extension}&file_format=${format}`;
  downloadButton.attr('href', url);
};

onLoad(($) => {
  const $fileFormatSelector = $('#charge_import_file_format');
  if (!$fileFormatSelector.length) return;
  fileExampleByFormat($fileFormatSelector);
  $fileFormatSelector.on('change', () => fileExampleByFormat($fileFormatSelector));
  $('.JS-progress-bar-input-file input[type=file]')
    .each((_, element) => new ProgressBarFileInput($(element), () => new Promise(resolve => resolve(true))));
});
