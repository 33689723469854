import CodeConfirmation from '../../CodeConfirmation';
import TimeIntervalHelper from '../../helper/timeIntervalHelper';
import TwoFactor from '../../two_factor';

function parseError(xhr) {
  // TODO: on es2020 there is optional chaining (?.), but eslint is not updated
  const message = (xhr.responseJSON || {}).message || 'Ocorreu um erro';
  try {
    Swal.showValidationMessage(message);
  } catch (err) {
    console.error(err);
  }
}

class BeneficiaryRelease {
  constructor($element) {
    this.$element = $element;
    this.$element.click(event => this.releaseBeneficiariesClick(event));
    this.defaultErrorMessage = 'Não foi possível liberar o beneficiário';
    this.options = this.$element.data('codeConfirm');
    this.confirmationOptions = {
      '2fa': {
        confirmMethod: () => this.confirmUsing2FA(),
        preConfirmMethod: () => {},
      },
      email: {
        confirmMethod: () => this.confirmUsingEmail(),
        preConfirmMethod: () => new CodeConfirmation(this.options).send().catch(parseError),
      },
    };
  }

  releaseBeneficiariesClick() {
    this.releaseBeneficiaries();
    return false;
  }

  async releaseBeneficiaries() {
    if ((await this.confirmDialog()).isDismissed) {
      return;
    }

    const result = await this.confirmCodeAndSubmit();
    if (result.isDismissed) {
      return;
    }

    window.location.reload();
  }

  confirmDialog() {
    const confirmationOptions = this.confirmationOptions[this.options.kind];

    const preConfirm = confirmationOptions.preConfirmMethod;

    return Swal.fire({
      text: this.$element.data('text'),
      title: this.options.confirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm,
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  errorDialog(text) {
    return Swal.fire({
      text,
      title: this.defaultErrorMessage,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  confirmCodeAndSubmit() {
    const confirmationOptions = this.confirmationOptions[this.options.kind];

    return confirmationOptions.confirmMethod(this);
  }

  confirmUsingEmail() {
    let timerInterval;
    const intervalHelper = new TimeIntervalHelper();

    return Swal.fire({
      html: this.options.prompt,
      input: 'text',
      title: 'Confirmação de Código',
      icon: 'warning',
      footer: this.options.footer,
      inputPlaceholder: 'Código de confirmação',
      confirmButtonText: 'Confirmar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      showCloseButton: true,
      timer: this.options.timer,
      preConfirm: code => this.submitRelease({ code_confirmation: code }).catch(parseError),
      allowOutsideClick: () => !Swal.isLoading(),
      onBeforeOpen: () => {
        timerInterval = setInterval(() => {
          Swal.getHtmlContainer().querySelector('#code-expiration-timer')
            .textContent = intervalHelper.humanizeInterval(Swal.getTimerLeft());
        }, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
      customClass: {
        actions: 'flex-row-reverse',
        footer: 'd-block px-4',
      },
    });
  }

  async confirmUsing2FA() {
    const twoFactor = new TwoFactor();
    const result = await twoFactor.showDialog();

    if (result.isDismissed || result.error) {
      return { success: false };
    }

    await this.submitRelease({ otp_attempt: result.value }).catch(parseError);

    return { success: true, data: { otp_attempt: result.value } };
  }

  submitRelease(data) {
    return new Promise((success, error) => {
      $.ajax({
        success,
        error,
        url: this.$element.attr('href'),
        method: this.$element.data('method'),
        dataType: 'json',
        data,
      });
    });
  }
}

onLoad($ => $('.release-beneficiary').each((_, element) => new BeneficiaryRelease($(element))));
