import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const searchField = $('.JS-change-ip');

  if (!searchField.length) return;

  const queryUrl = searchField[0].dataset.queryurl;

  const ipAccountsModal = $('#ipAccountsModal');
  const form = $('.JS-change-ip-form');
  const kind = form.find('.JS-change-ip-kind');
  const positiveVisibleBalance = form.find('.JS-change-ip-positive-visible-balance');
  const orderByBalance = form.find('.JS-change-ip-order-by-balance');
  const table = form.find('.JS-change-ip-table');
  const notFoundMessage = form.find('.JS-change-ip-not-found');
  const spinner = form.find('.JS-change-ip-spinner');
  const id = form.find('.JS-change-ip-id');
  const ability = form.data('ability');

  let lastSearch;

  let searchId = 0;

  table.add(notFoundMessage).add(spinner).hide();

  const switchTableOrMessage = (length) => {
    notFoundMessage.toggle(length === 0);
    table.toggle(length !== 0);
  };

  const selectAccount = function (e) {
    e.preventDefault();

    id.val(this.dataset.id);

    e.currentTarget.form.submit();
  };

  const currencyHelper = new CurrencyHelper();

  const fillTable = (data) => {
    const $tbody = table.find('tbody');

    $tbody.find('tr').remove();

    const $trs = [];

    $(data).each(function () {
      const $tr = $('<tr/>');
      let action;

      $tr.append($('<td/>', { text: this.bank_code, class: 'table-no-wrap' }))
        .append($('<td/>', { text: this.agency, class: 'table-no-wrap' }))
        .append($('<td/>', { text: this.account, class: 'table-no-wrap' }))
        .append($('<td/>', { text: this.name }))
        .append($('<td/>', { text: this.associated_to }))
        .append($('<td/>', { text: this.kind, class: 'table-no-wrap' }));
      if (ability) {
        $tr.append($('<td/>', { text: currencyHelper.format(this.visible_balance), class: 'table-no-wrap' }));
      }

      if (this.is_current) {
        action = '<button class="btn btn-disabled">Conta Atual</button>';
      } else {
        const hasPendingTerm = this.has_pending_term_acceptance;
        const createButton = _hasPendingTerm => $('<button/>', {
          class: `JS-change-ip-select-account btn ${_hasPendingTerm ? 'btn-primary' : 'btn-secondary'} btn-sm`,
          'data-id': this.id,
          align: 'center',
          text: _hasPendingTerm ? 'Termos e condições' : 'Selecionar Conta',
        });

        action = createButton(hasPendingTerm);

        const $icon = $('<i/>', { class: `${hasPendingTerm ? 'far fa-clock' : 'fas fa-arrow-right'} mr-2` });

        $icon.prependTo(action);
      }

      $tr.append($('<td align="center" class="table-no-wrap"/>').append(action));

      $trs.push($tr);
    });

    $tbody.append($trs);

    $('.JS-change-ip-select-account').on('click', selectAccount);
  };

  const searchData = () => (
    {
      search: searchField.val(),
      positive_visible_balance: positiveVisibleBalance.is(':checked'),
      order_by_balance: orderByBalance.is(':checked'),
      kind: kind.val(),
    }
  );

  let lastSearchData;

  const search = () => {
    const querySearchId = searchId + 1;
    searchId = querySearchId;

    if (JSON.stringify(lastSearchData) === JSON.stringify(searchData())) return;

    spinner.show();

    setTimeout(() => {
      if (searchId !== querySearchId) return;

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: queryUrl,
        data: searchData(),
        success(data) {
          spinner.hide();

          if (JSON.stringify(lastSearch) === JSON.stringify(data)) return;

          lastSearchData = searchData();

          fillTable(data);

          switchTableOrMessage(data.length);
        },
        error() {},
      });
    }, 800);
  };

  if (ipAccountsModal.hasClass('show')) {
    window.location.reload();
  }

  ipAccountsModal.on('show.bs.modal', () => {
    search();
  });

  searchField.on('change keyup', () => search());
  kind.add(positiveVisibleBalance).add(orderByBalance).on('change', () => search());
});
