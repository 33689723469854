function toggleDisableElement(elementId, value, $) {
  $(elementId).prop('disabled', value);
}

onLoad(($) => {
  $('.JS-magic-disable').on('change', function () {
    const truthyValues = ['1', 'true', true];

    const elementSelectorToDisable = $(this).data('el-to-disable');
    const disableElement = $(this).is(':checked') && truthyValues.includes($(this).val()) ? '' : 'disabled';

    toggleDisableElement(elementSelectorToDisable, disableElement, $);
  });

  $('.JS-magic-disable-inverse').on('change', function () {
    const falseyValues = ['0', 'false', false];

    const elementSelectorToDisable = $(this).data('el-to-disable-inverse');
    const disableElement = $(this).is(':checked') && falseyValues.includes($(this).val()) ? '' : 'disabled';

    toggleDisableElement(elementSelectorToDisable, disableElement, $);
  });

  conditionalDisable = $('.JS-conditional-disable');

  conditionalDisable.on('change', function () {
    const elementSelectorToSetReadonly = $(this).data('el-to-disable');
    const nodes = $(elementSelectorToSetReadonly);
    const disableElement = !$(this).data('conditions').includes($(this).val());
    const hideElement = $(this).data('el-to-hide');

    if (hideElement && disableElement) {
      $(hideElement).addClass('hidden-element');
    } else {
      $(hideElement).removeClass('hidden-element');
    }

    for (let n = 0; n < nodes.length; n += 1) {
      const node = nodes[n];
      toggleDisableElement(node, disableElement, $);
    }
  });

  if (conditionalDisable.length && conditionalDisable.data('conditions').includes($(conditionalDisable).val())) {
    const hideElement = $(conditionalDisable).data('el-to-hide');
    $(hideElement).removeClass('hidden-element');
  }
});
