const tooltipTemplate = (tooltipText) => {
  const template = `
      <label class="label-tooltip" data-toggle="tooltip" data-placement="top" data-title="${tooltipText}">
        <i class="fas fa-info-circle"></i>
      </label>
    `;

  return template;
};

const applyTooltips = (modelName, tooltipAlerts) => {
  Object.entries(tooltipAlerts).forEach((entry) => {
    const [className, tooltipText] = entry;
    const $element = $(`div.${modelName}_${className} label`);
    if (!$element.length) return;
    const template = tooltipTemplate(tooltipText);
    $element.append(template);
  });
};

export default applyTooltips;
