import haveDesignSystem from '../helper/have_design_system';

onLoad(($) => {
  if (!haveDesignSystem()) {
    return;
  }

  const $transfers = $('[href="#transferencias"]');
  const $pix = $('[href="#pix"]');
  const brandColorPrimaryPure = '#FF7C1E';

  if ($pix.hasClass('active') && !$transfers.hasClass('active')) {
    const $pixIcon = $('[href="#pix"] .img-icon svg');
    $pixIcon.css('fill', brandColorPrimaryPure);
  }

  if ($pix.hasClass('active') && $transfers.hasClass('active')) {
    $pix.removeClass('active');
  }
});
