onLoad(($) => {
  const ipBankAccountChargeConcessionForm = $('.JS-ip-bank-account-charge-concession-form');
  if (!ipBankAccountChargeConcessionForm.length) return;

  const documentNumberField = '#charge_concession_access_request_document_number';
  const accountNumberField = '#charge_concession_access_request_account_number';
  const ipBankAccountDocumentNumber = ipBankAccountChargeConcessionForm.find(documentNumberField);
  const ipBankAccountNumber = ipBankAccountChargeConcessionForm.find(accountNumberField);
  const ipBankAccountTable = ipBankAccountChargeConcessionForm.find('.table.table-show').find('td');
  const ipBankAccountInfoDiv = ipBankAccountChargeConcessionForm.find('.ip-bank-account-info');
  const ipBankAccountErrorDiv = ipBankAccountChargeConcessionForm.find('.ip-bank-account-error');
  const submitButton = ipBankAccountChargeConcessionForm.find('.submit-button');

  const ipBankAccountInfo = (data) => {
    const account = data.data;
    ipBankAccountTable.filter('#ipBankAccountName').text(account.name);
    ipBankAccountTable.filter('#ipBankAccountDocument').text(account.document_number);
    ipBankAccountTable.filter('#ipBankAccountAgency').text(account.agency);
    ipBankAccountTable.filter('#ipBankAccountNumber').text(account.account);

    ipBankAccountInfoDiv.removeClass('d-none');
    ipBankAccountErrorDiv.addClass('d-none');
    submitButton.attr('disabled', false);
  };

  const handleError = () => {
    submitButton.attr('disabled', true);
    ipBankAccountErrorDiv.removeClass('d-none');
    ipBankAccountInfoDiv.addClass('d-none');
  };

  ipBankAccountDocumentNumber.add(ipBankAccountNumber).on('change', () => {
    if (ipBankAccountDocumentNumber.val() !== '' && ipBankAccountNumber.val() !== '') {
      $.ajax({
        url: '/ip_bank_accounts/search',
        type: 'get',
        dataType: 'json',
        data: { document_number: ipBankAccountDocumentNumber.val(), account_number: ipBankAccountNumber.val() },
        success: ipBankAccountInfo,
        error: handleError,
      });
    }
  });
});
