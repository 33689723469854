onLoad(($) => {
  $('#JS-clients-user-role-add-accounts').on('click', (event) => {
    const $target = $(event.target);
    const currentPath = $target.data('current-path');
    const $accountsForm = $('#JS-clients-user-role-add-account-form');
    const $roleForm = $('#JS-clients-user-role-form');
    const accountIds = $accountsForm.find(':checkbox:checked').map(function () { return this.value; }).get();

    // Append accounts to the role's form
    let childIndex = new Date().valueOf();
    accountIds.forEach((accountId) => {
      const $checkboxTag = $(document.createElement('input'));
      $checkboxTag.attr('type', 'hidden');
      $checkboxTag.attr('name', `user[roles_attributes][${childIndex}][ip_bank_account_id]`);
      $checkboxTag.attr('value', accountId);
      $roleForm.append($checkboxTag);
      childIndex += 1;
    });

    // Submit role's form as a patch to the current path
    const $patchTag = $(document.createElement('input'));
    $patchTag.attr('type', 'hidden').attr('name', '_method').attr('value', 'patch');
    $roleForm.find('[name=_method]').remove();
    $roleForm.prepend($patchTag);
    $roleForm.attr('action', currentPath).attr('method', 'post').submit();
  });
});
