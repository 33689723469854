onLoad(($) => {
  $mainElement = $('#accounts_index');
  $key = $mainElement.find('#pix_account_key');
  $key.parent().hide();

  $mainElement.find('#pix_account_key_type_cd').change(function () {
    keyType = $(this).val();

    if (keyType === '' || keyType === 'evp' || keyType === 'cnpj' || keyType === 'cpf') {
      $key.parent().hide();
      $key.removeAttr('required');
    } else {
      $key.parent().show();
      $key.attr('required', true);
    }
  });
});
