import Utils from './utils';
import Partner from './partner';
import Grantor from './grantor';
import Administrator from './administrator';

const Main = {
  init() {
    const $onboarding = $('#onboarding-grantor');
    if (!$onboarding) return false;

    $onboarding.find('input, select, checkbox, radio').removeClass('is-valid');

    Utils.labelActive($onboarding);
    Utils.editConfirmBtn($onboarding);
    Utils.findIdForCheckbox($onboarding);
    Utils.datepicker($onboarding);

    Grantor.firstAccess($onboarding.find('.grantors.first_access'));
    Grantor.bankAccount($onboarding.find('.grantors.bank_account'));
    Grantor.company($onboarding.find('.grantors.company'));
    Grantor.document($onboarding.find('.grantors.document'));
    Grantor.address($onboarding.find('.grantors.address'));
    Grantor.participationSociety($onboarding.find('.grantors.participation_society'));
    Grantor.legalRepresentation($onboarding.find('.grantors.legal_representation'));
    Grantor.administrator($onboarding.find('.grantors.administrators'));

    Partner.company($onboarding.find('.partners.company'));
    Partner.companyDocument($onboarding.find('.partners.company_document'));
    Partner.companyAddress($onboarding.find('.partners.company_address'));
    Partner.companyAdministrator($onboarding.find('.partners.company_administrator'));

    Partner.individual($onboarding.find('.partners.individual'));
    Partner.individualDocument($onboarding.find('.partners.individual_document'));
    Partner.individualAddress($onboarding.find('.partners.individual_address'));

    Administrator.attorney($onboarding.find('.administrators.attorney'));

    return true;
  },
};

onLoad(Main.init);
