onLoad(($) => {
  const nodes = $('.JS-multiple-select');

  if (!nodes.length) return;

  const queryUrl = nodes[0].dataset.queryurl;
  const autocompleteFields = JSON.parse(nodes[0].dataset.autocompletefields);
  const autocompleteFieldsKeys = Object.keys(autocompleteFields);
  const focusField = nodes[0].dataset.focusfield;
  const model = nodes[0].dataset.model;

  nodes.autocomplete({
    source: queryUrl,
    dataType: 'json',
    select: (event, ui) => {
      event.preventDefault();

      for (let i = 0; i < autocompleteFieldsKeys.length; i += 1) {
        const key = autocompleteFieldsKeys[i];
        const id = `${model}_${autocompleteFieldsKeys[i]}`;

        $(`#${id}`).addClass('auto-filled').val(ui.item.data[autocompleteFields[key]]).trigger('change');
      }

      $('.phone').trigger('input');

      if (focusField) {
        $(`#${focusField}`).focus();
      }
    },
  });
});
