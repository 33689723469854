onLoad(($) => {
  const $submitRedirectBtn = $('.JS-submit-redirect-btn');

  if (!$submitRedirectBtn.length) return;

  $submitRedirectBtn.on('click', () => {
    const input = $('<input/>').attr('type', 'hidden').attr('name', 'redirect').val('create_and_new');
    $('form').append(input);
  });
});
