const ToggleIcon = () => {
  const icon = {
    filter: '.fa-filter',
    times: '#ds-btn-filter .fa-times',
  };

  const showFilter = () => {
    $(icon.filter).addClass('d-none');
    $(icon.times).removeClass('d-none');
  };

  const showTimes = () => {
    $(icon.filter).removeClass('d-none');
    $(icon.times).addClass('d-none');
  };

  return {
    showFilter,
    showTimes,
  };
};

export default ToggleIcon;
