/* global MutationObserver */

onLoad(($) => {
  const targetNode = document.getElementById('role_automatic_rules');
  if (!targetNode) return;

  const addAllAccountsOption = ($select) => {
    const allAccountsOption = new window.Option('Todas as contas', '', false, false);
    $select.prepend(allAccountsOption);
    $select.select2();
  };

  const setInitialValue = ($select) => {
    if ($select.val().length === 0) {
      $select.val(['']).trigger('change');
    }
  };

  const updateSelectValues = ($select, selectedValues) => {
    if (selectedValues.includes('')) {
      $select.val(['']).trigger('change');
    } else {
      const index = selectedValues.indexOf('');
      if (index !== -1) {
        selectedValues.splice(index, 1);
        $select.val(selectedValues).trigger('change');
      }
    }
  };

  const handleSelectChange = ($select) => {
    let isUpdating = false;
    $select.on('change', function () {
      if (isUpdating) return;
      isUpdating = true;
      updateSelectValues($(this), $(this).val());
      isUpdating = false;
    });
  };

  const initializeSelect = ($select) => {
    if ($select.length > 0) {
      addAllAccountsOption($select);
      setInitialValue($select);
      handleSelectChange($select);
    }
  };

  const initializeAllSelects = () => {
    $('.JS-occupations-for-select').each(function () {
      initializeSelect($(this).find('.JS-occupations-select'));
    });
  };

  const handleMutation = (mutationsList) => {
    mutationsList.forEach((mutation) => {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeType === 1) {
            initializeSelect($(node).find('.JS-occupations-select'));
          }
        });
      }
    });
  };

  const setupObserver = () => {
    const observer = new MutationObserver(handleMutation);
    observer.observe(targetNode, { childList: true, subtree: true });
  };

  initializeAllSelects();
  setupObserver();
});
