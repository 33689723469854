import CurrencyHelper from '../helper/currencyHelper';

onLoad(($) => {
  if (!$('.ds-qr-code-payment').length) return;

  const currencyHelper = new CurrencyHelper();

  const $balanceAvailable = $('#balance-available-value').text();
  const $continueBtn = $('.JS-continue-btn');
  const $inputMoneyAlt = $('.input-money-alt');
  const $input = $inputMoneyAlt.find('input');
  const $inputMoneyAltContinueBtn = $('.JS-input-money-alt-continue-btn');
  const $pasteQrCodeBtn = $('.JS-paste-qr-code-btn');
  const $qrCodeInput = $('.JS-pix-qr-code-input');

  const balanceValue = currencyHelper.unformat($balanceAvailable.replace('R$', ''));
  const value = $inputMoneyAlt.length ? currencyHelper.unformat($input.val()) : 0;

  $('.swal2-modal .swal2-title').css('color', '#0C111D').css('font-size', '24px');
  $('.swal2-html-container').css('font-size', '16px');

  $qrCodeInput.focus();

  $pasteQrCodeBtn.on('click', () => {
    navigator.clipboard.readText().then((clipText) => {
      $qrCodeInput.val(clipText).trigger('input');
    });
  });

  $qrCodeInput.on('input', () => {
    $continueBtn[`${$qrCodeInput.val().length ? 'remove' : 'add'}Class`]('btn-disabled');
  });

  if ($inputMoneyAlt.hasClass('dynamic') || value > 0) {
    $input.attr('readOnly', true);
  }

  if (value > balanceValue) {
    $inputMoneyAlt.find('.input-group').append('<div class="invalid-feedback d-block">Saldo insuficiente</div>');
    return;
  }

  if (value > 0) {
    $inputMoneyAltContinueBtn.removeClass('btn-disabled');
  }
});
