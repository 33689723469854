const TimeIntervalHelper = () => {
  const millisecondsPerSecond = 1000;
  const secondsPerMinute = 60;

  const formatZeros = (value) => {
    const additionalZero = value < 10 ? '0' : '';

    return `${additionalZero}${value}`;
  };

  const humanizeInterval = (interval) => {
    const seconds = Math.floor((interval / millisecondsPerSecond) % secondsPerMinute);
    const minutes = Math.floor((interval / millisecondsPerSecond / secondsPerMinute));

    return `${formatZeros(minutes)}:${formatZeros(seconds)}`;
  };

  return { humanizeInterval };
};

export default TimeIntervalHelper;
