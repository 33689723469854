onLoad(($) => {
  $('#filters').on('click', () => $('.filters').toggleClass('is-hidden'));

  $('#due-date-filter').change(function toggleDueDateFilter() {
    if ($(this).val() === 'range') {
      $('.filter-interval').removeClass('is-hidden');
      $('.filter-date').addClass('is-hidden');
    } else {
      $('.filter-date').removeClass('is-hidden');
      $('.filter-interval').addClass('is-hidden');
    }
  });

  if ($('.action_available_charges_to_catch').length) {
    const $chargeSearch = $('#charge_search');
    const $searchs = $('#searchs');
    const $searchsVal = $searchs.val();
    const $searchInputs = $chargeSearch.find('input:not([type=hidden])');

    $('#clear_current_filter').on('click', (e) => {
      e.preventDefault();
      $chargeSearch
        .find('#destroy_current').val(true)
        .end().submit();
    });

    $searchInputs.prev().on('dblclick', function clearInput() {
      $(this).next().val('').removeClass('is-valid');
    });

    $('#q_ip_bank_account_id_eq').on('change', function changeIpFilter() {
      const $this = $(this);

      if ($searchsVal.length) {
        $($searchsVal.split('|')).each(function readSavedSearch() {
          const obj = JSON.parse(this.replace(/=>/g, ':'));
          let stopReadSavedSearchs = false;

          if ((Number(obj.ip_bank_account_id_eq) || 0) === (Number($this.val()) || 0)) {
            $this.toggleClass('is-valid', $this.val() !== '');

            $searchInputs.each(function fixIsValidField() {
              const $input = $(this);
              const savedVal = obj[$input.attr('id').replace(/^q_/, '')];
              $input.toggleClass('is-valid', !!(savedVal && savedVal.length)).val(savedVal);
            });

            stopReadSavedSearchs = true;
          } else {
            $this.removeClass('is-valid');
            $chargeSearch.find('input:not([type=hidden])').removeClass('is-valid').val(null);
          }
          return !stopReadSavedSearchs;
        });
      }
    });
  }
});
