const TransferRequestHelper = () => {
  const toggleFinalizeContainer = (selected, $finalizeContainer) => {
    $finalizeContainer[`${selected ? 'remove' : 'add'}Class`]('d-none');
  };

  const verifyInputs = (inputs, confirmButton) => {
    if (inputs.includes('')) {
      confirmButton.addClass('btn-disabled');
      return;
    }
    confirmButton.removeClass('btn-disabled');
  };

  return { toggleFinalizeContainer, verifyInputs };
};

export default TransferRequestHelper;
