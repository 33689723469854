/* eslint-disable no-unused-vars, no-undef, no-tabs */
onLoad(($) => {
  const $swalDetails = $('.JS-swal-details');

  $swalDetails.on('click', function detailsClick(e) {
    e.preventDefault();
    const url = $(this).attr('href');

    const title = $(this).attr('data-title') || 'Detalhes';

    Swal.fire({
      html: `<div class="mb-3">
               <div class="row no-print">
	                <div class="col-lg-7 text-left pt-1">
	                    <strong>${title}</strong>
	                </div>
	             </div>
	           </div>
	           <iframe id="JS-iframe-details" src="${url}" width="379px" height="690px"
	                onload="this.style.height=this.contentDocument.body.scrollHeight+4 +'px';">
	           </iframe>`,
      showCloseButton: false,
      focusConfirm: true,
      width: '480px',
      customClass: {
        confirmButton: 'no-print',
        closeButton: 'no-print',
      },
      onOpen: () => {
        const $iFrame = $('#JS-iframe-details');
        $iFrame.on('load', () => {
          $iFrame.css({ 'border-width': '0px' });
        });
      },
    });
  });
});
/* eslint-enable no-unused-vars, no-undef, no-tabs */
