/* eslint-disable no-unused-vars, no-undef */
import DateHelper from './helper/dateHelper';

const dateHelper = new DateHelper();

const validateInputDate = ($element, minDate, maxDate) => {
  const [day, month, year] = dateHelper.toArray($element.val());
  const inputDate = new Date(year, month, day);
  const inputIsValid = dateHelper.validate($element.val());

  const inputInvalid = () => {
    $element.addClass('is-invalid');
    $element.val('');
  };

  const inputValid = () => {
    if ($element.hasClass('is-invalid')) $element.removeClass('is-invalid');
  };

  const compareDates = (classElement, reference, current) => {
    const dates = {
      '.JS-dateGreaterThanInitial': () => (current > reference) && inputInvalid(),
      '.JS-dateLessThanFinal': () => (current < reference) && inputInvalid(),
    };
    return dates[classElement]();
  };

  const validateRange = (classElement) => {
    const $findElementValue = $element.closest('.row').find(classElement).val();
    if ($findElementValue) {
      const referenceDate = new Date($findElementValue.split('/').reverse());
      const currentDate = new Date($element.val().split('/').reverse());
      compareDates(classElement, referenceDate, currentDate);
    }
  };

  const findRangeElements = () => {
    if ($element.hasClass('JS-dateGreaterThanInitial')) {
      const initial = new Date($element.closest('.row').find('.JS-dateLessThanFinal').val().split('/')
        .reverse());
      const final = new Date($element.val().split('/').reverse());
      return [initial, final];
    } if ($element.hasClass('JS-dateLessThanFinal')) {
      const initial = new Date($element.val().split('/').reverse());
      const final = new Date($element.closest('.row').find('.JS-dateGreaterThanInitial').val().split('/')
        .reverse());
      return [initial, final];
    }
    return null;
  };

  if (inputDate > maxDate || inputDate < minDate || !inputIsValid) {
    inputInvalid();
  } else {
    inputValid();
  }

  if ($element.hasClass('JS-dateGreaterThanInitial')) {
    validateRange('.JS-dateLessThanFinal');
  } else if ($element.hasClass('JS-dateLessThanFinal')) {
    validateRange('.JS-dateGreaterThanInitial');
  }

  if ($element.hasClass('JS-dateThreeMonthsRange')) {
    const [initial, final] = findRangeElements();
    let maxFinal = new Date(initial.valueOf());
    maxFinal = new Date(maxFinal.setMonth(maxFinal.getMonth() + 3));
    if (final > maxFinal) {
      inputInvalid();
    } else if (initial <= final) {
      inputValid();
      $element.addClass('is-valid');
    }
  }
};

export default validateInputDate;

/* eslint-enable no-unused-vars, no-undef */
