export default class GeneralShimmer {
  constructor($elementToShimmer, shimmerIconParentClass, callback = () => {}) {
    this.shimmerStyleClass = 'balance-info-shimmer';
    this.$elementToShimmer = $elementToShimmer;
    this.shimmerIconParentClass = shimmerIconParentClass;
    this.callback = callback;

    $(shimmerIconParentClass).on('click', () => this.apply());
  }

  apply() {
    this.toggleIcon();
    this.toggleShimmer();
    if (this.isApplied()) return;

    this.runCallback();
  }

  runCallback() {
    this.callback(this);
  }

  toggleIcon() {
    $(`${this.shimmerIconParentClass} .fa-eye-slash`).toggleClass('d-none');
    $(`${this.shimmerIconParentClass} .fa-eye`).toggleClass('d-none');
  }

  toggleShimmer() {
    this.$elementToShimmer.children().toggleClass('d-none');
    this.$elementToShimmer.toggleClass(this.shimmerStyleClass);
  }

  isApplied() {
    return this.$elementToShimmer.hasClass(this.shimmerStyleClass);
  }
}
