onLoad(($) => {
  $('.JS-numeric-text-field').keypress((ev) => {
    const charCode = (ev.which) ? ev.which : ev.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  });

  $('.JS-numeric-text-field').on('paste', (ev) => {
    if (ev.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
      ev.preventDefault();
    }
  });
});
