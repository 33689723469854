import haveDesignSystem from './helper/have_design_system';

onLoad(($) => {
  if (!haveDesignSystem()) {
    return;
  }

  if ($('.JS-dinamic-bg').length) {
    $('.application-content').addClass('bg-white');
  }
});
