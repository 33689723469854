import haveDesignSystem from '../helper/have_design_system';

onLoad(($) => {
  if (!haveDesignSystem()) {
    return;
  }

  const adjusteFooterInMainContainer = () => {
    const messageRuleNew = $('.ds-messaging-rules-new');

    if (messageRuleNew.length) {
      const footer = $('.footer');
      const mainContent = $('#page-content-wrapper');
      mainContent.append(footer);
    }
  };
  adjusteFooterInMainContainer();
});
