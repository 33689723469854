onLoad(($) => {
  if (!$('.JS-entry-description').length) return;

  const $entryDetail = $('.JS-entry-description');

  const setUpSwal = (responseData) => {
    Swal.fire({
      html: responseData.statement_entry,
      showCloseButton: false,
      focusConfirm: true,
      width: '800px',
      heigth: '860px',
      customClass: {
        container: 'main-content statement-entries-modal',
        confirmButton: 'btn btn-secondary',
      },
    });
  };

  $entryDetail.on('click', function detailsClick(e) {
    e.preventDefault();
    e.stopPropagation();

    $entryDetail.css('pointer-events', 'none');

    const url = $(this).attr('href');
    $.ajax({
      type: 'GET',
      url,
      success(responseData) {
        $entryDetail.css('pointer-events', 'auto');

        setUpSwal(responseData);
      },
      error() {
        Swal.fire({
          text: 'Não foi possível carregar detalhes da movimentação',
          showCloseButton: false,
          customClass: {
            container: 'main-content statement-entries-modal',
            confirmButton: 'btn btn-secondary',
          },
        });
      },
    });
  });
});
