onLoad(($) => {
  if ($('.JS-charge-form').length && !$('.JS-form-with-tooltips').length) {
    const $chargeForm = $('.JS-charge-form');
    const $fieldByTypeInput = $chargeForm.find('.JS-field-by-type');
    const $fieldByTypeLabel = $fieldByTypeInput.closest('.form-group').find('.form-control-label');
    const $iconTemplate = `<label class="label-tooltip" data-toggle= "tooltip"
    data-placement="top" data-title='Caso não queira cobrar, selecione a opção "Nenhum"'>
    <i class="fas fa-question-circle"></i>
    </label>`;
    $fieldByTypeLabel.append($iconTemplate);
  }

  const $registrationMethod = $('.JS-registration-method-alert');
  if (!$registrationMethod.length) return;

  const defineAlertText = () => {
    const selectedValue = $('.JS-registration-method-selector').val();
    const alertText = {
      strong: '',
      span: '',
    };

    if (selectedValue === 'online') {
      alertText.strong = 'Método de Registro Imediato:';
      alertText.span = 'Registra imediatamente o boleto após a criação da cobrança';
    } else if (selectedValue === 'offline') {
      alertText.strong = 'Método de Registro Em lotes:';
      alertText.span = 'Registra o boleto em no máximo duas horas após a criação da cobrança';
    }

    const animationSpeedInMilliseconds = 75;
    $registrationMethod.hide();
    $registrationMethod.find('strong').text(alertText.strong);
    $registrationMethod.find('span').text(alertText.span);
    $registrationMethod.fadeIn(animationSpeedInMilliseconds);
  };

  defineAlertText();

  $('.JS-registration-method-selector').on('change', () => {
    defineAlertText();
  });
});
