onLoad(($) => {
  function swalAlert() {
    const $this = $(this);
    const alertTitle = $this.data('alert-title') ? $this.data('alert-title') : 'Informativo';
    const alertMessage = $this.data('alert-message');
    const alertIcon = $this.data('alert-type') ? $this.data('alert-type') : 'info';

    if (alertMessage) {
      Swal.fire({
        title: alertTitle,
        text: alertMessage,
        icon: alertIcon,
      });
    }
  }

  $('.swal_alert').on('click', swalAlert);
});
