import MultiselectCheckbox from '../multiselect_checkbox';
import CurrencyHelper from '../helper/currencyHelper';

function updateState(multiselect) {
  let params = '';
  let totalTransferValue = 0;

  if (multiselect.hasCheckedItems()) {
    multiselect.getCheckedItems().each((_, checkedItem) => {
      const $checkedItem = $(checkedItem);
      params += `ids[]=${$checkedItem.val()}&`;
      totalTransferValue += parseFloat($checkedItem.data('value'));
    });
  }

  multiselect.$actionButtons.each((_, button) => {
    const $btn = $(button);
    const url = $btn.attr('href').split('?')[0];
    const quantSelected = multiselect.checkedItemsQuantity();

    $btn.attr('href', `${url}?${params}`);

    if ($btn.hasClass('approve-transfer-request')) {
      const dataset = $btn.data('approveTransferRequest');

      dataset.value = totalTransferValue;
      dataset.quant = quantSelected;
      $btn.data('approveTransferRequest', dataset);
    } else {
      const currencyHelper = new CurrencyHelper();
      const confirmText = `
      <div class="swal-confirm-modal">
        <div class="content">
          Você está prestes a rejeitar
          <strong> ${quantSelected} solicitaç${quantSelected > 1 ? 'ões' : 'ão'} de transferência </strong>
          no valor total de <strong>${currencyHelper.format(totalTransferValue)}</strong>.
        </div>
        <div class="content mt-3">Tem certeza que deseja continuar?</div>
      </div>
    `;

      $btn.data('html', confirmText);
      $btn.data('confirmbutton', 'Sim, rejeitar');
    }
  });
}

onLoad(($) => {
  $('#select_all_transfer_request').each((_, element) => new MultiselectCheckbox($(element), { updateState }));
});
