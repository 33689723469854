import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  if (!$('.card-expense-management').length) {
    return;
  }

  const currencyHelper = new CurrencyHelper();

  const $table = $('#table-scroll');
  const header = $('.card-header')[0];
  const $fixedColumnTable = $('.fixed-column-table');
  const $mainTable = $('.main-table');
  const spinner = $('.JS-expense-management-spinner');

  const $dateMonth = $('#date_month');
  const $dateYear = $('#date_year');
  const $searchFormEvents = $('#search_form_events');
  const $searchFormInitialDay = $('#search_form_initial_day');
  const $searchFormFinalDay = $('#search_form_final_day');
  const $searchFormTotalPositive = $('#search_form_total_positive');


  function updateThead(expensesDate) {
    const $thead = $mainTable.find('thead').find('tr');
    const datesKeys = Object.keys(expensesDate);

    datesKeys.forEach((date) => {
      $thead.append($('<th/>', { text: 'Qtd.', class: 'text-center' }))
        .append($('<th/>', { text: date, class: 'text-center' }));
    });
  }

  function updateTbody(expenses, daily) {
    const $tbody = $mainTable.find('tbody');
    $tbody.empty();

    $.each(expenses, (label, value) => {
      const totalRow = label === 'Total' ? 'font-bold' : '';
      const datesValue = Object.values(value.dates);
      const $tr = $('<tr/>', { class: totalRow });

      $tr.append(`
        <th class="label-min-width fixed-side ${totalRow}" title="${label}">
          ${label}
        </th>
      `);

      if (daily) {
        $tr.append($('<th/>', { text: value.count_row, class: 'font-bold count-col fixed-side' }))
          .append(`
            <th class="font-bold total-col fixed-side">
              ${currencyHelper.format(value.total_row)}
            </th>
          `);
      }

      datesValue.forEach((date) => {
        $tr.append($('<td/>', { text: date.count, class: 'text-center' }))
          .append($('<td/>', { text: currencyHelper.format(date.total), class: 'text-center' }));
      });

      $tbody.append($tr);
    });
  }

  function fillTable(data) {
    const dates = Object.values(data.expenses)[0].dates;
    updateThead(dates);
    updateTbody(data.expenses, data.daily);
  }

  function setMaxWidth(newWidth) {
    $table.css('width', `${newWidth}px`);
  }

  const searchData = () => (
    {
      search_form: {
        total_positive: $searchFormTotalPositive.is(':checked'),
        year: $dateYear.val(),
        month: $dateMonth.val(),
        initial_day: $searchFormInitialDay.val(),
        final_day: $searchFormFinalDay.val(),
        events: $searchFormEvents.val(),
      },
    }
  );

  function setTable() {
    $table.hide();
    spinner.show();

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/billing/expense-management/calculate_expenses',
      data: searchData(),
      success(data) {
        fillTable(data);

        if ($fixedColumnTable.length && header.offsetWidth > 576) {
          $mainTable.clone(true).appendTo($table).addClass('clone');
        }

        setMaxWidth(header.offsetWidth);
        spinner.hide();
        $table.show();
      },
      error() {},
    });
  }

  $(window).on('toggle-sidebar', () => {
    $table.hide();

    setTimeout(() => {
      setMaxWidth(header.offsetWidth);
      $table.show();
    }, 50);
  });

  setTable();
});
