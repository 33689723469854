class IpBankAccountBeneficiariesNewForm {
  constructor($element) {
    this.$form = $element;

    this.validationFlags = {
      name: true, document: true, agency: true, account: true,
    };

    this.documentLengths = {
      unmaskedCpf: 11, maskedCpf: 14, unmaskedCnpj: 14, maskedCnpj: 18,
    };

    this.addAllEventListeners();
  }

  addAllEventListeners() {
    const $form = this.$form;
    const $nameInput = $form.find('input#bank_account_name');
    const $documentInput = $form.find('input#bank_account_document_number');
    const $documentSelect = $form.find('.select2#bank_account_document_number');
    const document = $documentSelect.length ? $documentSelect[0].selectedOptions[0].value : null;
    const $agencyInput = $form.find('input#bank_account_agency');
    const $accountInput = $form.find('input#bank_account_account');

    $documentSelect.select2();

    [
      [$nameInput, this.validateNameInput, { target: $nameInput[0] }],
      [$documentInput, this.validateDocumentInputOrSelect, { target: $documentInput[0] }],
      [$documentSelect, this.validateDocumentInputOrSelect, { params: { data: { id: document } } }, 'select2:select'],
      [$agencyInput, this.validateAgencyInput, { target: $agencyInput[0] }],
      [$accountInput, this.validateAccountInput, { target: $accountInput[0] }],
    ].forEach(params => this.addEventListener(...params));
  }

  addEventListener($element, callback, dummyEvent, eventType = 'keyup') {
    if ($element.length) {
      const boundCallback = callback.bind(this);

      boundCallback(dummyEvent);
      $element.on(eventType, boundCallback);
    }
  }

  validateNameInput(e) {
    this.validateMinLength('name', e.target.value.length, 1);
  }

  validateDocumentInputOrSelect(e) {
    const valueLength = (e.params ? e.params.data.id : e.target.value).length;
    const lengths = this.documentLengths;
    let cpfLength = lengths.unmaskedCpf;
    let cnpjLength = lengths.unmaskedCnpj;

    if (e.type === 'keyup') {
      cpfLength = lengths.maskedCpf;
      cnpjLength = lengths.maskedCnpj;

      $(e.target).removeClass('is-invalid');
    }

    this.validateDocumentLength(valueLength, cpfLength, cnpjLength);
  }

  validateAgencyInput(e) {
    this.validateMinLength('agency', e.target.value.length, 1);
  }

  validateAccountInput(e) {
    const valueLength = e.target.value.length;

    this.validateMinLength('account', valueLength, 3);
    this.toggleAccountLimitAlert(valueLength, 13);
  }

  toggleAccountLimitAlert(valueLength, minLength) {
    const accountMaskLength = 1;
    const $accountLimitAlert = this.$form.find('.account-limit-container');
    const conditionResult = valueLength <= minLength + accountMaskLength;

    $accountLimitAlert[`${conditionResult ? 'add' : 'remove'}Class`]('d-none');
  }

  validateMinLength(attribute, valueLength, minLength) {
    const conditionResult = valueLength >= minLength;

    this.updateFlag(attribute, conditionResult);
  }

  validateDocumentLength(valueLength, cpfLength, cnpjLength) {
    const conditionResult = valueLength === cpfLength || valueLength === cnpjLength;

    this.updateFlag('document', conditionResult);
  }

  updateFlag(flag, conditionResult) {
    this.validationFlags[flag] = conditionResult;
    this.refreshSubmitButtonState();
  }

  refreshSubmitButtonState() {
    const flags = Object.values(this.validationFlags);
    const $submitButton = this.$form.find('input[type=submit]');

    if (flags.every(v => v)) {
      $submitButton.removeClass('btn-disabled');
    } else {
      $submitButton.addClass('btn-disabled');
    }
  }
}

onLoad($ => new IpBankAccountBeneficiariesNewForm($('form#new_beneficiary_by_bank_account')));
