import MultiselectCheckbox from '../multiselect_checkbox';

class BarcodePaymentIndexMultiselect extends MultiselectCheckbox {
  constructor($element, options = {}) {
    super($element, options);
    this.localStorageKeyName = 'barcode_payment_receipts';
    this.storedValues = this.getStoredValues();
    this.downloadFileLimit = this.$parentCheckbox.data('web-file-limit');
    this.zipFileLimit = this.$parentCheckbox.data('zip-file-limit');
  }

  getStoredValues() {
    return JSON.parse(localStorage.getItem(this.localStorageKeyName)) || {};
  }

  resetLocalStorage() {
    this.$itemCheckboxes.each((_, e) => {
      const element = $(e);
      this.storedValues[element.attr('id')] = {
        checked: element.is(':checked'),
        receiptId: element.data('receiptId'),
      };
    });

    localStorage.setItem(this.localStorageKeyName, JSON.stringify(this.storedValues));
  }

  getStoredSelectedValues() {
    return Object.values(this.getStoredValues()).filter(v => v.checked);
  }

  storedSelectedValuesCount() {
    return this.getStoredSelectedValues().length;
  }

  hasCheckedItems() {
    /* Assim o usuário não precisa checar algo
    caso tenha mudado a paginação para aparecer o botão de download */
    return this.storedSelectedValuesCount();
  }

  downloadFilesText() {
    if (!this.downloadFileLimit) return '';

    return `${this.getSelectedItemsText(this.storedSelectedValuesCount())}
            <br/>
            Arquivos: ${Math.ceil(this.checkedItemsQuantity() / this.zipFileLimit)}
            <br/>
            (máximo de ${this.zipFileLimit} comprovantes por arquivo)
            <br/>
            Limite para download: ${this.downloadFileLimit} comprovantes`;
  }
}

function updateState(multiselect) {
  multiselect.resetLocalStorage();
  let params = '';
  if (multiselect.hasCheckedItems()) {
    multiselect.getStoredSelectedValues().forEach((checkedItem) => {
      params += `ids[]=${checkedItem.receiptId}&`;
    });
  }

  multiselect.$actionButtons.each((_, button) => {
    const $btn = $(button);
    const url = $btn.attr('href').split('?')[0];
    const dataset = $btn.data('batchDownloadIpReceipt');

    dataset.downloadLimit = multiselect.downloadFileLimit;
    dataset.text = multiselect.downloadFilesText();
    dataset.selectedCount = multiselect.storedSelectedValuesCount();
    $btn.data('batchDownloadIpReceipt', dataset);

    $btn.attr('href', `${url}?${params}`);
  });
}

const actionButtonsClass = 'btn-barcode-payment-history-action';

onLoad(($) => {
  localStorage.removeItem('barcode_payment_receipts');

  $('#select_all_barcode_payment_history')
    .each((_, element) => new BarcodePaymentIndexMultiselect($(element), { actionButtonsClass, updateState }));

  $('body').on('change', () => {
    $('#select_all_barcode_payment_history')
      .each((_, element) => new BarcodePaymentIndexMultiselect($(element), { actionButtonsClass, updateState }));
  });
});
