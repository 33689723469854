/*
 Caso exista a necessidade de utilizar um <form> ao invés de um <a>,
 por exemplo para usar algo como 2fa ou alguma outra customização de
 fluxo, considerar separar as particularidades.

 Por enquanto, ambos os botões de aprovação (em lote e individual)
 são do tipo <a>.
*/

export default class ChargeProtestApprove {
  constructor($element) {
    this.$element = $element;
    this.options = this.$element.data('approveChargeProtest');
    this.defaultErrorMessage = this.options.errorText;
    this.targetClass = 'approve-charge-protest';

    this.$element.off('click');
    const callback = () => this.approveChargeProtestEvent();
    this.$element.on('click', callback);
  }

  approveChargeProtestEvent() {
    if (!this.isSubmitting()) {
      this.approveChargeProtest().catch(() => this.errorDialog());
      return false;
    }

    return true;
  }

  async approveChargeProtest() {
    const confirmResult = await this.confirmDialog();
    if (confirmResult.isDismissed) return;

    this.submitApproval();
  }

  isSubmitting() {
    return !this.$element.hasClass(this.targetClass);
  }

  toggleSubmitStatus() {
    this.$element.toggleClass(this.targetClass);
  }

  confirmDialog() {
    return Swal.fire({
      title: this.options.title || 'Deseja aprovar a instrução de protesto?',
      html: this.options.text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }

  submitApproval() {
    this.toggleSubmitStatus();
    this.$element[0].click();
    this.toggleSubmitStatus();
  }

  errorDialog(text) {
    return Swal.fire({
      text,
      title: this.defaultErrorMessage,
      icon: 'error',
      customClass: {
        actions: 'flex-row-reverse',
      },
    });
  }
}

onLoad(($) => {
  $('a.approve-charge-protest[data-remote="true"]').each((_, element) => new ChargeProtestApprove($(element)));
  $('.action-buttons a.approve-charge-protest').each((_, element) => new ChargeProtestApprove($(element)));

  $('body').on('change', () => {
    $('.action-buttons a.approve-charge-protest').each((_, element) => new ChargeProtestApprove($(element)));
  });
});
