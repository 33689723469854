const Shimmer = () => {
  const shimmerStyleClass = 'balance-info-shimmer';
  const balanceValueClass = 'JS-balance-info-value';

  const checkStorage = () => localStorage.getItem('@grafeno-toggle-balance');

  const storeState = () => {
    if (!checkStorage()) {
      return localStorage.setItem('@grafeno-toggle-balance', true);
    }
    return localStorage.removeItem('@grafeno-toggle-balance');
  };

  const toggleIcon = () => {
    $('.toggle-balance-icon .fa-eye-slash').toggleClass('d-none');
    $('.toggle-balance-icon .fa-eye').toggleClass('d-none');
  };

  const addShimmer = () => {
    const $value = $(`.${balanceValueClass}`);
    $value.children().addClass('d-none');
    $value.addClass(shimmerStyleClass);
  };

  const removeShimmer = () => {
    const $value = $(`.${balanceValueClass}`);
    $value.children().removeClass('d-none');
    $value.removeClass(shimmerStyleClass);
  };

  const verifyIcon = {
    'svg-inline--fa fa-eye fa-w-18 d-none': () => {
      toggleIcon();
      addShimmer();
      storeState();
    },
    'svg-inline--fa fa-eye-slash fa-w-20 d-none': () => {
      toggleIcon();
      removeShimmer();
      storeState();
    },
  };

  const initialize = () => {
    if (checkStorage()) {
      addShimmer();
      $('.toggle-balance-icon .fa-eye-slash').addClass('d-none');
      $('.toggle-balance-icon .fa-eye').removeClass('d-none');
    }
  };

  const apply = ($element) => {
    const icon = $element.children('.d-none').attr('class');
    verifyIcon[icon]();
  };

  return {
    initialize,
    apply,
  };
};

export default Shimmer;
