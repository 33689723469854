/* eslint-disable no-unused-vars, no-undef */
window.onLoad = function onLoad(f) {
  document.addEventListener('turbolinks:load', () => f($));
};

window.turbolinksSetInterval = function turbolinksSetInterval(intervalFunction, milliseconds) {
  const interval = setInterval(intervalFunction, milliseconds);

  function removeInterval() {
    clearInterval(interval);
    $(document).off('turbolinks:load', removeInterval);
  }

  $(document).on('turbolinks:load', removeInterval);
};

/* eslint-enable no-unused-vars, no-undef */
