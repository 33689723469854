const Masks = {
  decimalPlacesMoney: 2,
  decimalPlacesPercent: 1,
  fixMoneyInputValues($, selector, decimalPlaces) {
    $(selector).each((n, el) => {
      const value = el.value;
      if (value) $(el).val(parseFloat(value).toFixed(decimalPlaces));
    });
  },

  fixDateInputValues($) {
    $('.input--date').each((n, el) => {
      const date = el.value;
      if (date) {
        const [year, month, day] = date.split('-');
        $(el).val(`${day}/${month}/${year}`);
      }
    });
  },
  phoneMask: val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009'),
  barcodeMask: (val) => {
    const formattedVal = val.replace(/\D/g, '');

    if (formattedVal[0] === '8') {
      return '00000000000-0 00000000000-0 00000000000-0 00000000000-0';
    }
    return '00000.00000 00000.000000 00000.000000 0 00000000000000';
  },
  documentNumberMask: (val) => {
    const formattedValLength = val.replace(/\D/g, '').length;
    return formattedValLength > 11 || !formattedValLength ? '00.000.000/0000-00' : '000.000.000-009';
  },
  onKeyPress: f => ({ onKeyPress: (val, e, field, options) => field.mask(f(val), options) }),
  getFromClipboard: (originalEvent) => {
    let pastedText;

    if (originalEvent.clipboardData && originalEvent.clipboardData.getData) {
      pastedText = originalEvent.clipboardData.getData('text/plain');
    } else if (window.clipboardData && window.clipboardData.getData) {
      pastedText = window.clipboardData.getData('Text');
    }
    return pastedText;
  },
  isHelperKeyPressed: event => ['Control', 'Meta', 'Shift', 'Alt', 'Backspace'].includes(event.key),
  applyAll($) {
    const m = Masks;
    m.fixMoneyInputValues($, '.input--money', m.decimalPlacesMoney);
    m.fixMoneyInputValues($, '.input--percent', m.decimalPlacesPercent);
    m.fixDateInputValues($);

    $('input[type=text].document_number').mask(m.documentNumberMask, m.onKeyPress(m.documentNumberMask));
    $('.phone').mask(m.phoneMask, m.onKeyPress(m.phoneMask));

    $('.input--barcode').on('paste', function (e) {
      e.preventDefault();
      const originalEvent = e.originalEvent;
      const pastedText = m.getFromClipboard(originalEvent);
      const newValue = pastedText.replace(/\D/g, '');

      $(this).unmask();
      $(this).val(newValue);
      $(this).mask(m.barcodeMask, m.onKeyPress(m.barcodeMask));
      $(this).val($(this).masked(newValue));
    });

    $('.input--multibarcode').on('paste', function (e) {
      e.preventDefault();
      const $thisInput = $(this);
      let $this;
      const originalEvent = e.originalEvent;
      const pastedText = m.getFromClipboard(originalEvent);

      const splited = pastedText.split(/\n|\r|;/).filter(item => item);
      const maskedValues = [];

      $.each(splited, (key, value) => {
        const newValue = value.replace(/\D/g, '');

        $this = $('<input>').val(newValue).mask(m.barcodeMask);
        maskedValues.push($this.masked(newValue));
      });

      $thisInput.val((index, val) => {
        const previousValues = /\d$/.test(val[val.length - 1]) ? `${val};\n` : val;
        return `${previousValues + maskedValues.join(';\n')}`;
      });
    }).on('keyup', function (e) {
      if (!m.isHelperKeyPressed(e)) {
        e.preventDefault();
        const $thisInput = $(this);
        const splited = $thisInput.val().split(/\n|\r|;/).filter(item => item);
        const value = splited.pop();
        const oldValues = splited.length ? `${splited.join(';\n')};\n` : '';

        if (value) {
          const newValue = value.replace(/\D/g, '');
          const $this = $('<input>');

          if (e.code === 'Semicolon') {
            $thisInput.val(`${$thisInput.val()}\n`);
          } else if (e.code !== 'Enter') {
            $this.val(newValue);
            $this.mask(m.barcodeMask);
            $thisInput.val(`${oldValues}${$this.masked(newValue)}`);
          }
        }
      }
    }).on('keydown', function (e) {
      const $this = $(this);
      const val = $this.val();
      const lastCharIsNumber = /;|\n$/.test(val[val.length - 1]);
      if (e.code === 'Enter' && !lastCharIsNumber && !m.isHelperKeyPressed(e)) {
        e.preventDefault();
        $this.val(`${val};\n`);
      }
    });

    $('.input--barcode').mask(m.barcodeMask, m.onKeyPress(m.barcodeMask));
    $('.input--cpf').mask('000.000.000-00', { reverse: true });
    $('.input--cnpj').mask('00.000.000/0000-00', { reverse: true });
    $('.input--money').mask('##0.00', { reverse: true, placeholder: 'R$ 0.00' });
    $('.input--brl-money').mask('#.##0,00', { reverse: true, placeholder: 'R$ 0,00' });
    $('.input--percent').mask('##0.0', { reverse: true, placeholder: 'Ex: 11.5' });
    $('.input--long-percent').mask('##0.00', { reverse: true, placeholder: 'Ex: 11.35' });
    $('.input--percent-comma').mask('##0,00', { reverse: true, placeholder: 'Ex: 12,75%' });
    $('.input--date').mask('00/00/0000', { placeholder: '__/__/____' });
    $('.input--datetime').mask('00/00/0000 00:00', { placeholder: '__/__/____ __:__' });
    $('.input--cep').mask('00000-000');
    $('.input--bank-account').mask('##0-0', { reverse: true, placeholder: 'Ex: 1234-5' });
    $('.input--bank-agency').mask('##0', { reverse: true, placeholder: 'Ex: 1234' });
    $('.input--2fa').mask('000000');
  },
};

onLoad(Masks.applyAll);
