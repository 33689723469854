onLoad(($) => {
  if (!$('.report-incomes-modal').length) return;

  $('[data-tt=tooltip]').tooltip();

  const $yearSelect = $('.JS-modal-year-select');

  $yearSelect.on('change', (e) => {
    const id = e.target.id.split('_')[1];
    const optionValue = $(`#select_${id}`).children('option:selected').val();
    if (optionValue !== '') {
      $(`#select_button_${id}`).removeClass('btn-disabled');
    } else {
      $(`#select_button_${id}`).addClass('btn-disabled');
    }
  });
});
