import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const btnSelectBatch = $('#select_batch');

  if (!btnSelectBatch.length) return;

  localStorage.clear();

  const btnSelectBatchAction = $('.btn-select-batch-action');

  const checkboxValues = JSON.parse(localStorage.getItem('checkboxValues')) || {};
  let selected = [];

  const resetLocalStorage = () => {
    $('.selected-items').each((i, e) => {
      const element = $(e);
      checkboxValues[element.attr('id')] = {
        checked: element.is(':checked'),
        value: element.data('value'),
      };
    });

    localStorage.setItem('checkboxValues', JSON.stringify(checkboxValues));
  };

  const setSelectedChargesUUIDs = () => {
    $('.selected-items:checked').each((index, element) => {
      if (!selected.includes($(element).val())) {
        selected.push($(element).val());
      }
    });

    $('.selected-items:checkbox:not(:checked)').each((index, element) => {
      if (selected.includes($(element).val())) {
        selected = selected.filter(item => item !== $(element).val());
      }
    });

    $('.hidden-items').val(selected);
  };

  const setSelectedChargesTotalValue = (checkedValues) => {
    const totalValue = checkedValues.reduce((acc, curr) => acc + parseFloat(curr.value), 0);

    const currencyHelper = new CurrencyHelper();
    $('#total-charges-selected').text(currencyHelper.format(totalValue));
  };

  const setSelectedChargesCount = (selectedCount) => {
    const plural = selectedCount === 1 ? '' : 's';

    $('#charges-selected-count').text(`${selectedCount} título${plural}`);
  };

  const setSelectedChargesSummary = () => {
    const selectedChargesSummary = $('#summary-charges-selected');

    if (!selectedChargesSummary.length) return;

    const checkedValues = Object.values(checkboxValues).filter(v => v.checked);
    setSelectedChargesTotalValue(checkedValues);

    const selectedCount = checkedValues.length;
    setSelectedChargesCount(selectedCount);

    selectedChargesSummary.toggleClass('d-none', !selectedCount);
  };

  $('body').on('change', '.selected-items', () => {
    resetLocalStorage();

    setSelectedChargesUUIDs();

    setSelectedChargesSummary();

    btnSelectBatchAction.toggleClass('d-none', !$('.selected-items').filter(':checked').length);
  });

  btnSelectBatch.on('change', function checkUncheck() {
    $('.selected-items').prop('checked', $(this).is(':checked')).trigger('change');
  });
});
