/*
  TODO: separar comportamentos
  ex.: app/frontend/javascripts/multiselect_checkbox.js
  e app/frontend/javascripts/transfer_requests/index_multiselect.js
*/
import CurrencyHelper from './helper/currencyHelper';

onLoad(($) => {
  const $btnSelectAll = $('#select_all');
  if (!$btnSelectAll.length) return;

  const $btnSelectAllAction = $('.btn-select-all-action');
  const $limit = $btnSelectAll.data('limit-size');
  const $selectAllCheckboxes = $('.select_all-checkbox').filter('input[type=checkbox]').filter('input:enabled');
  const registeredSize = $btnSelectAll.data('registred-size');
  const isRegisteredSizePresent = !Number.isNaN(parseFloat(registeredSize));

  const currencyHelper = new CurrencyHelper();

  function updateSelectAllActions() {
    let params = '';
    let paramsBoletos = '';
    const $checkboxesChecked = $selectAllCheckboxes.filter(':checked');
    let selectedCount = 0;
    let totalValue = 0;
    const transformToInt = 100;

    if ($checkboxesChecked.length) {
      $checkboxesChecked.each(function buildParams() {
        params += `ids[]=${this.value}&`;

        // eslint-disable-next-line radix
        params += `vals[]=${parseInt($(this).parents('tr').find('input.paid_value').val() * transformToInt)}&`;
        if (JSON.parse(this.getAttribute('data-download-boleto'))) {
          selectedCount += 1;
        }
        if ($(this).attr('data-value')) {
          totalValue += parseFloat($(this).data('value'));
        }
      });
    }

    $btnSelectAllAction.each(function updateButtonActions() {
      const prefix = $(this).data('prefix') || '';
      const url = $(this).attr('href').split('?')[0];
      const action = $(this).data('action');
      const type = $(this).data('type');
      let qnt;
      let text;

      if (action === 'read') {
        if ($btnSelectAll.prop('checked')) {
          paramsBoletos = 'all=true&';
          const result = $btnSelectAll.data('filters');
          $.each(result, (k, v) => {
            paramsBoletos += `filters[${k}]=${v}&`;
          });
          text = 'Será feito o download de todos os boletos disponíveis.';
        } else if (selectedCount === 0) {
          paramsBoletos = '';
          text = 'Nenhuma cobrança com boleto foi encontrada para gerar o relatório.';
        } else {
          paramsBoletos = params;
          qnt = `${selectedCount} (Limite por arquivo: ${$limit})`;
          text = `${prefix} Total de itens selecionados: ${qnt}`;
        }
        $(this).attr('data-text', text).data('text', text).attr('href', `${url}?${paramsBoletos}`);
      } else if (action === 'cancel_all') {
        const value = currencyHelper.format(totalValue);
        const confirmText = `
        <div>
          Você está prestes a cancelar <strong>${$checkboxesChecked.length} 
          solicitaç${$checkboxesChecked.length > 1 ? 'ões' : 'ão'} de ${type} </strong>
          no valor total de <strong>${value}</strong>.
        </div>
        <div class="mt-3">Tem certeza que deseja continuar?</div>
        `;

        $(this).attr('href', `${url}?${params}`);
        $(this).data('html', confirmText);
        $(this).data('confirmbutton', 'Sim, continuar');
      } else {
        let totalItemsText = '';
        if (isRegisteredSizePresent) totalItemsText = `/${registeredSize}`;
        qnt = `${$checkboxesChecked.length}${totalItemsText}`;
        text = `${prefix} Total de itens selecionados: ${qnt}`;
        $(this).attr('data-text', text).data('text', text).attr('href', `${url}?${params}`);
      }
    });
  }

  $btnSelectAll.on('change', function checkUncheck() {
    $selectAllCheckboxes.prop('checked', $(this).is(':checked'));
  });

  $selectAllCheckboxes.add($btnSelectAll).on('change', () => {
    const $checkeds = $selectAllCheckboxes.filter(':checked');
    const $uncheckeds = $selectAllCheckboxes.not(':checked');
    if (isRegisteredSizePresent) {
      const isLessThenRegistred = $checkeds.length < $btnSelectAll.data('registred-size');

      if (isLessThenRegistred && $uncheckeds.length) Swal.close();
      // TODO: sujeira de código, Swal não é utilizado mais nesse arquivo
    }

    $btnSelectAllAction.toggleClass('d-none', !$checkeds.length);
    updateSelectAllActions();

    $btnSelectAll.prop('checked', !$uncheckeds.length).trigger('update-state');
  });
});
