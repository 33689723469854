import { validateForm } from '../helper/validate_form';

onLoad(($) => {
  if (!$('#ccb-requests-new').length) return;
  const $continueButton = $('#JS-button-continue-ccb-request-new');
  const $selects = $('.JS-select-autocomplete');
  const $checkbox = $('#checkbox-accept-terms-of-service');
  const $form = $('.select2-selection__rendered, #checkbox-accept-terms-of-service');

  function composeValidateForm() {
    return $form.map(function () {
      return Boolean($(this).attr('title') || $(this).prop('checked'));
    });
  }

  $selects.add($checkbox).on('change', () => {
    validateForm({
      $form: composeValidateForm(),
      $button: $continueButton,
    });
  });
});
