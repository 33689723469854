onLoad(($) => {
  const $warranty = $('#JS-ccb-warranty');
  if (!$warranty.length) return;

  const EMPTY_VALUE = '';
  const $buttonAddWarrantyReceivables = $('#JS-button-add-ccb-warranty-receivables');
  const $buttonDeleteWarrantyReceivables = $('.JS-button-delete-ccb-warranty-receivables');
  const $buttonEditWarrantyReceivables = $('#JS-button-edit-ccb-warranty-receivables');
  const $selectWarrantyReceivables = $('#ccb_request_warranty_account_id');
  const selectWarrantyReceivablesValue = $('#ccb_request_warranty_account_id').val();
  const $selectWarrantyReceivablesLabel = () => $('span.select2-selection__rendered');
  const $buttonCancelModal = () => $('button.swal2-cancel');
  const cleanSelectWarrantyReceivablesValue = () => $selectWarrantyReceivables.val(EMPTY_VALUE);
  const fillSelectWarrantyReceivablesValue = () => $selectWarrantyReceivables.val(selectWarrantyReceivablesValue);
  const cleanSelectWarrantyReceivablesLabel = () => $selectWarrantyReceivablesLabel().text(EMPTY_VALUE);
  const $buttonAddWarranty = value => $(`[data-selected="${value}"]`);
  const $selectWarranty = $('#JS-select-ccb-warranty');
  const $buttonsAddWarranty = $('[data-selected]');
  const templateLabelButtonAdd = '<i class="fas fa-times mr-2"></i> Remover';
  const templateLabelButtonRemove = '<i class="fas fa-plus mr-2"></i> Adicionar';

  const selectWarrantyOwner = $('.warranty-owner-select');
  const attrDataOwnerValue = selectWarrantyOwner.attr('data-owner-value');

  function hideButtonsAddWarranty() {
    $buttonsAddWarranty.addClass('d-none');
  }

  function showButtonAddWarranty(value) {
    $buttonAddWarranty(value).removeClass('d-none');
  }

  function handleButtonDeleteWarrantyReceivables() {
    cleanSelectWarrantyReceivablesValue();
    $buttonCancelModal().on('click', () => {
      fillSelectWarrantyReceivablesValue();
    });
  }

  function handleButtonAddWarrantyReceivables() {
    const isExpanded = !($buttonAddWarrantyReceivables.attr('aria-expanded') === 'true');
    if (isExpanded) {
      $buttonAddWarrantyReceivables.html(templateLabelButtonAdd);
      return;
    }
    cleanSelectWarrantyReceivablesValue();
    cleanSelectWarrantyReceivablesLabel();
    $buttonAddWarrantyReceivables.html(templateLabelButtonRemove);
  }

  function handleButtonEditWarrantyReceivables() {
    $selectWarranty.val('warranty-receivables').trigger('change');
  }

  function handleSelectWarranty() {
    hideButtonsAddWarranty();
    showButtonAddWarranty($selectWarranty.val());
  }

  $warranty.ready(hideButtonsAddWarranty);
  $buttonDeleteWarrantyReceivables.on('click', handleButtonDeleteWarrantyReceivables);
  $buttonAddWarrantyReceivables.on('click', handleButtonAddWarrantyReceivables);
  $buttonEditWarrantyReceivables.on('click', handleButtonEditWarrantyReceivables);
  $selectWarranty.on('change', handleSelectWarranty);

  $('.warranty-input-uppercase-text').on('keyup', (e) => {
    const key = e.target;
    const start = key.selectionStart;
    const end = key.selectionEnd;
    key.value = key.value.toUpperCase();
    key.setSelectionRange(start, end);
  });

  if (attrDataOwnerValue) {
    selectWarrantyOwner.val(attrDataOwnerValue).change();
  }
});
