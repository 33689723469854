/* eslint-disable no-useless-escape */

onLoad(($) => {
  const $formPassword = $('.JS-form-password');
  if (!$formPassword.length) return;

  const $buttonRedefinePassword = $('.JS-button-redefine-password');
  const $inputConfirmationPassword = () => $('.JS-input-confirmation-password');
  const $inputCreatePassword = () => $('.JS-input-create-password');
  const $inputsPassword = [$inputCreatePassword(), $inputConfirmationPassword()];
  const $validationItemList = $('.JS-list-validate-password').find('li');

  const errorTemplate = text => `<div class="invalid-feedback d-block">${text}</div>`;
  const iconErrorTemplate = '<span class="fa fa-fw fa-times"></span>';

  const inputConfirmationPasswordValue = () => $inputConfirmationPassword().find('input').val();
  const inputCreatePasswordValue = () => $inputCreatePassword().find('input').val();
  const isMatchPassword = () => inputCreatePasswordValue() === inputConfirmationPasswordValue();
  const validPasswords = {
    isCreatePassword: false,
    isConfirmationPassword: false,
  };
  let matchPassword = false;

  function isAllValidationsPassed() {
    return matchPassword && validPasswords.isCreatePassword && validPasswords.isConfirmationPassword;
  }

  const getValidations = () => {
    const sequencialNumbers = ['012', '123', '234', '345', '456', '567', '678', '789'];
    const listValidate = {
      'min-length': text => /^.{8,}$/.test(text),
      uppercase: text => /[A-Z]+/.test(text),
      lowercase: text => /[a-z]+/.test(text),
      number: text => /[0-9]+/.test(text),
      special: text => /[^a-zA-Z0-9\_]+/.test(text),
      'sequencial-number': text => !sequencialNumbers.some(numbers => text.includes(numbers)),
    };
    return listValidate;
  };

  const toggleValidateItem = (isValid, key) => {
    const $item = $(`.JS-item-validate-${key}`);
    const $svg = $item.find('svg');
    $item[`${isValid ? 'add' : 'remove'}Class`]('text-success');
    $item[`${!isValid ? 'add' : 'remove'}Class`]('text-danger');
    $svg[`${isValid ? 'add' : 'remove'}Class`]('fa-check-circle');
    $svg[`${!isValid ? 'add' : 'remove'}Class`]('fa-times');
  };

  const validateCreatePassword = (text) => {
    const validations = getValidations();
    const keys = Object.keys(validations);
    const isEmptyText = !(text);
    if (isEmptyText) {
      $validationItemList.removeClass('text-danger').removeClass('text-success');
      $validationItemList.find('svg').removeClass('fa-check-circle').addClass('fa-times');
      return;
    }
    keys.forEach((key) => {
      const isValid = validations[key](text);
      toggleValidateItem(isValid, key);
    });
    const isValidPassword = keys.every(key => validations[key](text));
    validPasswords.isCreatePassword = isValidPassword;
  };

  const validateConfirmationPassword = () => {
    const textError = 'As senhas não coincidem.';
    if (!inputCreatePasswordValue()) return;
    if (!inputConfirmationPasswordValue()) return;
    if (!isMatchPassword()) {
      $inputsPassword.forEach((element) => {
        const $element = $(element);
        if ($element.find('.invalid-feedback').length) return;
        $element.find('.form-group').append(errorTemplate(textError));
        $element.find('.toggle-password').prepend(iconErrorTemplate);
        $element.find('input').addClass('is-invalid');
      });
      return;
    }
    $inputsPassword.forEach((element) => {
      const $element = $(element);
      $element.find('.invalid-feedback').remove();
      $element.find('.fa-times').remove();
      $element.find('input').removeClass('is-invalid');
    });
    validPasswords.isConfirmationPassword = true;
  };

  const errorCreatePassword = () => {
    const $error = $inputCreatePassword().find('.invalid-feedback');
    const $icon = $inputCreatePassword().find('.toggle-password');
    $error.insertAfter($icon);
  };

  const handleError = (errors) => {
    const otherErrors = ['taken_in_past', 'username', 'email'];
    const keys = Object.keys(errors);
    const mapedErrors = [];

    otherErrors.forEach((key) => {
      const isValid = !keys.includes(key);
      mapedErrors.push(!isValid);
      toggleValidateItem(isValid, key);
    });

    const isMapedError = mapedErrors.some(error => Boolean(error));

    if (isMapedError) {
      $buttonRedefinePassword.addClass('btn-disabled');
      return;
    }

    if (!isMapedError && isAllValidationsPassed()) {
      $buttonRedefinePassword.removeClass('btn-disabled');
    }
  };

  const setData = () => (
    {
      password: inputCreatePasswordValue(),
      reset_password_token: $('#JS-reset-token-field').val(),
      invitation_token: $('#JS-invitation-token-field').val(),
    }
  );

  const setRequest = () => {
    if (!inputCreatePasswordValue()) return;

    $.ajax({
      url: '/users/password_validations/valid_new_password',
      type: 'POST',
      data: setData(),
      dataType: 'json',
      success: () => {
        $validationItemList.removeClass('text-danger').addClass('text-success');
        $validationItemList.find('svg').addClass('fa-check-circle').removeClass('fa-times');
        if (isMatchPassword()) {
          $buttonRedefinePassword.removeClass('btn-disabled');
        }
      },
      error: (response) => {
        if ((response.status === 500 || !('responseJSON' in response)) && isAllValidationsPassed()) {
          $buttonRedefinePassword.removeClass('btn-disabled');
          return;
        }
        const errors = response.responseJSON.errors;
        handleError(errors);
      },
    });
  };

  const validatePasswords = () => {
    if (isAllValidationsPassed()) {
      setRequest();
      return;
    }
    $buttonRedefinePassword.addClass('btn-disabled');
  };

  $inputCreatePassword().on('input', (event) => {
    validateCreatePassword(event.target.value);
    validateConfirmationPassword();
    matchPassword = isMatchPassword();
    validatePasswords();
  });

  $inputConfirmationPassword().on('input', () => {
    validateConfirmationPassword();
    matchPassword = isMatchPassword();
    validatePasswords();
  });

  $inputCreatePassword().find('input').add($inputConfirmationPassword().find('input')).on('blur', () => {
    setRequest();
  });

  $formPassword.ready(() => {
    errorCreatePassword();
  });
});
