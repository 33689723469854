import applyTooltips from '../helper/tooltip';

onLoad(($) => {
  if (!$('.JS-form-with-tooltips').length) return;

  const tooltipAlerts = {
    observation: 'Mensagem que aparece no boleto da cobrança, como instruções, avisos ou lembretes.',
    interest_type_cd: 'Tipo de juros aplicado à cobrança.',
    monthly_interest: 'Valor fixo ou o percentual do juros cobrado por mês sobre a cobrança.',
    formatted_applicable_fine: 'Valor em percentual da multa cobrada em caso de atraso no pagamento da cobrança.',
    discount_type_cd: 'Tipo de desconto aplicado à cobrança.',
    discount_value: 'Valor fixo ou o percentual do desconto concedido na cobrança.',
    discount_until: 'Quantidade de dias após o vencimento da cobrança para que ela seja baixada automaticamente.',
    days_write_off_after_due_date: `Indica o número de dias que a cobrança permanece ativa após a data de vencimento
    para realizar baixa automática.`,
  };

  $('.charge_days_write_off_after_due_date label').text('Realizar baixa automática após (dias)');

  applyTooltips('charge', tooltipAlerts);
});
