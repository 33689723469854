import Pin from './pin';
import TwoFactor from './two_factor';

const UserAuth = (requires2fa, requiresPin = false) => {
  const validate = async () => {
    const pin = new Pin();
    const defaultResponse = { success: false };

    if (requires2fa) {
      const twoFactor = new TwoFactor();
      const result = await twoFactor.showDialog();
      if (result.isDismissed || result.error) {
        return defaultResponse;
      }

      return { success: true, data: { otp_attempt: result.value } };
    }

    if (requiresPin && pin.shouldForcePinAuth()) {
      const result = await pin.showDialog();
      if (result.isDismissed) return defaultResponse;

      return { success: true, data: { pin: result.value } };
    }

    return { success: true, data: {} };
  };

  return { validate };
};

export default UserAuth;
