onLoad(($) => {
  const nodes = $('.JS-api-token-extra-fields');

  if (!nodes.length) return;

  const checkedBoxes = [];
  const confirmationUrlDiv = $('.JS-api-token-extra-fields-confirmation-url');
  const confirmationKinds = ['create_ted'];

  let confirmationUrlShowing = true;

  const manageCheckbox = (checkBox) => {
    if (checkBox.prop('checked') === true) {
      checkedBoxes.push(checkBox.val());
    } else {
      const index = checkedBoxes.indexOf(checkBox.val());

      if (index !== -1) checkedBoxes.splice(index, 1);
    }
  };

  const toggleCreateTedField = () => {
    if (confirmationUrlShowing === true) {
      confirmationUrlDiv.hide().attr({ disabled: true, readonly: 'readonly' });
      $('#api_token_confirmation_url').val('');

      confirmationUrlShowing = false;
    } else {
      confirmationUrlShowing = true;
      confirmationUrlDiv.show().attr({ disabled: false, readonly: false });
    }
  };

  const manageConfirmationDiv = () => {
    let hasCheckbox = false;

    for (let i = 0; i < checkedBoxes.length; i += 1) {
      if (confirmationKinds.includes(checkedBoxes[i]) === true) {
        hasCheckbox = true;
      }
    }

    if (hasCheckbox === true) {
      if (confirmationUrlShowing === false) {
        toggleCreateTedField();
      }
    } else if (confirmationUrlShowing === true) {
      toggleCreateTedField();
    }
  };

  $(nodes).each(function () {
    manageCheckbox($(this));
    manageConfirmationDiv();
  });

  nodes.change(function () {
    manageCheckbox($(this));
    manageConfirmationDiv();
  });
});
