onLoad(($) => {
  const $parentCheckbox = $('#JS-client-users-roles-checkbox-parent');

  if (!$parentCheckbox.length) return;

  const $checkboxes = $('.JS-client-users-roles-checkbox-child');

  // Toggle all checkboxes by parentCheckbox's state
  $parentCheckbox.on('change', (event) => {
    const $target = $(event.currentTarget);
    $checkboxes.prop('checked', $target.is(':checked'));
    $checkboxes.change();
  });

  // Toggle parentCheckbox by checkboxes's state
  $checkboxes.on('change', (event) => {
    const $target = $(event.currentTarget);

    if (!$target.is(':checked')) {
      $parentCheckbox.prop('checked', false);
    }
  });
});
