import MultiselectCheckbox from '../multiselect_checkbox';

class PendingApprovalMultiselect extends MultiselectCheckbox {
  constructor($element, options = {}) {
    super($element, options);
    this.localStorageKeyName = 'chargeProtests';
    this.storedValues = this.getStoredValues();
  }

  getStoredValues() {
    return JSON.parse(localStorage.getItem(this.localStorageKeyName)) || {};
  }

  resetLocalStorage() {
    this.$itemCheckboxes.each((_, e) => {
      const element = $(e);
      this.storedValues[element.attr('id')] = {
        checked: element.is(':checked'),
        uuid: element.data('uuid'),
      };
    });

    localStorage.setItem(this.localStorageKeyName, JSON.stringify(this.storedValues));
  }

  getStoredSelectedValues() {
    return Object.values(this.getStoredValues()).filter(v => v.checked);
  }

  storedSelectedValuesCount() {
    return this.getStoredSelectedValues().length;
  }

  hasCheckedItems() {
    /* Assim o usuário não precisa checar algo
    caso tenha mudado a paginação para aparecer o botão de download */
    return this.storedSelectedValuesCount();
  }

  getSelectedItemsText(checkedItemsQuantity = this.checkedItemsQuantity()) {
    const checkedItemsText = `${checkedItemsQuantity}`;
    return `Total de itens selecionados: ${checkedItemsText}`;
  }
}

function updateState(multiselect) {
  multiselect.resetLocalStorage();
  let params = '';
  if (multiselect.hasCheckedItems()) {
    multiselect.getStoredSelectedValues().forEach((checkedItem) => {
      params += `ids[]=${checkedItem.uuid}&`;
    });
  }

  multiselect.$actionButtons.each((_, button) => {
    const $btn = $(button);
    const url = $btn.attr('href').split('?')[0];
    const dataset = $btn.data('approveChargeProtest');
    const selectedCount = multiselect.storedSelectedValuesCount();

    if (dataset) {
      dataset.text = `${dataset.prefix}<br/><br/>${multiselect.getSelectedItemsText(selectedCount)}`;
      $btn.data('approveChargeProtest', dataset);
    } else {
      const prefix = $btn.data('prefix');
      const confirmText = `${prefix} ${multiselect.getSelectedItemsText(selectedCount)}`;
      $btn.data('text', confirmText);
    }

    $btn.attr('href', `${url}?${params}`);
  });
}

const actionButtonsClass = 'btn-charge-protest-action';

onLoad(($) => {
  localStorage.removeItem('chargeProtests');

  $('#select_all_charge_protest')
    .each((_, element) => new PendingApprovalMultiselect($(element), { actionButtonsClass, updateState }));

  $('body').on('change', () => {
    $('#select_all_charge_protest')
      .each((_, element) => new PendingApprovalMultiselect($(element), { actionButtonsClass, updateState }));
  });
});
