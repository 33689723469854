onLoad(($) => {
  const $pageWrapper = $('#wrapper');
  const $sidebarMenu = $('#sidebar-menu');
  const $locationPathname = window.location.pathname;

  const $locationSearch = window.location.search;
  const $isHomePage = $locationPathname === '/';
  const $isSearchPage = $locationSearch.length > 0;

  // Cache frequently used elements
  const $hideSidebarButton = $('.hide-sidebar-button');
  const $listCollapseItems = $sidebarMenu.find('.collapse');

  function fetchActiveField() {
    const findKey = (!$isHomePage && $isSearchPage)
      ? `a[href^='${$locationPathname}']`
      : `a[href='${$locationPathname}']`;
    const element = $sidebarMenu.find(findKey);

    return (element.length > 1)
      ? $sidebarMenu.find(`a[href='${$locationPathname}']`)
      : element;
  }

  const $fieldActive = fetchActiveField();
  const $fieldParent = $fieldActive.closest('.sidebar-submenu');

  // Define function to toggle the active state and submenu arrow
  function toggleClass($element, className) {
    $element.toggleClass(className);
  }

  window.toggleSidebar = function toggleSidebar() {
    $('.submenu-arrow.turn').removeClass('turn');
    toggleClass($pageWrapper, 'toggled');
    toggleClass($('#button-icon'), 'fa-angle-left fa-angle-right');
  };

  function submenuArrowState(e) {
    const arrow = $(e.currentTarget).find('.submenu-arrow');
    $('.submenu-arrow.turn').not(arrow).removeClass('turn');
    return arrow.hasClass('turn') ? arrow.removeClass('turn') : arrow.addClass('turn');
  }

  // Handle clicks on top-level menu items
  $sidebarMenu.on('click', '.list-group-item.top-item', (e) => {
    const menuClicked = $(e.currentTarget);
    const hasDataAttributes = menuClicked.attr('data-parent') || menuClicked.attr('data-toggle');

    if (!hasDataAttributes) {
      e.stopPropagation();
      $listCollapseItems.find('.show').collapse('hide');
    }
  });

  $sidebarMenu.on('click', '.list-group-item', (e) => {
    const sidebarMinimize = $(e.currentTarget).closest('#wrapper');
    const $screenSize = $(window).width() > 768;
    if (sidebarMinimize.hasClass('toggled') && $screenSize) {
      sidebarMinimize.removeClass('toggled');
    }

    submenuArrowState(e);
  });

  // Function to set the active sidebar item
  function activeSidebar() {
    if ($locationPathname === '') return;

    $fieldActive.addClass('active');

    if ($fieldParent.length) {
      $fieldParent.addClass('show');
      const listGroupParent = $(`a[href='#${$fieldParent.attr('id')}']`);
      listGroupParent.addClass('active').attr('aria-expanded', 'true');
      listGroupParent.find('.submenu-arrow').addClass('turn');
    }
  }

  // Combine event listeners for the hide-sidebar button
  $hideSidebarButton.on('click', (e) => {
    const hasToggle = $(e.currentTarget).closest('.toggled').length;

    if (hasToggle) {
      $listCollapseItems.collapse('hide');
    } else {
      activeSidebar();
    }
  });

  activeSidebar();
});
