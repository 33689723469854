import haveDesignSystem from '../helper/have_design_system';
import ToggleIcon from './toggle_icon';

const toggleIcon = new ToggleIcon();

onLoad(($) => {
  if (!haveDesignSystem()) {
    return;
  }

  const putFilterInContainer = ($container, $filter) => {
    $container.first().after($filter);
  };

  const putFilterInsideCardContainer = () => {
    const $filterContainer = $('.JS-ds-search-form');
    const $filterBtn = $('#ds-btn-filter');
    const $cardHeader = $('.main-content .card-default div.card-header');
    const $btnsHeader = $('.JS-ds-search-form-btns');
    const $btnHeaderPrefix = $('.JS-ds-search-form-prefix');
    const $hasFilter = $('.JS-has-filter');

    if ($filterContainer.length && $cardHeader.length > 1) {
      if ($hasFilter.length) putFilterInContainer($hasFilter, $filterContainer);
      else putFilterInContainer($cardHeader, $filterContainer);
    } else if ($filterContainer.length) {
      $cardHeader.after($filterContainer);
    }

    if ($btnHeaderPrefix.length) {
      $btnHeaderPrefix.append($filterBtn);
      $('.filter-toggle-search').addClass('w-100').append($btnHeaderPrefix);
    }
    if ($btnsHeader.length) {
      $btnsHeader.prepend($filterBtn);
      $('.filter-toggle-search').append($btnsHeader);
    }
  };

  const setDefaultIcon = () => {
    const $filterContainer = $('#collapse-filter');

    if ($filterContainer.length && $filterContainer.hasClass('show')) {
      toggleIcon.showFilter();
    }
  };

  const toggleIconFilterBtn = () => {
    const $filterBtn = $('[href="#collapse-filter"]');
    $filterBtn.on('click', () => {
      const filterContainerIsOpen = $('#collapse-filter').hasClass('show');

      if (filterContainerIsOpen) {
        return toggleIcon.showTimes();
      }
      return toggleIcon.showFilter();
    });
  };

  setDefaultIcon();
  toggleIconFilterBtn();
  putFilterInsideCardContainer();
});
