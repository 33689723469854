onLoad(($) => {
  const $btnSelectAllConciliation = $('.select-all-conciliation');
  let $trsCheckeds;

  function updateStockSession() {
    const $btnSelectAllAction = $('#select-all-action');
    const $btnDownloadCnab = $('.JS-download-cnab');
    const checkedIds = $trsCheckeds.find('.select-all-checkbox').map(function () { return this.value; }).get();
    const checkedValues = $trsCheckeds
      .find('.paid_value')
      .map(function () { return parseFloat(this.value.replace(new RegExp('\\.', 'g'), '').replace(',', '.')); }).get();
    const totalValuesChecked = checkedValues.length ? checkedValues.reduce((sum, x) => sum + x) : 0.0;
    let activeId = $('.conciliation-groups').find('a').filter('.active').data('id');
    activeId = activeId || $('#select-group-autocomplete').children('option:selected').val();

    const conciliationItems = [];

    $btnSelectAllAction.add($btnDownloadCnab).addClass('disabled');

    $(checkedIds).each(function (index) {
      conciliationItems.push({ socopa_integrator_stock_id: this, value: checkedValues[index] });
    });

    $.ajax({
      url: window.location.pathname,
      beforeSend(xhr) { xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content')); },
      dataType: 'script',
      method: 'PATCH',
      data: {
        conciliation_item: { value: totalValuesChecked, stocks_attributes: conciliationItems },
        active_id: activeId,
      },
    });
  }

  function resetValueToNominal($el) {
    const $parentTd = $el.closest('td');
    const $input = $parentTd.find('.paid_value');
    $input.val($parentTd.siblings('.JS-vl-nominal').data('value').trim());
  }

  if ($btnSelectAllConciliation.length) {
    const $selectAllCheckboxes = $('.select-all-checkbox');
    const $inputPaidValues = $('.paid_value');
    $trsCheckeds = $selectAllCheckboxes.filter(':checked').closest('tr');
    let $trsUncheckeds = $selectAllCheckboxes.not(':checked').closest('tr');
    let $currentTr;

    $btnSelectAllConciliation.on('change', function checkUncheck() {
      $selectAllCheckboxes.prop('checked', $(this).is(':checked'));
    });

    $inputPaidValues.on('change', () => {
      updateStockSession($trsCheckeds);
    });

    $selectAllCheckboxes.add($btnSelectAllConciliation).on('change', (event) => {
      $trsCheckeds = $selectAllCheckboxes.filter(':checked').closest('tr');
      $trsUncheckeds = $selectAllCheckboxes.not(':checked').closest('tr');

      $currentTr = $(event.target).closest('tr');
      $.when($currentTr.fadeOut(500)).done(() => {
        $trsUncheckeds.appendTo('#table-unselecteds')
          .find('.JS-vl-pago')
          .addClass('d-none');

        $trsCheckeds.appendTo('#table-selecteds')
          .find('.JS-vl-pago')
          .removeClass('d-none');

        $currentTr.fadeIn(500);

        updateStockSession();

        $btnSelectAllConciliation
          .filter('#select-all-conciliation-selecteds')
          .prop('checked', $trsCheckeds.length).end()
          .filter('#select-all-conciliation-unselecteds')
          .prop('checked', false);
      });
    });

    $('.reset-input').on('click', (event) => {
      const $resetInput = $(event.target);
      resetValueToNominal($resetInput);
      updateStockSession();
      $resetInput.blur();
    });

    $('.reset-input-all').on('click', (event) => {
      event.preventDefault();
      $('tbody').find('.reset-input').each((_i, el) => resetValueToNominal($(el)));
      updateStockSession();
      $(event.target).blur();
    });
  }
});
