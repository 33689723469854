onLoad(($) => {
  $('[id=JS-user-alerts-dropdown]').on('show.bs.dropdown', () => {
    $('.notif').remove();
    $('[id=JS-span-alerts]').text('');
    $('[id=JS-icon-alerts]').hide();
    $('[id=JS-load-alerts]').addClass('spinner-border disabled');

    $.get('/user/alert', () => {}).done(() => {
      $('[id=JS-span-alerts]').text('Notificações');
      $('[id=JS-icon-alerts]').show();
      $('[id=JS-load-alerts]').removeClass('spinner-border disabled');
    });
  });
});
