import Utils from './onboarding/grantor/utils';

onLoad(($) => {
  $('#contacts')
    .on('cocoon:after-insert', () => {
      const phoneMask = val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009');
      const onKeyPress = f => ({ onKeyPress: (val, e, field, options) => field.mask(f(val), options) });
      $('.phone').mask(phoneMask, onKeyPress(phoneMask));
    });

  $('#apportionments')
    .on('cocoon:after-insert', () => {
      $('.input--percent').mask('##99.99%', { reverse: true, placeholder: '0,00%', watchDataMask: true });
    });

  $('#people')
    .on('cocoon:after-insert', (event, person) => {
      const $onboarding = $('#onboarding-grantor');
      Utils.labelActive($onboarding);
      $('.input--cpf').mask('000.000.000-00', { reverse: true });
      const phoneMask = val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009');
      const onKeyPress = f => ({ onKeyPress: (val, e, field, options) => field.mask(f(val), options) });
      $('.phone').mask(phoneMask, onKeyPress(phoneMask));
      const $datepicker = $('.datepicker');
      const currentDate = new Date();
      Utils.addCheckIcon($onboarding);
      person.find('.custom-control-label').attr('for', person.find('.boolean').attr('id'));

      $datepicker.pickadate({
        // Strings and translations
        monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
          'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Agos', 'Set', 'Out', 'Nov', 'Dez'],
        weekdaysFull: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        // Buttons
        today: 'Hoje',
        clear: 'Limpar',
        close: 'Fechar',
        // Accessibility labels
        labelMonthNext: 'Próximo mês',
        labelMonthPrev: 'Mês anterior',
        labelMonthSelect: 'Selecione um mês',
        labelYearSelect: 'Selecione um ano',
        // Formats
        format: 'dd/mm/yyyy',
        editable: true,
        // Dropdown selectors
        selectYears: 100,
        selectMonths: true,
        // Date limits
        min: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()),
        max: new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate()),
      });

      $datepicker.mask('99/99/9999');
    });

  $('#administrators')
    .on('cocoon:after-insert', () => {
      const $onboarding = $('#onboarding-grantor');
      Utils.labelActive($onboarding);

      $('.input--cpf').mask('000.000.000-00', { reverse: true });
      const phoneMask = val => (val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009');
      const onKeyPress = f => ({ onKeyPress: (val, e, field, options) => field.mask(f(val), options) });
      $('.phone').mask(phoneMask, onKeyPress(phoneMask));
      const $datepicker = $('.datepicker');
      const currentDate = new Date();

      $datepicker.pickadate({
        // Strings and translations
        monthsFull: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
          'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Agos', 'Set', 'Out', 'Nov', 'Dez'],
        weekdaysFull: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        // Buttons
        today: 'Hoje',
        clear: 'Limpar',
        close: 'Fechar',
        // Accessibility labels
        labelMonthNext: 'Próximo mês',
        labelMonthPrev: 'Mês anterior',
        labelMonthSelect: 'Selecione um mês',
        labelYearSelect: 'Selecione um ano',
        // Formats
        format: 'dd/mm/yyyy',
        editable: true,
        // Dropdown selectors
        selectYears: 100,
        selectMonths: true,
        // Date limits
        min: new Date(1950, 1, 1),
        max: new Date(currentDate.getFullYear() + 5, currentDate.getMonth(), currentDate.getDate()),
      });

      $datepicker.mask('99/99/9999');
    });

  $('#administrators')
    .on('cocoon:before-remove', (event, administrator) => {
      if (!administrator.data('marked-removed')) {
        event.preventDefault();
        Swal.fire({
          title: 'Você tem certeza que deseja remover?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.value === true) {
            administrator.data('marked-removed', true);
            administrator.find('.btn.remove').click();
            administrator.find('.form-control').addClass('confirmed');
          }
        });
      }
    });

  $('#contract-email')
    .on('cocoon:after-insert', () => {
      const $onboarding = $('#onboarding-grantor');
      Utils.labelActive($onboarding);
    });

  $('#bank-accounts')
    .on('cocoon:after-insert', () => {
      const $onboarding = $('#onboarding-grantor');
      Utils.labelActive($onboarding);
    });
});
