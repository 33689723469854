onLoad(() => {
  function autoRefreshPage() {
    const documentoscopy = document.getElementById('JS-container-documentoscopy');
    if (documentoscopy) {
      setTimeout(() => {
        window.location.reload();
      }, 120000);
    }
  }

  window.onload = autoRefreshPage();
});
