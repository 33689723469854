export default {
  msgInvalidCheckField: 'É necessário confirmar ou editar os campos acima',

  datepicker(mainElement) {
    mainElement.find('form').on('click', '.label-datepicker', function () {
      const $label = $(this).closest('label');
      const $input = $label.find('.form-control');

      if ($input.hasClass('readonly')) return false;

      $label.find('.label-txt').addClass('label-active');

      $input.pickadate('picker').open();
      return false;
    });
  },

  selectOrOther(mainElement, hideDiv, select, field, toggleOption) {
    const hideDivNode = mainElement.find(hideDiv);
    const selectNode = mainElement.find(select);
    const fieldNode = mainElement.find(field);

    const unhide = function () {
      hideDivNode.removeClass('d-none');

      fieldNode.attr('required', true).attr('disabled', false);
    };

    const hide = function () {
      fieldNode.val('').attr('disabled', true).attr('required', false);

      hideDivNode.addClass('d-none');
    };

    if (fieldNode.val()) {
      selectNode.val(toggleOption).trigger('change');
      unhide();
    } else {
      selectNode.val(selectNode.val()).trigger('change');
      hide();
    }

    const toggle = () => {
      if (selectNode.val() === toggleOption) {
        unhide();

        setTimeout(() => {
          fieldNode.focus();
        }, 300);
      } else {
        hide();
      }
    };

    selectNode.on('change', toggle);
  },
  labelActive(mainElement) {
    mainElement.find('.form-control').each((index, element) => {
      const $field = $(element);
      const $label = $field.parent();

      if ($label.find('.label-txt').length > 0) {
        if ($field.val() !== '') $label.find('.label-txt').addClass('label-active');
        $field.focus(() => { if (!$field.prop('readonly')) $label.find('.label-txt').addClass('label-active'); });
        $field.focusout(() => { if ($field.val() === '') $label.find('.label-txt').removeClass('label-active'); });
      }
    });
  },

  findIdForCheckbox(mainElement) {
    mainElement.find('.nested-fields').each((index, element) => {
      const $field = $(element);
      $field.find('.custom-control-label').attr('for', $field.find('.boolean').attr('id'));
    });
  },

  editConfirmBtn(mainElement) {
    const self = this;

    mainElement.find('.edit-form-btn').click(function edit() {
      const $field = $(this).parent().find('.form-control');

      $field.removeAttr('readonly').removeAttr('disabled').removeClass('readonly disabled').addClass('edited');
      $(this).parent().find('.confirm-form-btn').hide();
      $field.closest('label').find('.label-txt').addClass('label-active');

      return true;
    });

    mainElement.find('.confirm-form-btn').click(function confirm() {
      const $field = $(this).parent().find('.form-control');
      const $btn = $(this);

      if (!self.fieldEmptyValidate($field)) {
        self.confirm('O campo esta vazio. Deseja realmente confirmar?', false, (result) => {
          if (result && result.value) {
            $field.addClass('confirmed');
            $btn.addClass('confirmed').parent().find('.edit-form-btn').hide();
          }
        });
      } else {
        $field.addClass('confirmed');
        $btn.addClass('confirmed').parent().find('.edit-form-btn').hide();
      }

      return true;
    });
  },

  alert(title, message, icon) {
    Swal.fire({
      title,
      text: message,
      icon,
    });
  },

  confirm(title, message, callback) {
    Swal.fire({
      title: title || 'Você tem certeza?',
      text: message || '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar',
    }).then((result) => { callback(result); });
  },

  fieldEmptyValidate(field) {
    return field.val().length !== 0;
  },

  fieldCheckValidate(form) {
    let valid = true;

    form.find('input[type=text], input[type=email], input[type=tel], input[type=number]').each((index, element) => {
      const $field = $(element);

      if (!($field.hasClass('confirmed') || $field.hasClass('edited'))) valid = false;
    });

    return valid;
  },

  addCheckIcon(element) {
    element.find('input:file').change(function check() {
      const inputFile = $(this);
      const boxUploadImage = inputFile.parent('.box-upload-image');
      boxUploadImage.find('.fa-check').remove();
      boxUploadImage.find('.filename').prepend('<i class="fa fa-check"></i>');

      if (inputFile.val().length > 0) boxUploadImage.find('.kind_cd').removeAttr('disabled');
    });
  },

  addDocumentField(mainElement, modelName, indexBaseName) {
    this.addCheckIcon(mainElement);

    let i = mainElement.find('.extra_documents input[type=file]').length;

    mainElement.find('#add').click(() => {
      const inputTemplate = `<label class="box-upload-image row">
<div class="col-lg-2"><i class="fa fa-paperclip"></i></div>
<div class="col-lg-7"><span>Documento Extra</span></div>
<input class="string optional d-none form-control" type="file" name="${modelName}[${indexBaseName}][${i}][file]"
id="${modelName}_${indexBaseName}_${i}_file">
<div class="invalid-feedback"></div>
<div class="col-lg-3 filename"></div>
<input type="hidden" name="${modelName}[${indexBaseName}][${i}][kind_cd]" value="Documento Extra"
id="${modelName}_${indexBaseName}_${i}_kind_cd" class="kind_cd" disabled>
</label>`;

      mainElement.find('form .file-upload').append(inputTemplate);
      this.addCheckIcon(mainElement);

      i += 1;
    });
  },

  addressEditConfirmBtn(modelName) {
    $(`#${modelName}_address_attributes_zip_code`).on('focusout keyup', function () {
      if ($(this).val().length === 9) $('.label-txt').addClass('label-active');
    });

    $(`#${modelName}_address_attributes_street`).parent().find('.edit-form-btn').click(() => {
      $(`#${modelName}_address_attributes_number`).removeAttr('readonly').removeAttr('disabled')
        .removeClass('readonly disabled')
        .addClass('edited');

      $(`#${modelName}_address_attributes_complement`).removeAttr('readonly').removeAttr('disabled')
        .removeClass('readonly disabled')
        .addClass('edited');
    });

    $(`#${modelName}_address_attributes_neighborhood`).parent().find('.edit-form-btn').click(() => {
      $(`#${modelName}_address_attributes_state`).removeAttr('readonly').removeAttr('disabled')
        .removeClass('readonly disabled')
        .addClass('edited');

      $(`#${modelName}_address_attributes_city`).removeAttr('readonly').removeAttr('disabled')
        .removeClass('readonly disabled')
        .addClass('edited');
    });
  },
};
