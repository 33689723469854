onLoad(($) => {
  function onReturningDateChange(visible) {
    let anyReturningDateHasContent = false;

    // Otherwise the makeover field would induce errors
    $(`.JS-returningDate${visible}`).each((_index, input) => {
      if (input.value) {
        anyReturningDateHasContent = true;
      }
    });

    if (anyReturningDateHasContent) {
      $('.JS-concessionDate').prop('disabled', true);
      $('.JS-concessionKind').attr('disabled', true);

      $('.JS-concessionDate').val('');
      $('[name="q[charge_concession_kind_cd_eq]"]').val('returning');
    } else {
      $('.JS-concessionDate').prop('disabled', false);
      $('.JS-concessionKind').attr('disabled', false);
    }
  }

  const chargeConcessionElement = {
    select: $('#JS-charge-concession-select'),
    btn: $('#JS-charge-concession-btn'),
  };

  $(document).on('change', '.JS-returningDate', () => onReturningDateChange(':visible'));
  onReturningDateChange('');

  if (!chargeConcessionElement.select.length || !chargeConcessionElement.btn.length) return;

  chargeConcessionElement.select.on('change', () => {
    if (chargeConcessionElement.btn.attr('disabled')) {
      chargeConcessionElement.btn.removeAttr('disabled');
    }
  });
});
