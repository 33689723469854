onLoad(($) => {
  const $modalCancelProtest = $('.JS-cancel-protest-modal');
  if (!$modalCancelProtest.length) return;

  const content = $modalCancelProtest.data('content');
  const $title = $('.JS-cancel-protest-modal .modal-title');
  const $subtitle = $('.JS-cancel-protest-modal .modal-subtitle');
  const $callout = $('.JS-cancel-protest-modal .modal-callout');
  const $checkboxLabel = $('.JS-cancel-protest-modal .modal-checkbox-label');
  const $checkbox = $('.JS-cancel-protest-modal .checkbox');
  const $buttonCancelProtest = $('.JS-cancel-protest-btn');
  const $buttonCancelProtested = $('.JS-cancel-protested-btn');
  const $buttonGenerateLetter = $('.JS-generate-letter-btn');
  const $buttonUnauthorizedGenerateLetter = $('.JS-unauthorized-generate-letter-btn');
  const $buttonAllowRegistryPaymentProtest = $('.JS-allow-registry-payment-protest-btn');
  const $buttons = [
    $buttonCancelProtested,
    $buttonAllowRegistryPaymentProtest,
    $buttonCancelProtest,
    $buttonGenerateLetter,
    $buttonUnauthorizedGenerateLetter,
  ];

  const contents = {
    cancel: {
      title: 'Deseja realmente cancelar a instrução de protesto?',
      subtitle: `Você está prestes a cancelar a instrução de protesto. Esta ação é irreversível e encerra a instrução 
        de protesto`,
      callout: `Ao cancelar, haverá custas cartorárias associadas à operação. Certifique-se de que há saldo suficiente 
        em sua conta para cobrir esses custos.`,
      checkboxLabel: `Desejo seguir com o encerramento da instrução de protesto e estou de acordo com as custas 
        cartorárias associadas à operação.`,
      buttonLabel: 'Cancelar protesto',
      buttons: [$buttonCancelProtest],
    },
    protested: {
      title: 'Deseja realmente cancelar a instrução de protesto?',
      subtitle: `Ao cancelar haverá custas cartorárias associadas à operação, você pode escolher entre cancelar 
        o protesto e assumir as custas ou gerar uma carta de anuência eletrônica permitindo o sacado ir ao cartório 
        realizar o pagamento das custas.`,
      buttons: [$buttonCancelProtested, $buttonGenerateLetter, $buttonUnauthorizedGenerateLetter],
    },
    cancelProtested: {
      title: 'Deseja realmente assumir as custas e cancelar a instrução de protesto?',
      subtitle: `Você está prestes a cancelar a instrução de protesto e assumir as custas cartorárias associadas à 
        operação. Essa ação é irreversível e encerra a instrução de protesto.`,
      callout: 'Certifique-se de que há saldo suficiente em sua conta para cobrir esses custos.',
      checkboxLabel: `Desejo seguir com o encerramento da instrução de protesto e estou de acordo com as custas 
        cartorárias associadas à operação.`,
      buttonLabel: 'Cancelar instrução de protesto',
      buttons: [$buttonCancelProtest],
    },
    generateLetter: {
      title: 'Deseja realmente autorizar o sacado a pagar as custas no cartório?',
      subtitle: `Você está prestes a autorizar o sacado a comparecer em cartório para pagar as despesas cartorárias. 
        Essa ação é irreversível e pode gerar custos baseados na sua tabela de preços.`,
      callout: `Certifique-se de que o sacado tenha pago o valor devido à sua empresa antes de confirmar. 
        Após 24h úteis da autorização, o sacado poderá ir ao cartório fazer o pagamento das custas.`,
      checkboxLabel: `Desejo autorizar o sacado a comparecer em cartório para pagamento das custas cartorárias 
        (Carta de Anuência Eletrônica)`,
      buttons: [$buttonAllowRegistryPaymentProtest],
    },
    unauthorizedGenerateLetter: {
      title: 'Atenção',
      subtitle: `Apenas o usuário master e o usuário operador master da conta podem gerar carta de anuência para 
        permitir o sacado pagar as custas em cartório.`,
    },
  };

  function validateCheckbox() {
    $buttons.forEach((button) => {
      if (!$(button).is(':visible')) return;
      $(button).addClass('btn-disabled').removeClass('btn-able');
      if ($checkbox.is(':visible') && $checkbox.is(':checked')) {
        $(button).removeClass('btn-disabled').addClass('btn-able');
      }
    });
  }

  function renderContent(currentContent) {
    const toggle = prop => (prop ? 'show' : 'hide');
    $buttons.forEach(button => $(button).hide());
    $checkbox.prop('checked', false);
    $title.show().text(contents[currentContent].title);
    $subtitle.show().text(contents[currentContent].subtitle);
    $buttonCancelProtest.val(contents[currentContent].buttonLabel);
    $callout[toggle(contents[currentContent].callout)]().text(contents[currentContent].callout);
    $checkboxLabel[toggle(contents[currentContent].checkboxLabel)]().text(contents[currentContent].checkboxLabel);
    $checkbox[toggle(contents[currentContent].checkboxLabel)]();
    contents[currentContent].buttons.forEach((button) => {
      $(button).show();
      if ($(button).hasClass('btn-able')) $(button).addClass('btn-disabled').removeClass('btn-able');
    });
    validateCheckbox();
  }

  $modalCancelProtest.on('hidden.bs.modal show.bs.modal', () => renderContent(content));
  $buttonCancelProtested.on('click', () => renderContent('cancelProtested'));
  $buttonGenerateLetter.on('click', () => renderContent('generateLetter'));
  $buttonUnauthorizedGenerateLetter.on('click', () => renderContent('unauthorizedGenerateLetter'));
  $checkbox.on('change', validateCheckbox);
});
