onLoad(($) => {
  const $inputsFile = $('[data-file="JS-input-file"] input[type="file"]');
  if (!$inputsFile.length) return;

  const EMPTY_VALUE = '';
  const EMPTY_CONTENT = 'Escolher arquivo para upload...';
  const $fileExisted = $('.file-existed');

  const templateHint = dataHint => `<span class="input-file-hint">${dataHint}</span>`;
  const fileHintsData = $('[data-file="JS-input-file"]').map(function () {
    if (!$(this).data('hint')) return EMPTY_VALUE;
    return $(this).data('hint');
  });

  if ($('.input-file-hint').length > 0) {
    $('.input-file-hint')[0].remove();
  }

  function showInputFileEmpty($input) {
    const condition = $fileExisted.length;
    const content = condition ? $input.dataset.content : EMPTY_CONTENT;
    const primaryClass = 'input-file-primary';
    const secondaryClass = 'input-file-secondary';

    return $($input)
      .removeClass(condition ? secondaryClass : primaryClass)
      .addClass(condition ? primaryClass : secondaryClass)
      .attr('data-content', content);
  }

  function showInputFileFill($input, fileName) {
    return $($input)
      .removeClass('input-file-secondary')
      .attr('data-content', fileName)
      .addClass('input-file-primary');
  }

  function showHintInInputsFile() {
    $('[data-file="JS-input-file"] .col-lg-4 .form-group').each(function (index) {
      $(this).append(templateHint(fileHintsData[index]));
    });
  }

  function toggleInputFile() {
    $inputsFile.each(function () {
      const files = $(this).get(0).files;

      if (!files.length) {
        return showInputFileEmpty(this);
      }
      return showInputFileFill(this, files[0].name);
    });
  }

  $inputsFile.ready(() => {
    toggleInputFile();
    showHintInInputsFile();
  });

  $inputsFile.on('change', toggleInputFile);
});
