onLoad(($) => {
  const nodes = $('.JS-query-asset');

  if (!nodes.length) return;

  const queryUrl = nodes[0].dataset.queryurl;

  turbolinksSetInterval(() => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: queryUrl,
      success(data) {
        if (data.state !== 'pending' && data.aasm_state !== 'pending' && data.state_cd !== 'pending') {
          document.location.reload();
        }
      },
      error() { document.location.reload(); },
    });
  }, 2500);
});
