import Utils from './utils';

export default {
  company(mainElement) {
    if (mainElement) {
      Utils.selectOrOther(mainElement, '.JS-other-view', '.JS-legal-nature-select', '.JS-legal-nature-other', 'Outros');

      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });
    }
  },

  companyDocument(mainElement) {
    if (mainElement) {
      Utils.addDocumentField(mainElement, 'client_company_partner', 'personal_documents_attributes');
    }
  },

  companyAddress(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });

      Utils.addressEditConfirmBtn('client_company_partner');
    }
  },

  companyAdministrator(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });
    }
  },

  individual(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });
    }
  },

  individualDocument(mainElement) {
    if (mainElement) {
      Utils.addDocumentField(mainElement, 'client_individual_partner', 'personal_documents_attributes');
    }
  },

  individualAddress(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });

      Utils.addressEditConfirmBtn('client_individual_partner');
    }
  },
};
