import applyTooltips from '../../helper/tooltip';

onLoad(($) => {
  if (!$('.JS-form-with-tooltips').length) return;

  const tooltipAlerts = {
    onboarding_phone: 'Celular que receberá o link via SMS para preenchimento do cadastro.',

  };

  applyTooltips('user', tooltipAlerts);
});
