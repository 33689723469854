onLoad(($) => {
  const nodes = $('.JS-fill-address-with-cep');

  if (!nodes.length) return;

  const $addressAutocompleteNodes = $('.JS-address-autocomplete');

  const toggleField = (prefix, state) => {
    $addressAutocompleteNodes.not(`#${prefix}_state`).prop('readonly', !state).end()
      .filter(`#${prefix}_state`);
  };

  const updateColumns = (prefix, data) => {
    $addressAutocompleteNodes.removeClass('readonly disabled').filter(`#${prefix}_street`).val(data.address).end()
      .filter(`#${prefix}_city`)
      .val(data.city)
      .end()
      .filter(`#${prefix}_state`)
      .val(data.state)
      .end()
      .filter(`#${prefix}_neighborhood`)
      .val(data.neighborhood)
      .end()
      .filter(`#${prefix}_number`)
      .val('');
  };

  const changeFocus = (prefix) => {
    const nodeToFocus = $(`#${prefix}_street`).val() ? 'number' : 'street';

    $(`#${prefix}_${nodeToFocus}`).focus();
  };

  for (let n = 0; n < nodes.length; n += 1) {
    const node = nodes[n];
    const jqueryNode = $(node);
    const tablePrefix = node.dataset.tableprefix;
    const persisted = node.dataset.persisted;
    const initialValue = jqueryNode.val();
    let lastCount = initialValue.length;
    let doingRequest = false;

    if (persisted === 'false' && jqueryNode.val().length === 0) {
      toggleField(tablePrefix, false);
    }

    jqueryNode.on('change keyup', () => {
      const value = jqueryNode.val();

      if (jqueryNode.hasClass('auto-filled')) {
        lastCount = 9;
        jqueryNode.removeClass('auto-filled');
      }

      if (value.length !== 9) {
        jqueryNode.removeClass('is-valid');
      }

      if (value.length === 9 && lastCount !== 9) {
        if (doingRequest === true) {
          return;
        }

        doingRequest = true;

        $.get(`/cep_address/${value.match(/\d/g).join('')}`)
          .done((resp) => {
            updateColumns(tablePrefix, resp.data);

            jqueryNode.addClass('is-valid');
          })
          .fail(() => {
            jqueryNode.removeClass('is-valid');
          })
          .always(() => {
            doingRequest = false;
            toggleField(tablePrefix, true);
            jqueryNode.attr('disabled', false);
            changeFocus(tablePrefix);
          });
      }

      lastCount = jqueryNode.val().length;
    });
  }
});
