onLoad(($) => {
  if (!$('.request-status').length) {
    return;
  }

  const EMPTY = '';
  const accountsState = $('#request-contract').data('account');
  const requestState = $('.request-status').data('state');
  const $send = {
    title: $('.send-state-title'),
    point: $('.send-state-point'),
    bar: $('.send-state-bar'),
  };
  const $onboardingInvite = {
    title: $('.request-onboarding-invite-title'),
    point: $('.request-onboarding-invite-point'),
    bar: $('.request-onboarding-invite-bar'),
  };
  const $analysis = {
    title: $('.request-analysis-title'),
    point: $('.request-analysis-point'),
    bar: $('.request-analysis-bar'),
  };
  const $contract = {
    title: $('.contract-title'),
    point: $('.contract-point'),
    bar: $('.contract-bar'),
  };
  const $finished = {
    title: $('.finished-state-title'),
    point: $('.finished-state-point'),
  };

  function active(point, title, bar = EMPTY) {
    if (point) { point.addClass('reg-active'); }
    if (bar) { bar.addClass('reg-active'); }
    if (title) title.addClass('reg-title-active').removeClass('reg-title-disabled');
  }

  function rejected(point, title) {
    if (point) { point.addClass('reg-rejected'); }
    if (title) { title.addClass('reg-title-rejected'); }
  }

  function updateTimeline() {
    const awayting = [
      'awaiting_validation',
      'awaiting_security_analysis',
      'awaiting_review'];

    const states = {
      pending: () => {
        active(EMPTY, $onboardingInvite.title);
        $onboardingInvite.point.addClass('reg-border-active');
      },
      analysis: () => {
        active(EMPTY, $analysis.title);
        $analysis.point.addClass('reg-border-active');
      },
      awaiting_missing_information: () => {
        rejected(EMPTY, $analysis.title);
        $analysis.point.addClass('reg-border-rejected');
        $analysis.title.removeClass('reg-title-disabled');
      },
      approved: () => {
        active(
          $analysis.point,
          $analysis.title,
          $analysis.bar,
        );
        $contract.point.addClass('reg-border-active');
        if (accountsState) {
          active($contract.point, $contract.title);
        }
      },
      canceled: () => {
        rejected(
          $send.point,
          $send.title,
        );
        $send.point.removeClass('reg-active');
        $send.bar.removeClass('reg-active');
        $onboardingInvite.title.removeClass('reg-title-active').addClass('reg-title-disabled');
        $onboardingInvite.point.removeClass('reg-active');
        $onboardingInvite.bar.removeClass('reg-active');
      },
      finished: () => {
        active(
          $analysis.point,
          $analysis.title,
          $analysis.bar,
        );
        active($contract.point, $contract.title, $contract.bar);
        active($finished.point, $finished.title);
      },
      rejected: () => {
        rejected(
          $analysis.point,
          $analysis.title,
        );
        $analysis.title.removeClass('reg-title-disabled');
      },
      default: () => {
        active($send.point, $send.title, $send.bar);
      },
    };

    if (requestState !== 'pending') {
      $('#request-analysis .subtitle .disabled').removeClass('disabled');
      active($onboardingInvite.point, $onboardingInvite.title, $onboardingInvite.bar);
    }
    if (requestState && awayting.includes(requestState)) {
      return states.analysis();
    }
    return requestState ? states[requestState]() : states.default();
  }

  updateTimeline();
});
