import GeneralShimmer from './helper/generalShimmer';

onLoad(($) => {
  if (!$('.ds-grafeno-statement-entries').length) return;

  const $entryAtBetweenSelect = $('.JS-entry-at-between');
  const $statementEntriesDateForm = $('.JS-statement-entries-date');
  const $initialDate = $('.JS-initial-date');
  const $finalDate = $('.JS-final-date');
  const entryAtBetweenValue = $entryAtBetweenSelect.last().children('option:selected').val();
  const balanceValue = {
    balance: null,
    unavailable_balance: null,
  };

  function verifyEntryAtBetweenValue(optionValue) {
    if (optionValue === 'custom_range') {
      $statementEntriesDateForm.removeClass('d-none').addClass('d-flex');
    } else {
      $initialDate.val('').change();
      $finalDate.val('').change();
      $statementEntriesDateForm.addClass('d-none').removeClass('d-flex');
    }
  }

  function verifyDateRequired(value, input) {
    if (value) {
      input.prop('required', true);
    } else {
      input.prop('required', false);
    }
  }

  function formatBalance(balance) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(balance);
  }

  function updateBalanceCard(data, type) {
    balanceValue[type] = data[type];
    const html = type === 'balance' ? '.balance-total-value' : '.balance-unavailable-value';
    $(html).html(`<strong>${formatBalance(balanceValue[type])}</strong>`);
  }

  function getBalanceValue(type) {
    if (balanceValue[type] === null) {
      $.ajax({
        url: `/grafeno_statement_entries/${type}`,
        type: 'get',
        dataType: 'json',
        data: { },
      }).done(data => updateBalanceCard(data, type))
        .fail(() => handleError());
    } else {
      updateBalanceCard(balanceValue, type);
    }
  }

  $('.sidebar-menu').find('a[href="/grafeno_statement_entries"').addClass('active');

  $('.JS-balance-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-balance-total-icon.JS-toggle-balance'));

  $('.JS-unavailable-total-value')
    .each((_, el) => new GeneralShimmer($(el), '.toggle-balance-total-icon.JS-toggle-unavailable'));

  $('.JS-toggle-balance').on('click', () => {
    getBalanceValue('balance');
  });

  $('.JS-toggle-unavailable').on('click', () => {
    getBalanceValue('unavailable_balance');
  });

  $entryAtBetweenSelect.on('change', () => {
    const optionValue = $entryAtBetweenSelect.last().children('option:selected').val();
    verifyEntryAtBetweenValue(optionValue);
  });

  $initialDate.on('change', () => {
    const intialValue = $initialDate.last().val();
    verifyDateRequired(intialValue, $finalDate);
  });

  $finalDate.on('change', () => {
    const finalValue = $finalDate.last().val();
    verifyDateRequired(finalValue, $initialDate);
  });

  verifyEntryAtBetweenValue(entryAtBetweenValue);
});
