export default class CodeConfirmation {
  constructor({ scope, kind, sendTo }) {
    this.scope = scope;
    this.sendTo = sendTo;
    this.kind = kind;
    this.csrfToken = $('meta[name="csrf-token"]').attr('content');
  }

  send() {
    return this.request({
      url: '/code_confirmations/create',
      data: {
        kind: this.kind,
        scope: this.scope,
        value: this.sendTo,
      },
    });
  }

  confirm(code) {
    return this.request({
      url: '/code_confirmations/confirm',
      data: {
        code,
        scope: this.scope,
        value: this.sendTo,
      },
    });
  }

  request({ url, data }) {
    return new Promise((success, error) => {
      $.ajax({
        url,
        data,
        success,
        error,
        type: 'POST',
        dataType: 'json',
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', this.csrfToken);
        },
      });
    });
  }
}
