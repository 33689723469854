onLoad(($) => {
  const params = new URLSearchParams(window.location.search);

  if (!$('.JS-pixKeyContent').length) {
    return;
  }

  function alertBox(title, message) {
    Swal.fire({
      title,
      text: message,
      icon: 'error',
    });
  }

  function toggleAlert(selected) {
    const todosOsAlerts = $('.JS-pixKeyAlert');
    todosOsAlerts.hide().filter(`#${selected}Alert`).css('display', 'flex');
  }

  function toggleKeyField(isShown) {
    const keySection = $('.JS-pixKeySection');
    if (isShown) {
      keySection.show();
    } else {
      keySection.hide();
    }
  }

  function toggleKey(selected) {
    const key = $('#JS-pixKeyInput');
    key.prop('readonly', selected !== 'phone' && selected !== 'email').val('');
    toggleKeyField(selected !== 'evp');
    if (selected === 'phone') {
      // máscara específica para telefone pix
      key.mask('+55 (00) 00000-0000');
    } else {
      key.unmask();
      key.val(selected !== 'evp' ? key.data(selected) : '');
    }
  }

  function loadConfirmationForm() {
    const $continueButton = $('#JS-continue_btn');
    const keySection = $('#JS-pixKeySection');
    const pixKey = params.get('key') || $('#JS-pixKeyInput').val();
    const requestKey = params.get('pix_key_request_key') || $('#JS-pixKeyInput').val();

    $continueButton.toggleClass('disabled', false);
    $continueButton.hide();
    keySection.hide();

    $('#JS-pix_validation_msg').html(`Enviamos um
      ${$('#pixType :selected').attr('value') === 'phone' ? ' SMS' : 'e-mail'}
      com um código de confirmação para
      <b>${pixKey}.</b>
      Preencha o campo abaixo com este código para prosseguir.`);

    $('#JS-pixKeyInput').val(requestKey);
    $('#JS-pixConfirmKeySection').show();
    $('.JS-create_pix_btn').prop('disabled', true);
    $('.JS-create_pix_btn').show();
  }

  if (params.get('pix_key_request_key')) loadConfirmationForm();

  $('#pixType').on('change', () => {
    const pixKeyContent = $('.JS-pixKeyContent');
    const selected = $('#pixType :selected').attr('value');
    const createButton = $('.JS-create_pix_btn');
    const continueButton = $('#JS-continue_btn');

    if (selected) {
      if ($('#pixType').data('backend') === 'bmp' && (selected === 'email' || selected === 'phone')) {
        createButton.hide();
        continueButton.show();
      } else {
        createButton.show();
        createButton.prop('disabled', false);
        continueButton.hide();
      }

      toggleAlert(selected);
      toggleKey(selected);
      pixKeyContent.show();
    } else {
      pixKeyContent.hide();
      createButton.hide();
      continueButton.hide();
    }
  });

  $('#JS-continue_btn').on('click', (e) => {
    if ($('#pixType').data('backend') !== 'bmp') return;

    const kind = $('#pixType :selected').val();
    const $continueButton = $('#JS-continue_btn');
    if (kind === 'email' || kind === 'phone') {
      e.preventDefault();

      if ($('#JS-pixKeyInput').val() === '') {
        alertBox('Chave não encontrada',
          'Informe a chave no campo indicado ou solicite a atualização de seus dados cadastrais');
      }

      if ($('#JS-pixTherms').is(':checked') === false) {
        alertBox('Termos', 'Aceite os termos de divulgação dos dados para continuar');
      }


      if ($('#JS-pixTherms').is(':checked') && $('#JS-pixKeyInput').val()) {
        const value = $('#JS-pixKeyInput').unmask().val();
        const codeCreateUrl = $('#pixType').data('createcodeurl');
        $continueButton.toggleClass('disabled', true);
        // executar ajax para submeter a chave.
        // mostrar o formulário de confirmação

        $.ajax({
          type: 'POST',
          dataType: 'json',
          url: codeCreateUrl,
          beforeSend: (xhr) => {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
          data: { scope: 'pix_key', kind, value },
          success() {
            loadConfirmationForm();
          },
          error() {
            alertBox('Erro', 'Não foi possível enviar a chave de confirmação');
            toggleKey($('#pixType :selected').attr('value'));
            $continueButton.toggleClass('disabled', false);
          },
        });
      }
    }
  });

  function clearPixCode() {
    $('.pix-code').val('');
  }

  $('form').submit(function () {
    const btn = $(this).find("button[type='submit']");
    btn.prop('disabled', true);
    setTimeout(() => { btn.prop('disabled', false); }, 20000);
  });

  $('.pix-code').on('keyup', () => {
    const code = $('.pix-code').val();

    if (code.length < 6) {
      return;
    }
    const $pixCode = $('.pix-code');
    const codeConfirmUrl = $('#pixType').data('confirmcodeurl');
    const value = $('#JS-pixKeyInput').val();

    $pixCode.attr('disabled', true);

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: codeConfirmUrl,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data: { scope: 'pix_key', value, code },
      success() {
        if (params.get('pix_key_request_key')) {
          window.location.href = $('#pixType').data('createkeyurl');
        } else {
          $pixCode.attr('disabled', true);
          $('.JS-create_pix_btn').prop('disabled', false);
          $('#JS-clean_btn').hide();
        }
      },
      error() {
        $pixCode.attr('disabled', false);
        $('.JS-create_pix_btn').prop('disabled', true);
        $('.JS-create_pix_btn').show();
        alertBox('Erro', 'Código de confirmação inválida');
        clearPixCode();
      },
    });
  });
});
