import Utils from './utils';

export default {
  firstAccess(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;
        e.preventDefault();

        const $form = $(this);

        valid = true;
        $('#onboarding-grantor').find('header, main, footer').hide();
        $('#onboarding-grantor .loading').removeClass('d-none');

        setTimeout(() => { $form.submit(); }, 3000);

        return valid;
      });
    }
  },

  bankAccount(mainElement) {
    if (mainElement) {
      mainElement.find('.form-control').each((index, element) => {
        const $field = $(element);
        const $formGroup = $field.parent('div');

        if ($field.val().length > 0 && !$field.hasClass('not-disabled')) {
          $formGroup.removeClass('d-none');
          $field.removeAttr('disabled').attr('required', true);
        }
      });

      mainElement.find('#add').click(() => {
        const $formGroup = mainElement.find('.addable-bank-account.d-none').first();
        $formGroup.removeClass('d-none').find('.form-control').removeAttr('disabled').attr('required', true);
      });

      mainElement.find('.addable-bank-account .remove').click(function () {
        const $formGroup = $(this).closest('.addable-bank-account');
        $formGroup.addClass('d-none').find('.form-control').val('').attr('disabled', true)
          .removeAttr('required');
      });
    }
  },

  company(mainElement) {
    if (mainElement) {
      Utils.selectOrOther(mainElement, '.JS-other-view', '.JS-legal-nature-select', '.JS-legal-nature-other', 'Outros');

      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });
    }
  },

  document(mainElement) {
    if (mainElement.hasClass('document')) {
      Utils.addDocumentField(mainElement, 'client', 'client_documents_attributes');
    }
  },

  address(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });

      Utils.addressEditConfirmBtn('client');
    }
  },

  participationSociety(mainElement) {
    if (mainElement) {
      const calculateSharedPercentage = () => {
        let total = 0.0;

        mainElement.find('.share-percent').each(function () {
          const value = parseFloat($(this).val());
          total += value || 0;
        });

        total = total.toFixed(2);

        if (total >= 0 && total <= 100) {
          mainElement.find('#total-shared-percentage').val(total).closest('.share-percent-column')
            .find('.percent-bar')
            .addClass('gradient')
            .css('width', total);
        }

        return total;
      };

      const calculatePercentBar = (field) => {
        const $sharePercentColumn = field.closest('.share-percent-column');
        const percent = parseFloat(field.val()).toFixed(2);

        if (percent > 0) {
          $sharePercentColumn.find('.percent-bar').addClass('gradient').css('width', percent);
        }

        return percent;
      };

      const validateSharedPercentTotal = (total) => {
        if (total < 0 || total > 100) {
          Utils.alert('Limite de Participação', 'Limite total de participação atingido!', 'warning');
          return false;
        }

        return true;
      };

      mainElement.find('.share-percent').each((index, element) => { calculatePercentBar($(element)); });
      calculateSharedPercentage();

      mainElement.find('.share-percent').on('change', function () {
        const value = $(this).val().replace(',', '.');

        if (value < 0 || value > 100) {
          Utils.alert('Limite de Participação', 'O valor informado esta fora dos limites permitidos!', 'warning');
          $(this).val(0);
          return false;
        }
        $(this).val(value);

        calculatePercentBar($(this));

        validateSharedPercentTotal(calculateSharedPercentage());

        return true;
      });

      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        if (!validateSharedPercentTotal(calculateSharedPercentage())) return false;

        valid = true;
        return $form.submit();
      });
    }
  },

  legalRepresentation(mainElement) {
    if (mainElement) {
      Utils.addCheckIcon(mainElement);
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        valid = true;
        return $form.submit();
      });
    }
  },

  administrator(mainElement) {
    if (mainElement) {
      let valid = false;

      mainElement.find('form').on('submit', function validate(e) {
        if (valid) return true;

        e.preventDefault();

        const $form = $(this);

        const message = 'Você confirma que todos os sócios da empresa estão declarados conforme seu Contrato Social?'
                        + ' Se você é um empresário individual, confirma que apenas você está cadastrado?';

        Utils.confirm('Confirmar todos os administradores?', message, (result) => {
          if (result && result.value) {
            valid = true;
            $form.submit();
          }
        });
        return true;
      });
    }
  },
};
