
const TableValueHelper = () => {
  const handleValueKinds = (kind) => {
    switch (kind) {
      case 'percentage':
        return '(%)';
      case 'fixed_value':
        return '(R$)';
      default:
        return '';
    }
  };


  function sanitizeEmptyValues(obj) {
    const newObj = obj;
    Object.keys(newObj).forEach((key) => {
      if (newObj[key] === '' || newObj[key] === null || newObj[key] === undefined) {
        newObj[key] = '-';
      }
    });

    return newObj;
  }

  return { handleValueKinds, sanitizeEmptyValues };
};

export default TableValueHelper;
