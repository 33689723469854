import GeneralShimmer from './helper/generalShimmer';

onLoad(($) => {
  const $nodes = $('.JS-find-payer-info');
  if (!$nodes.length) return;
  $('.toggle-payer-table-shimmer').each((_, el) => new GeneralShimmer($(el), '.toggle-payer-table-container'));

  const $documentNumberField = $('#charge_document_number');
  const $payerAccordion = $('#payerAccordion');
  const $payerEditLink = $('#payerEditLink');

  function setDataPayer(payload) {
    const $payerValues = $('.charge-table-row').find('span');
    const payer = payload.data[0].attributes;

    $payerValues.filter('#payerName').text(payer.name);
    $payerValues.filter('#payerDocumentNumber').text(payer['formatted-document-number']);
    $payerValues.filter('#payerEmail').text(payer.email);
    $payerValues.filter('#payerPhone').text(payer.phone).addClass('phone');
    $payerValues.filter('#payerAddressStreet').text(payer.address.street);
    $payerValues.filter('#payerAddresssNumber').text(payer.address.number);
    $payerValues.filter('#payerAddressComplement').text(payer.address.complement);
    $payerValues.filter('#payerAddressNeighborhood').text(payer.address.neighborhood);
    $payerValues.filter('#payerAddressCity').text(payer.address.city);
    $payerValues.filter('#payerAddressState').text(payer.address.state);
    $payerValues.filter('#payerAddressZipCode').text(payer.address['zip-code']);
    $payerValues.filter('#documentNumberField').val(payer.formatted_document_number);
    $payerEditLink.prop('href', `/payers/${payer.uuid}/edit?came_from_charge=true`);
    $payerEditLink.removeClass('d-none');
  }

  function setChargeGroup(data) {
    const $chargeGroup = $('#charge_charge_group');

    $chargeGroup.val(data.charge_group_id);
    $chargeGroup.change();
  }

  function setMessagingRule(data) {
    const $messagingRule = $('#charge_messaging_rule');

    $messagingRule.val(data.messaging_rule_id);
    $messagingRule.change();
  }

  function handlePayerSuccess(data) {
    setDataPayer(data);
    setChargeGroup(data);
    setMessagingRule(data);
  }

  function handlePayerError() {
    $payerAccordion.addClass('d-none');
    $payerEditLink.addClass('d-none');
    $payerEditLink.prop('href', '#');
  }

  function populateForm(data) {
    const chargeGroup = data.charge_group;

    if (chargeGroup.discount_until) {
      const [year, month, day] = chargeGroup.discount_until.split('-');
      $('#charge_discount_until').val(`${day}/${month}/${year}`);
    }
    $('#charge_discount').val(chargeGroup.discount_value);
    $('#charge_discount_type_cd').val(chargeGroup.discount_type_cd);
    if (chargeGroup.applicable_fine) {
      $('#charge_formatted_applicable_fine').val(chargeGroup.applicable_fine * 100);
    }
    $('#charge_interest_type_cd').val(chargeGroup.interest_type_cd);
    $('#charge_monthly_interest').val(chargeGroup.monthly_interest);
    $('#charge_days_write_off_after_due_date').val(chargeGroup.days_write_off_after_due_date);
    $('#charge_observation').val(chargeGroup.observation);
    $('#charge_description').val(chargeGroup.description);
  }

  const isValidDocumentLength = document => document.length === 11 || document.length === 14;

  const payerAnalysis = () => {
    const newPayer = $documentNumberField.val();
    const editingChargePayer = $documentNumberField.data('payer').toString();
    const canSearchPayer = isValidDocumentLength(newPayer) || isValidDocumentLength(editingChargePayer);
    return { canSearchPayer, documentToUse: newPayer || editingChargePayer };
  };

  function find(isEdit = false) {
    const documentAnalysis = payerAnalysis();
    if (documentAnalysis.canSearchPayer) {
      $.ajax({
        url: '/payers/search',
        type: 'get',
        dataType: 'json',
        data: { document_number: documentAnalysis.documentToUse },
        success: (data) => {
          handlePayerSuccess(data);
          if (isEdit && data.charge_group) populateForm(data);
        },
        error: handlePayerError,
      });
    }
  }

  find();

  $documentNumberField.on('change', () => {
    const isEdit = true;
    find(isEdit);
  });
});
