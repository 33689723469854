import { urlQueryParamsToObject } from '../helper/url';

onLoad(($) => {
  const $select = $('.JS-select-date');
  if (!$select.length) return;

  const $inputsDate = $('.JS-input-date');
  const url = window.location.href;
  const params = urlQueryParamsToObject(url);

  const handleSelect = (value) => {
    $inputsDate.each(function () {
      const $element = $(this);
      if (!value) {
        $element.find('input').attr('disabled', 'disabled').val('');
        return;
      }
      const id = $element.find('input').attr('id');
      if (!id.includes(value)) {
        $element.addClass('d-none');
        $element.find('input').val('');
        return;
      }
      $element.removeClass('d-none');
      $element.find('input').removeAttr('disabled');
    });
  };

  if (params && params.date_type) {
    $select.val(params.date_type);
    handleSelect(params.date_type);
  }

  $select.on('change', function () {
    handleSelect($(this).val());
  });
});
