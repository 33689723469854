import CurrencyHelper from '../helper/currencyHelper';
import TransferRequestHelper from '../helper/TransferRequestHelper';

onLoad(($) => {
  if (!$('.ds-transfer-requests-new').length) return;

  const currencyHelper = new CurrencyHelper();
  const transferRequestHelper = new TransferRequestHelper();

  const $beneficiarySelect = $('#transfer_request_beneficiary_id');
  const $confirmTransferBtn = $('.JS-confirm-transfer-btn');
  const $clientPurposeSelect = $('.client-purpose-select');
  const $finalizeTransferContainer = $('.JS-finalize-transfer');
  const $transferRequestValue = $('#transfer_request_formatted_value');

  function verifyInputs() {
    if ($transferRequestValue.val().length && $clientPurposeSelect.val().length) {
      $confirmTransferBtn.removeClass('btn-disabled');
    }
  }

  $clientPurposeSelect.on('change', () => {
    if (!$clientPurposeSelect.val().length) {
      $confirmTransferBtn.addClass('btn-disabled');
      return;
    }
    verifyInputs();
  });

  $transferRequestValue.on('change input', (event) => {
    const value = currencyHelper.unformat(event.target.value);
    if (Number.isNaN(value) || value === 0) {
      $confirmTransferBtn.addClass('btn-disabled');
      return;
    }
    verifyInputs();
  });

  $confirmTransferBtn.on('click', () => {
    $confirmTransferBtn.addClass('btn-disabled');
  });

  transferRequestHelper.toggleFinalizeContainer($beneficiarySelect.val() !== null, $finalizeTransferContainer);
});
