export default class MultiselectCheckbox {
  constructor($parentCheckbox, { actionButtonsClass = 'btn-select-all-action', updateState = function () {} }) {
    this.$actionButtons = $(`.${actionButtonsClass}`);
    this.$parentCheckbox = $parentCheckbox;
    this.updateState = updateState;

    const checkboxRootId = this.$parentCheckbox.attr('id');
    this.$itemCheckboxes = $(`.${checkboxRootId}-checkbox`).filter('input[type=checkbox]').filter('input:enabled');
    this.totalItemCheckboxes = this.$itemCheckboxes.length;
    this.totalItems = this.$parentCheckbox.data('registred-size');

    this.$parentCheckbox.on('change', e => this.toggleChildCheckboxesStatus(e));

    this.$itemCheckboxes.add(this.$parentCheckbox).on('change', () => {
      this.delegateUpdateState();

      this.toggleActionButtonsVisibility();

      this.toggleParentCheckboxStatus();
    });
  }

  delegateUpdateState() {
    this.updateState(this);
  }

  getCheckedItems() {
    return this.$itemCheckboxes.filter(':checked');
  }

  checkedItemsQuantity() {
    return this.getCheckedItems().length;
  }

  hasCheckedItems() {
    return this.getCheckedItems().length;
  }

  allItemsChecked() {
    return this.checkedItemsQuantity() === this.totalItemCheckboxes;
  }

  toggleChildCheckboxesStatus() {
    this.$itemCheckboxes.prop('checked', this.$parentCheckbox.is(':checked'));
  }

  toggleParentCheckboxStatus() {
    this.$parentCheckbox.prop('checked', this.allItemsChecked()).trigger('update-state');
  }

  toggleActionButtonsVisibility() {
    this.$actionButtons.toggleClass('d-none', !this.hasCheckedItems());
  }

  getSelectedItemsText(checkedItemsQuantity = this.checkedItemsQuantity()) {
    const checkedItemsText = `${checkedItemsQuantity}/${this.totalItems}`;
    return `Total de itens selecionados: ${checkedItemsText}`;
  }
}
