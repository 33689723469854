onLoad(($) => {
  if (!$('#JS-horizontal-timeline').length) {
    return;
  }
  // Globals
  const $timeline = $('#JS-horizontal-timeline');
  const $dueIcon = $('#due_icon');
  const $finalPoint = $('.point-end');
  const $startPoint = $('.point-1');
  const points = [];
  const immediatePoints = [];
  const immediateOnStart = [];
  let lastId = 0;

  function createTypeMap(types) {
    const map = new Map();
    const elements = types.substring(1, types.length - 1).split('],');
    elements.forEach((e) => {
      if (e) {
        const itens = e.replace(/&quot;/g, '').replace('[', '').replace(']', '').split(',');
        map.set(itens[1].trim(), itens[0].trim());
      }
    });
    return map;
  }

  const comFormats = createTypeMap(comFormatsString);
  const kinds = createTypeMap(kindsString);
  const triggers = createTypeMap(triggerString);

  function getTriggerTime(due) {
    if (due.includes('antes')) {
      return 'before';
    }
    if (due.includes('no dia')) {
      return 'due_day';
    }
    if (due.includes('após')) {
      return 'after';
    }

    return 'immediately';
  }

  function setDueIconPosition() {
    let isIconSet = false;
    let position = 0;

    for (let i = 0; i < points.length; i += 1) {
      if (points[i].due && points[i].due !== 'immediately') {
        if (points[i].due === 'before') {
          position = (100 / (points.length + 1) * (i + 1));
        }
        if (points[i].due === 'due_day') {
          const index = i === 0 ? 1 : i;
          position = (100 / (points.length + 1) * (isIconSet ? i + 1 : index));
        }
        if (points[i].due === 'after') {
          if (!points[i - 1] || !points[i - 1].due) {
            position = (100 / (points.length + 1) * (i === 0 ? 1 : i)) - 10;
          }
        }
        isIconSet = true;
      }
    }
    if (!isIconSet) {
      position = 47;
    }
    $dueIcon.css('left', `${position < 10 ? 10 : position}%`);
  }

  function setPointsPosition() {
    const size = 100 / (points.length + 1);

    for (let i = 0; i < points.length; i += 1) {
      const width = size * (i + 1);
      const $point = points[i].point;
      const due = points[i].due;

      $point.css('left', `${width}%`);
      $point.children().toggleClass('tooltip-right', width > 50);
      $point.removeClass('before-due after-due');
      if (due) {
        if (due === 'before') {
          $point.addClass('before-due');
        } else {
          $point.addClass('after-due');
        }
      }
    }
    setDueIconPosition();
  }

  function removePoint(index) {
    points[index].point.remove();
    points.splice(index, 1);
    setPointsPosition();
  }

  function clearPoints() {
    points.forEach((point, index) => {
      if (!$(`#${point.id}`).length) {
        removePoint(index);
      }
    });
  }

  function isImmediateOnStart($point) {
    return $point && $point.find('span').html() === comFormats.get('none');
  }

  function initTimeline() {
    const forms = $('#events').find('.nested-fields');
    const existingPoints = $timeline.find('.point-position');
    for (let i = 0; i < forms.length; i += 1) {
      $(forms[i]).attr('id', i);
      const $point = $(existingPoints[i]);
      const due = $point.find('#due').text();
      const pointObject = {
        point: $point,
        id: i,
        due: getTriggerTime(due),
        days: parseInt(due.replace(/^\D+/g, ''), 10),
      };
      if ($point.css('display') === 'none') {
        if (isImmediateOnStart($point)) {
          immediateOnStart.push(pointObject);
        } else {
          immediatePoints.push(pointObject);
        }
      } else {
        points.push(pointObject);
      }
    }
    lastId = forms.length;
    setPointsPosition();
    setDueIconPosition();
  }

  function createPoint() {
    const $point = $('<div/>', {
      class: 'timeline-point point-position',
    });

    $point.append($(`<div class="timeline-tooltip">
    <div class="inner">
      <div>
        <b id="due"></b>
      </div>
        <div class="inner-text">
          <div>
            <b>Formato de comunicação:</b>  <span></span>
          </div>
          <div id="type">
            <b>Tipo:</b>
          </div>
        </div>
    </div>
  </div>`));
    $timeline.append($point);
    points.push({
      point: $point,
      id: lastId,
    });
    lastId += 1;
    setPointsPosition();
  }

  initTimeline();

  // manage tooltip
  $('.timeline-bar').on('mouseenter', 'div.timeline-point', function change() {
    $(this).find('div').css('bottom', '30px').fadeIn();
  })
    .on('mouseleave', 'div.timeline-point', function change() {
      $(this).find('div').fadeOut();
    });

  $('#events').bind('DOMSubtreeModified', (e) => {
    if ($(e.target).attr('id') === 'events') {
      clearPoints();
      const forms = $('#events').find('.nested-fields:visible');
      if (forms.length > (points.length + immediatePoints.length + immediateOnStart.length)) {
        $(forms[forms.length - 1]).attr('id', lastId);
        createPoint();
      }
    }
  });

  function getPoint(id) {
    for (let i = 0; i < points.length; i += 1) {
      if (points[i].id === id) {
        return points[i];
      }
    }
    for (let i = 0; i < immediatePoints.length; i += 1) {
      if (immediatePoints[i].id === id) {
        return immediatePoints[i];
      }
    }
    for (let i = 0; i < immediateOnStart.length; i += 1) {
      if (immediateOnStart[i].id === id) {
        return immediateOnStart[i];
      }
    }
    return null;
  }

  function updateFinalPoint() {
    if (immediatePoints.length > 0) {
      $finalPoint.addClass('after-due');
      $finalPoint.html($(`<div class="timeline-tooltip tooltip-right">
        <div class="inner">
          <div>
            <b id="due">Envio na finalização</b>
          </div>
            <div class="inner-text">
            </div>
        </div>
      </div>`));
      const $tooltipText = $finalPoint.find('.inner-text');
      immediatePoints.forEach((pointObject) => {
        $tooltipText.append(pointObject.point.find('.inner-text').html());
      });
    } else {
      $finalPoint.removeClass('after-due');
      $finalPoint.empty();
    }
  }

  function updateStarPoint() {
    if (immediateOnStart.length > 0) {
      $startPoint.addClass('before-due');
      $startPoint.html($(`<div class="timeline-tooltip tooltip-left">
        <div class="inner">
          <div>
            <b id="due">Envio imediato</b>
          </div>
            <div class="inner-text">
            </div>
        </div>
      </div>`));
      const $tooltipText = $startPoint.find('.inner-text');
      immediateOnStart.forEach((pointObject) => {
        $tooltipText.append(pointObject.point.find('.inner-text').html());
      });
    } else {
      $startPoint.removeClass('before-due');
      $startPoint.empty();
    }
  }

  function destroyImmediatePoint(id) {
    let pointObject;

    immediatePoints.forEach((point, index) => {
      if (point.id === id) pointObject = immediatePoints.splice(index, 1)[0];
    });
    if (!pointObject) {
      immediateOnStart.forEach((point, index) => {
        if (point.id === id) pointObject = immediateOnStart.splice(index, 1)[0];
      });
    }

    pointObject.point.remove();
    updateFinalPoint();
    updateStarPoint();
  }

  $('#events').on('click', 'a.remove_fields', function removeFields() {
    const id = parseInt($(this).closest('.nested-fields').attr('id'), 10);
    const point = getPoint(id);

    if (!point) return;

    if (point.due && point.due === 'immediately') {
      queueMicrotask(() => destroyImmediatePoint(id));
      return;
    }

    for (let i = 0; i < points.length; i += 1) {
      if (points[i].id === id) {
        removePoint(i);
        return;
      }
    }
  });

  function setCommunicationFormat(value, $point) {
    $point.find('span').html(comFormats.get(value));
  }

  function setKindFormat(value, $point) {
    $point.find('#type').html(`<b>Tipo:</b> ${kinds.get(value)}`);
  }

  function sortPoints() {
    points.sort((a, b) => {
      if (!a.due && !b.due) {
        return 0;
      }
      if (!a.due && !b.due) {
        return !a.due ? -1 : 1;
      }
      if (a.due === 'immediately' || b.due === 'immediately') {
        return a.due === 'immediately' ? 1 : -1;
      }
      if (a.due === 'due_day' || b.due === 'due_day') {
        if (a.due === 'before' || b.due === 'before') {
          return a.due === 'due_day' ? 1 : -1;
        }
        return a.due === 'due_day' ? -1 : 1;
      }
      const daysA = a.due === 'before' ? a.days * (-1) : a.days;
      const daysB = b.due === 'before' ? b.days * (-1) : b.days;

      return daysA - daysB;
    });
    setPointsPosition();
  }

  function setImmediatePoint(id) {
    let pointObject;

    points.forEach((point, index) => {
      if (point.id === id) pointObject = points.splice(index, 1)[0];
    });
    pointObject.point.hide();
    if (!isImmediateOnStart(pointObject.point)) {
      immediatePoints.push(pointObject);
      updateFinalPoint();
    } else {
      immediateOnStart.push(pointObject);
      updateStarPoint();
    }
  }

  function restoreImmediatePoint(id) {
    let pointObject;

    immediatePoints.forEach((point, index) => {
      if (point.id === id) pointObject = immediatePoints.splice(index, 1)[0];
    });
    if (!pointObject) {
      immediateOnStart.forEach((point, index) => {
        if (point.id === id) pointObject = immediateOnStart.splice(index, 1)[0];
      });
    }
    points.push(pointObject);
    pointObject.point.show();
    updateFinalPoint();
    updateStarPoint();
  }

  function setTriggerTimeFormat(value, id) {
    const pointObject = getPoint(id);
    if (!pointObject) return;
    const previousValue = pointObject.due;
    pointObject.due = value;
    pointObject.point.find('#due').html(triggers.get(value));
    if (previousValue === 'immediately') restoreImmediatePoint(id);
    if (value === 'due_day' || value === 'immediately') {
      if (value === 'immediately') setImmediatePoint(id);
      sortPoints();
    }
    setDueIconPosition();
  }

  function setDaysFormat(value, $point) {
    const $child = $point.find('#due');

    if ($child.text()) {
      let text = $child.text();
      const position = text.indexOf(' ');
      const textIntro = text.slice(0, position);
      const textOutro = text.slice(position);
      text = text.includes('dia(s)') ? `${textIntro} ${value} ${textOutro.replace(/[0-9]/g, '')}`
        : `${textIntro} ${value} dia(s) ${textOutro}`;
      $child.html(text);
      sortPoints();
    }
  }

  function addPointAttr(type, value, id) {
    const pointObject = getPoint(id);

    if (!pointObject) return;

    switch (type) {
      case 'communication-format':
        setCommunicationFormat(value, pointObject.point);
        break;
      case 'kind':
        setKindFormat(value, pointObject.point);
        break;
      case 'trigger-time':
        setTriggerTimeFormat(value, id);
        break;
      case 'days-abs':
        pointObject.days = parseInt(value, 10);
        setDaysFormat(value, pointObject.point);
        break;
      default:
    }
  }

  $('#events').on('change', 'div.nested-fields', 'input', (e) => {
    const $element = $(e.target);
    const type = $element.attr('class').substring($element.attr('class').indexOf('JS-') + 3);

    addPointAttr(type, $element.val(), parseInt($element.closest('.nested-fields').attr('id'), 10));
  });
});
