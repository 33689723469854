import detectPartner from './detect_partner';

const barcodeCardBillSize = 36;
const barcodeMinSize = 44;
const barcodeComumSize = 47;
const barcodeTaxSize = 48;
const MAX_BARCODES = 20;
const MAX_BARCODES_TEXT = 'vinte';
const MIN_BARCODES = 1;
const MIN_BARCODES_TEXT = 'um';

function isTribute(barcode) {
  return barcode[0] === '8';
}

function validateBarcodeLength(barcode) {
  const barcodeSize = isTribute(barcode) ? barcodeTaxSize : barcodeComumSize;

  const validLengths = [barcodeSize, barcodeMinSize, barcodeCardBillSize];
  return validLengths.includes(barcode.length);
}

function isHelperKeyPressed(event) {
  return ['Control', 'Meta', 'Shift', 'Alt'].includes(event.key);
}

class Validator {
  constructor(inputElement, outputElement, errorHint, btnConfirm) {
    this.inputElement = inputElement;
    this.outputElement = outputElement;
    this.errorHint = errorHint;
    this.btnConfirm = btnConfirm;
    this.warnings = new Map();
  }

  init() {
    ['change', 'keyup', 'paste'].forEach((eventType) => {
      this.inputElement.addEventListener(eventType, e => this.validateInput(e));
    });
  }

  validateInput(event) {
    if (isHelperKeyPressed(event)) {
      return;
    }

    this.splitBarcodes();

    const partners = new Set(this.barcodes.map(detectPartner));

    const maxBarcodeText = `Para boletos comuns, insira até 
                            ${MAX_BARCODES} (${MAX_BARCODES_TEXT}) boletos por solicitação.`;
    const limitText = 'Você atingiu o limite de boletos por solicitação.';

    if (partners.has('fiducia') && this.barcodes.length > MAX_BARCODES) {
      this.addWarning(
        maxBarcodeText,
        'tributePartnerAndLength',
        'Pagamentos de Tributos e Convênios só podem ser submetidos individualmente',
      );
      this.deleteWarning('tributePartner');
      this.deleteWarning('barcodeLength');
    } else {
      this.validate(
        partners.has('fiducia') && this.barcodes.length > MIN_BARCODES,
        'tributePartner',
        `Para pagamento de Tributos e Convênios, insira ${MIN_BARCODES} (${MIN_BARCODES_TEXT}) boleto por solicitação.`,
        limitText,
      );

      this.validate(
        this.barcodes.length > MAX_BARCODES,
        'barcodeLength',
        maxBarcodeText,
        limitText,
      );
      this.deleteWarning('tributePartnerAndLength');
    }

    const canContinue = !this.warnings.size && this.completeBarcodes().length >= 1;
    $(this.btnConfirm)[`${canContinue ? 'remove' : 'add'}Class`]('btn-disabled');

    if (canContinue || !this.completeBarcodes().length >= 1) {
      $(this.errorHint).text('');
    }
  }

  validate(condition, errorID, warningText, limitText) {
    return condition ? this.addWarning(warningText, errorID, limitText) : this.deleteWarning(errorID);
  }

  addWarning(warningText, errorID, limitText) {
    if (this.warnings.has(errorID)) {
      return;
    }

    if (warningText.length) {
      const alertElement = `
        <div role="alert" class="callout d-flex align-items-center mb-3 info-light" id="${errorID}">
          <div class="circle-icon"><i class="far fa-bell"></i></div>
          <div class="content">
            ${warningText}
          </div>
        </div>
      `;
      $(this.outputElement).append(alertElement);
    }

    $(this.errorHint).text(limitText);
    this.warnings.set(errorID);
    $(this.btnConfirm).addClass('btn-disabled');
  }

  deleteWarning(errorID) {
    if (this.warnings.has(errorID)) {
      $(this.outputElement).find(`#${errorID}`).remove();
    }

    this.warnings.delete(errorID);
  }

  completeBarcodes() {
    return this.barcodes.filter(validateBarcodeLength);
  }

  splitBarcodes() {
    this.barcodes = this.inputElement.value
      .split(/\n|\r|;/)
      .map(barcode => barcode.replaceAll(/\D/g, ''))
      .filter(barcode => !!barcode);
  }

  setCurrentTime(currentTime) {
    this.currentTime = currentTime;
  }

  getCurrentTime() {
    if (this.currentTime) {
      return this.currentTime;
    }

    return new Date().toLocaleTimeString('pt-BR');
  }

  setBusinessDay(isBusinessDay) {
    this.isBusinessDay = isBusinessDay;
  }

  static create(input, output, errorHint, btnConfirm, { serverTime, isBusinessDay }) {
    const warningArea = $(output);
    document.querySelectorAll(input).forEach((elem) => {
      const validator = new Validator(elem, warningArea, errorHint, btnConfirm);

      if (serverTime) {
        validator.setCurrentTime(serverTime);
      }

      validator.setBusinessDay(!!isBusinessDay);

      validator.init();
    });
  }
}

export default Validator;
