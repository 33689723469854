onLoad(($) => {
  const $textArea = $('textarea.JS-has-char-counter');

  if (!$textArea.length) return;
  const maxLength = $textArea.attr('maxlength');
  const $counter = $(`<span class="JS-char-counter">0/${maxLength}</span>`);
  $($counter).insertBefore($textArea);

  $textArea.on('input', () => {
    $counter.text(`${$textArea.val().length}/${maxLength}`);
  });
});
