onLoad(($) => {
  if (!$('.JS-validate-onboarding-phone').length) return;

  const $showBannerButton = $('.JS-onboarding-phone-banner');
  const $continueButton = $('.JS-continue-button');
  const $inputPhone = $('.JS-onboarding-phone');
  const $getInputPhone = $(document.getElementsByClassName('phone'));

  function handleInputPhone() {
    if (!$getInputPhone.length) {
      $showBannerButton.hide();
      return;
    }
    if (!$inputPhone.val()) {
      $showBannerButton.show();
      $continueButton.hide();
      return;
    }
    $showBannerButton.hide();
    $continueButton.show();
  }

  handleInputPhone();
  $inputPhone.on('input', handleInputPhone);
});
