onLoad(($) => {
  const $inputsRequired = $('.JS-input-required');
  if (!$inputsRequired.length) return;

  const $submitButton = $('.JS-submit-required');

  function applyHint() {
    const id = this.attr('id');
    const $hint = $(`.${id}`).find('.hint');
    if ($hint.length) $hint.remove();
    if (!this.val()) $(`.${id}`).append('<p class="hint is-invalid">Campo obrigatório não preenchido</p>');
  }

  function applyClass() {
    this[`${!this.val() ? 'add' : 'remove'}Class`]('is-invalid');
  }

  $submitButton.on('click', () => {
    $inputsRequired.trigger('change');
  });

  $inputsRequired.on('change', function () {
    applyClass.call($(this));
    applyHint.call($(this));
  });
});
