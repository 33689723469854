const CurrencyHelper = () => {
  const format = (number, locale = 'pt-BR', currency = 'BRL') => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });

    return formatter.format(number);
  };

  const unformat = string => Number(string
    .replace(/[^\d,]+/g, '')
    .replace(',', '.'));

  return { format, unformat };
};

export default CurrencyHelper;
