const TwoFactor = () => {
  const textHtml = `
      <div class="swal-confirm-modal">
        <div class="title">Atenção</div>
        <div class="content mt-3 mb-1">
          Para confirmar, digite o código de segurança da autenticação de dois fatores.
        </div>
      </div>
    `;
  const dialog = () => Swal.fire({
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Confirmar',
    html: textHtml,
    icon: 'warning',
    input: 'password',
    inputPlaceholder: 'Código de segurança',
    showCancelButton: true,
    customClass: {
      actions: 'flex-row-reverse',
      content: 'pt-0',
    },
    showLoaderOnConfirm: true,
    footer: null,
    allowOutsideClick: () => !Swal.isLoading(),
    inputAttributes: {
      id: 'swal2-2fa-input',
      maxlength: 6,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    inputValidator: (value) => {
      if (value.length !== 6) {
        return 'O 2FA deve ter 6 dígitos';
      }
      return null;
    },
    onBeforeOpen: () => {
      Swal.getInput().onkeypress = event => Number.isInteger(parseInt(event.key, 10));
    },
  });

  const sendCode = (confirmUrl = '/users/two_factor_authentication/send_code') => {
    const sendTwoFactorCodeUrl = confirmUrl;

    return new Promise((success, error) => {
      $.ajax({
        url: sendTwoFactorCodeUrl,
        type: 'POST',
        dataType: 'json',
        success,
        error,
      });
    });
  };

  const errorDialog = text => Swal.fire({
    title: 'Erro',
    text: text || 'Erro inesperado, tente novamente mais tarde.',
    icon: 'error',
  });

  const showDialog = async (opts = {}) => {
    const sendCodeResult = await sendCode(opts.confirmUrl).catch(() => errorDialog());
    if (sendCodeResult.isConfirmed) return { error: true };

    if (!sendCodeResult.response === true) {
      errorDialog('Erro ao enviar código autenticador, tente novamente mais tarde.');
      return { error: true };
    }

    return dialog();
  };

  return { showDialog };
};

export default TwoFactor;
