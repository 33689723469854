onLoad(($) => {
  if (!$('.select-when-transfer').length) return;

  const $formCheckInput = $('.form-check-input');
  const $scheduledDateContainer = $('.scheduled-date-container');
  const $dateInput = $scheduledDateContainer.find('input');
  const $cantTransferTodayForm = $('.cant-transfer-today');

  $formCheckInput.on('change', () => {
    const selectedId = $formCheckInput.filter(':checked').attr('id');

    if (selectedId === 'scheduledAt') {
      $scheduledDateContainer.removeClass('d-none');
      $dateInput.attr('required', true);
    } else {
      $scheduledDateContainer.addClass('d-none');
      $dateInput.removeAttr('required');
      $dateInput.val('').change();
    }
  });

  if ($cantTransferTodayForm.length) {
    const $transferTodayInput = $cantTransferTodayForm.find('input');
    $cantTransferTodayForm.css('width', 'fit-content');
    $cantTransferTodayForm.attr('title', 'Durante finais de semana e feriados, pagamentos podem apenas ser agendados.');
    $cantTransferTodayForm.tooltip();
    $transferTodayInput.prop('disabled', true).change();
    $formCheckInput.attr('id', 'scheduledAt').prop('checked', true).change();
  }

  if ($dateInput.val()) {
    $formCheckInput.attr('id', 'scheduledAt').prop('checked', true).change();
  }
});
