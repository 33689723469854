onLoad(($) => {
  const $checkboxes = $('.JS-client-users-roles-batch-action-checkbox');

  if (!$checkboxes.length) return;

  const $tableBody = $('.JS-client-users-rules-list');
  const $counter = $('.JS-client-users-roles-batch-action-counter');
  const $cancelBtn = $('.JS-client-users-roles-cancel-batch-action');
  const $disassociateBtn = $('.JS-client-users-roles-remove-association');

  const $globalCheckbox = $(
    '#JS-client-users-roles-batch-action-global-checkbox',
  );
  const $applyButton = $('#JS-client-users-roles-batch-action-apply');
  const $roleSelect = $('#JS-client-users-roles-batch-action-select');

  // Show batch action footer if any checkbox is checked
  $checkboxes.on('change', (event) => {
    const $target = $(event.currentTarget);
    const isAnyChecked = $checkboxes.filter(':checked').length > 0;
    const $footer = $('#JS-client-users-roles-batch-action-footer');
    const animationDelay = 400;

    if (!$target.is(':checked')) $globalCheckbox.prop('checked', false);

    if (isAnyChecked) {
      $footer.show(animationDelay);
    } else {
      $footer.hide();
    }

    $counter.html($checkboxes.filter(':checked').length);
  });

  $cancelBtn.on('click', () => $checkboxes.prop('checked', false).trigger('change'));

  $disassociateBtn.on('click', function () {
    $counter.html($checkboxes.filter(':checked').length);

    const $ipBankAccountId = $(this).data('role-id');
    const $clientId = $(this).data('client-id');

    $.ajax({
      url: `/clients/${$clientId}/users/destroy_role`,
      type: 'PATCH',
      data: { ip_bank_account_id: $ipBankAccountId },
    });
  });

  $tableBody.on('DOMSubtreeModified', () => {
    const $updatedCheckboxes = $('.JS-client-users-roles-batch-action-checkbox');
    const isAnyChecked = $updatedCheckboxes.filter(':checked').length > 0;
    const $footer = $('#JS-client-users-roles-batch-action-footer');

    if (!isAnyChecked) $footer.hide();

    $counter.html($updatedCheckboxes.filter(':checked').length);
  });

  // Toggle all checkboxes by globalCheckbox's state
  $globalCheckbox.on('change', (event) => {
    const $target = $(event.currentTarget);
    $checkboxes.prop('checked', $target.is(':checked'));
    $checkboxes.change();
  });

  // Change kind_cd of every selected role
  $applyButton.on('click', () => {
    const kindCd = $roleSelect.val();
    $checkboxes.filter(':checked').each(function () {
      $(this).closest('tr').find('select').val(kindCd);
    });
  });
});
