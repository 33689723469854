onLoad(($) => {
  const $chargeToggle = $('.JS-charge-toggle-totals');
  if (!$chargeToggle.length) return;

  const findComponents = () => {
    const chargeToggleIcons = [$chargeToggle.find('.fa-eye-slash'), $chargeToggle.find('.fa-eye')];
    const shimmers = $('.charge-shimmer');
    const chargeTotals = {
      value: $('.JS-total-charges-value'),
      count: $('.JS-total-charges'),
      pages: $('.JS-total-pages-charges'),
    };
    const toggleableElements = [...chargeToggleIcons, ...shimmers];
    const isVisible = chargeToggleIcons[1].hasClass('d-none');
    return [toggleableElements, isVisible, chargeTotals];
  };

  const hideTotals = (chargeTotals) => {
    const keys = Object.keys(chargeTotals);
    keys.forEach((key) => {
      $(chargeTotals[key]).addClass('d-none');
    });
  };

  const getPageNumber = (queryString) => {
    const regex = /page=(\d+)/;
    const match = regex.exec(queryString);
    return match ? match[1] : '1'; // 1st page
  };

  const populateTotals = (chargeTotals) => {
    const searchParams = window.location.href.split('?')[1];
    const currentPage = getPageNumber(searchParams);

    $.get(`/charges/calculate_totals?${searchParams}`, (data) => {
      const formattedValue = data.total_value_general.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      const chargesPerPage = parseInt($('#charges-per-page').data('value'), 10);
      let totalPages = Math.ceil(data.total_count / chargesPerPage);
      if (!totalPages) totalPages = 1;

      $(chargeTotals.value).text(formattedValue);
      $(chargeTotals.count).text(data.total_count);
      $(chargeTotals.pages).text(` ${currentPage}/${totalPages}`);
    }).fail(() => {
      const keys = Object.keys(chargeTotals);
      keys.forEach((key) => {
        $(chargeTotals[key]).text('ERRO');
      });
    });
  };

  const showTotals = (chargeTotals) => {
    const valueText = $(chargeTotals.value).text();
    if (valueText === '' || valueText === 'ERRO') {
      const keys = Object.keys(chargeTotals);
      keys.forEach((key) => {
        $(chargeTotals[key]).text('Carregando...');
      });
      populateTotals(chargeTotals);
    } else {
      const keys = Object.keys(chargeTotals);
      keys.forEach((key) => {
        $(chargeTotals[key]).removeClass('d-none');
      });
    }
  };

  $chargeToggle.on('click', (e) => {
    e.preventDefault();
    const [toggleableElements, isVisible, chargeTotals] = findComponents();
    toggleableElements.forEach((element) => {
      $(element).toggleClass('d-none');
    });

    if (isVisible) {
      hideTotals(chargeTotals);
    } else {
      showTotals(chargeTotals);
    }
  });
});
