const { default: Swal } = require('sweetalert2');

onLoad(($) => {
  const $btnCreateProtest = $('#JS-validate-create-protest');
  if (!$btnCreateProtest.length) return;

  const $charge = $btnCreateProtest.data('charge');
  const $isCompanyAccount = $btnCreateProtest.data('account-kind') === 'company';
  const $isLinkedAccount = $btnCreateProtest.data('account-kind') === 'linked';
  const defaultUrl = '/charges/id/create_protest';
  const redirectUrl = defaultUrl.replace('id', $charge.uuid);

  const sendProtest = () => {
    let acceptanceTermMessage = '';

    if ($isCompanyAccount || $isLinkedAccount) {
      acceptanceTermMessage = `
        <br/><br/>
        <small>
          <strong>
            As instruções de protesto geradas precisarão ser aprovadas pelo Usuário Master da conta
          </strong>
        </small>
        <br/><br/>
        <small>
          <input type='checkbox' id='readTermsCheckbox'>
          Ao prosseguir com esta ação,
          você concorda com os termos de
          uso da plataforma Grafeno.
          <br/><br/>
          <a href='/downloads/termos_de_autorizacao_uso_instrucao_protesto.pdf'>
            Termos de uso <i class="fas fa-external-link-alt fa-xs"></i>
          </a>
          <br/><br/>
          <strong>É necessário aceitar os termos de uso para continuar</strong>
        </small>
      `;
    }

    const htmlContent = `
      Caso você desista ou cancele, essa ação estará
      sujeita a custas cartorárias de sua responsabilidade.
      ${acceptanceTermMessage}
      `;

    Swal.fire({
      title: 'Deseja realmente criar essa instrução de protesto?',
      html: htmlContent,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim',
      onBeforeOpen: () => {
        const content = Swal.getHtmlContainer();
        const $binder = content.querySelector.bind(content);
        const readTermsCheckbox = $binder('#readTermsCheckbox');
        if (!readTermsCheckbox) return;

        const confirmButton = Swal.getConfirmButton();
        confirmButton.disabled = true;

        readTermsCheckbox.addEventListener('change', () => {
          confirmButton.disabled = !readTermsCheckbox.checked;
        });
      },
    }).then((result) => {
      if (result.value === true) {
        $.ajax({
          url: redirectUrl,
          type: 'post',
          dataType: 'script',
          format: 'js',
          beforeSend: (xhr) => {
            xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
          },
        }).done($btnCreateProtest.removeClass('disabled'));
      } else { $btnCreateProtest.removeClass('disabled'); }
    });
  };

  const fireGrantorModal = () => {
    const $grantorModal = $(`#ProtestGrantorEditModal${$charge.uuid}`);
    $grantorModal.modal({
      keyboard: false,
      backdrop: 'static',
    });
    $grantorModal.modal('show');
  };

  const firePayerModal = () => {
    const $payerModal = $(`#ProtestPayerEditModal${$charge.uuid}`);
    $payerModal.modal({
      keyboard: false,
      backdrop: 'static',
    });
    $payerModal.modal('show');
  };

  const paymentWarning = () => {
    Swal.fire({
      title: 'Cobrança com Pagamento',
      text: 'Essa cobrança possui pagamento(s) efetuado(s). Deseja criar a instrução de protesto mesmo assim?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar',
    }).then((result) => {
      if (result.value === true) {
        sendProtest();
      }
      $btnCreateProtest.removeClass('disabled');
    });
  };

  const creditorAlert = () => {
    Swal.fire({
      title: 'Preencha os dados do sacador avalista',
      text: 'Para criar a instrução de protesto, é necessário preencher os dados do sacador avalista',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Preencher',
    }).then((result) => {
      if (result.value === true) {
        fireGrantorModal();
      }

      $btnCreateProtest.removeClass('disabled');
    });
  };

  const payerAlert = () => {
    errorText = `
      Pagador vinculado a cobrança não possui número, complemento ou bairro, complete o endereço e tente novamente
    `;
    Swal.fire({
      title: 'Atenção',
      text: errorText,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Editar endereço do pagador',
    }).then((result) => {
      if (result.value === true) {
        firePayerModal();
      }

      $btnCreateProtest.removeClass('disabled');
    });
  };

  const signerAlert = (text) => {
    Swal.fire({
      title: 'Não é possível criar a instrução de protesto',
      text,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'OK',
    }).then(() => {
      $btnCreateProtest.removeClass('disabled');
    });
  };

  const protestAlert = () => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Para o Estado (UF) do sacado dessa cobrança, a opção de instrução de '
      + 'protesto está desativada temporariamente.',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Ok',
    });
  };

  const handleValidations = (data) => {
    const signerRegExp = /não possui representantes com poder de assinatura/;
    const hasSignerErrors = data.errors.some(e => e.match(signerRegExp));

    if (hasSignerErrors) {
      const text = data.errors.join();
      signerAlert(text);
      return;
    }

    const creditorRegExp = /credor não pode estar em branco/;
    const hasCreditorErrors = data.errors.some(e => e.match(creditorRegExp));
    const payerRegExp = /pagador sem número, compl. ou bairro/;
    const hasPayerErrors = data.errors.some(e => e.match(payerRegExp));
    const hasPaymentWarning = data.payment_warning;

    if (hasCreditorErrors) {
      creditorAlert();
    } else if (hasPayerErrors) {
      payerAlert();
    } else if (hasPaymentWarning) {
      paymentWarning();
    } else {
      sendProtest();
    }
  };

  const handleValidationSuccess = (data) => {
    if (data.success && !data.payment_warning) {
      sendProtest();
    } else {
      handleValidations(data);
    }
  };

  $btnCreateProtest.on('click', (e) => {
    e.preventDefault();

    if ($charge['protest_disabled?']) {
      protestAlert();
      return;
    }

    $btnCreateProtest.addClass('disabled');
    $.ajax({
      url: '/charges/validate_protest',
      type: 'get',
      dataType: 'json',
      data: { uuid: $charge.uuid },
      success: (data) => {
        handleValidationSuccess(data);
      },
      error() {
        $btnCreateProtest.removeClass('disabled');
      },
    });
  });
});
