onLoad(($) => {
  if (!$('.ds-messaging-rule')) return;

  const $enabledSwitch = $('.toggle-switch');

  $enabledSwitch.each((_i, element) => {
    const $element = $(element);
    const isEnabled = $element.data('enabled');
    const $checkbox = $element.find("input[type='checkbox']");
    $checkbox.prop('checked', isEnabled);
  });
});
