/* eslint-disable no-unused-vars, no-undef, no-tabs */
const SwalInvoice = (element) => {
  const url = $(element).attr('href');
  const title = $(element).attr('data-original-title');
  const iframeMaxHeight = 650;

  const showAllIframeBody = ($iFrame, bodyHeight) => {
    const salt = 200;
    $iFrame.css({ height: (bodyHeight + salt), 'max-height': 'none' });
  };

  const backInitialIframeSize = ($iFrame, bodyHeight) => {
    $iFrame.css({ height: (bodyHeight), 'max-height': iframeMaxHeight });
  };

  const defineTemplate = () => (
    `<div class="d-flex justify-content-between align-items-center no-print pb-3">
      <strong>${title}</strong>
      <a href="#" onclick="window.print();return false;" class="btn btn-primary btn-sm">
          <span class="fas fa-print"></span>
      </a>
    </div>
    <iframe id="JS-iframe-invoice" src="${url}" width="100%" height="600px">
    </iframe>`
  );

  const handleIframe = () => {
    const $iFrame = $('#JS-iframe-invoice');
    $iFrame.on('load', () => {
      const bodyHeight = $iFrame.contents().find('body').height();
      $iFrame.css({ 'border-width': '0px', 'max-height': iframeMaxHeight, height: bodyHeight });
      window.onbeforeprint = () => showAllIframeBody($iFrame, bodyHeight);
      window.onafterprint = () => backInitialIframeSize($iFrame, bodyHeight);
    });
  };

  const handleHideIframe = () => {
    window.onbeforeprint = null;
    window.onafterprint = null;
  };

  const customClass = {
    confirmButton: 'no-print',
    closeButton: 'no-print',
  };

  return {
    html: defineTemplate(),
    onOpen: handleIframe,
    onClose: handleHideIframe,
    customClass,
    showCloseButton: false,
    focusConfirm: true,
    width: '860px',
  };
};

onLoad(($) => {
  const $swalInvoiceList = $('.JS-swal-invoice');

  $swalInvoiceList.on('click', function (e) {
    e.preventDefault();
    Swal.fire(new SwalInvoice(this));
  });
});
/* eslint-enable no-unused-vars, no-undef, no-tabs */
