import FroalaEditor from 'froala-editor';
import 'froala-editor/js/languages/pt_br';
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/char_counter.min';
import 'froala-editor/js/plugins/code_beautifier.min';
import 'froala-editor/js/plugins/code_view.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/emoticons.min';
import 'froala-editor/js/plugins/entities.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/help.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/image_manager.min';
import 'froala-editor/js/plugins/inline_class.min';
import 'froala-editor/js/plugins/inline_style.min';
import 'froala-editor/js/plugins/line_breaker.min';
import 'froala-editor/js/plugins/line_height.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/paragraph_style.min';
import 'froala-editor/js/plugins/print.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/save.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/url.min';

const productkey = 'lSA3D-17D4A1G2A1C1C1rD-16qvsB-9qH-8bxkA2D-11iA-9H-9H-8qoC5fjclrfduD3E2D1C1C4G1D3F1A11C6==';

onLoad(($) => {
  if (!$('.froala-email-templates').length) return;
  const messagingRuleUuid = $('#email_template_messaging_rule_uuid').val();
  FroalaEditor('.froala-email-templates', {
    key: productkey,
    attribution: false,
    language: 'pt_br',
    height: 500,

    // Set the file upload parameter.
    fileUploadParam: 'file',

    // Set the file upload URL.
    fileUploadURL: '/email_template_images',

    // Additional upload params.
    fileUploadParams: { messaging_rule_uuid: messagingRuleUuid },

    // Set request type.
    fileUploadMethod: 'POST',

    // Set max file size to 20MB.
    fileMaxSize: 1 * 1024 * 1024,

    // Allow to upload any file.
    fileAllowedTypes: ['pdf'],

    // Set the load images request URL.
    imageManagerLoadURL: '/email_template_images',

    // Set the load images request type.
    imageManagerLoadMethod: 'GET',

    // Set the image upload parameter.
    imageUploadParam: 'file',

    // Additional load params.
    imageManagerLoadParams: { messaging_rule_uuid: messagingRuleUuid },

    // Set the delete image request URL.
    imageManagerDeleteURL: '/email_template_images/delete_image',

    // Set the delete image request type.
    imageManagerDeleteMethod: 'POST',

    // Additional delete params.
    imageManagerDeleteParams: { messaging_rule_uuid: messagingRuleUuid },

    // Set the image upload URL.
    imageUploadURL: '/email_template_images',

    // Additional upload params.
    imageUploadParams: { messaging_rule_uuid: messagingRuleUuid },

    // Set request type.
    imageUploadMethod: 'POST',

    // Set max image size to 5MB.
    imageMaxSize: 1 * 1024 * 1024,

    // Allow to upload PNG and JPG.
    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    events: {
      'image.removed': ($img) => {
        $.post('/email_template_images/delete_image',
          { src: $img.attr('src'), messaging_rule_uuid: messagingRuleUuid });
      },
    },
  });
});
