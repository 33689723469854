
onLoad(($) => {
  if (!$('.ds-api-tokens-new').length) {
    return;
  }

  const $formCheck = $('.account-checkboxes:disabled').closest('.form-check');

  function addTooltip() {
    $formCheck.attr('data-toggle', 'tooltip');
    $formCheck.attr('title', 'Funcionalidade não habilitada, favor entrar em contato com a Central de Atendimento.');
    $formCheck.attr('data-placement', 'bottom');
    $formCheck.tooltip({ track: true });
  }

  addTooltip();
});
