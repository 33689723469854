onLoad(($) => {
  if (!$('#JS-QrCodeForm').length) {
    return;
  }

  const $qrCodeForm = $('#JS-QrCodeForm');
  const $qrCodeFinalScreen = $('#JS-QRCodeFinal');
  const $key = $('#JS-pixQrCodeKey');
  const $nextButton = $('#JS-pix-qrcode-next');
  const $pixDescriptions = $('#JS-transfer-pixDescription');

  $key.on('change', () => {
    $nextButton.toggleClass('disabled', $key.val() === 'Selecione uma opção');
  });

  function moveToFinalScreen() {
    $qrCodeForm.hide();
    $qrCodeFinalScreen.show();
  }

  function moveToFormScreen() {
    $qrCodeFinalScreen.hide();
    $qrCodeForm.show();
  }

  function setQrCode(src, codeString) {
    $('#JS-QrcodeImg').attr('src', src);
    $('.JS-clipboard-hidden-value').attr('data-copy', codeString);
  }

  function setPageDetails(value, keyType, key, description) {
    $('#JS-QrCodeGeneratedValue').text(value);
    $('#JS-QrCodeGeneratedKeyType').text(keyType);
    $('#JS-QrCodeGeneratedKey').text(key);
    $('#JS-QrCodeGeneratedDescription').text(description);
  }

  $nextButton.on('click', (e) => {
    e.preventDefault();
    // TODO: remover setQrCode, setPageDetails e moveToFinalScreen após implementar ajax
    setQrCode('https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=Example', 'código para cópia');
    setPageDetails('500,00', 'Chave aleatória', 'ad12-we124-32132-32131', '');
    moveToFinalScreen();
    // TODO: Ajax com os dados informados
    // $.ajax({
    //   // TODO: criar endpoint
    //   url: '',
    //   type: 'post',
    //   dataType: 'json',
    //   data: {
    //     key : $key.val(),
    //     value : $('#JS-pixQrCodeValue').val(),
    //     identifier : $('#JS-pixQrCodeIdentifier').val(),
    //     descrioption : $('#JS-pixQrCodeDescription').val()
    //   },
    //   success: (data) => {
    //    setQrCode(data.qrcodeUrl, data.qrcodeString);
    //    setPageDetails(data.value, data.keytype, data.key, data.description);
    //    moveToFinalScreen();
    //   },
    //   error: (error) => {
    //     // TODO: tratamento de erro
    //     $('#JS-QrCodeError').show();
    //     console.log(error)
    //   },
    // });
  });

  $pixDescriptions.on('input', (event) => {
    if (event.target.value.length) {
      $('#JS-charCount').text(`${event.target.value.length} / 140 caracteres`);
    } else {
      $('#JS-charCount').text('');
    }
  });

  $('#JS-returnToForm').on('click', (e) => {
    e.preventDefault();
    moveToFormScreen();
  });
});
