onLoad(($) => {
  if (!$('.ds-grafeno-authenticator').length) return;
  const hintText = 'Campo obrigatório';
  const $buttonVerifyCode = $('.JS-verify-code');
  const $inputCode = $('.JS-code');
  const $spanInvalidFeedback = $('.invalid-feedback');

  $buttonVerifyCode.on('click', () => {
    if ($inputCode.val().length === 0) {
      $inputCode.addClass('is-invalid');
      $spanInvalidFeedback.text(hintText);
    }
  });
});
